import {
    IStyle,
    mergeStyleSets
} from '@fluentui/react';
import { copilotWidth } from '../../App.styles';

const rightOffset: string = '46px';

/**
 * Styles used on this component.
 */
export const fdaStyles = mergeStyleSets({
    fdaContainer: {
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '100%',
        border: '1px solid rgb(0, 120, 212)',
        borderRadius: '2px',
        padding: '0px 16px',
        height: '30px',
        minWidth: '80px',
        color: 'rgb(255, 255, 255)',
        backgroundColor: 'rgb(0, 120, 212)',
        position: 'fixed',
        right: rightOffset,
        bottom: '10px',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        cursor: 'pointer',
        zIndex: 1000,
        ':hover': {
            backgroundColor: 'rgb(16,110,190)'
        } as IStyle
    } as IStyle,
    fdaContainerCopilotOpen: {
        right: `calc(${rightOffset} + ${copilotWidth}px)`
    } as IStyle,
    chatbotImg: {
        marginRight: '6px'
    } as IStyle
});
