import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ICommonPageProps } from '../../common/common.types';
import {
    DefaultButton,
    FontIcon,
    Spinner,
    SpinnerSize,
    Stack,
    Text
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ExportDialog } from './ExportDialog';
import { GenericDialog, GenericDialogMode } from '../GenericDialog/GenericDialog';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { IApiExcelExport, IApiExcelImport, callApiExcelExport, callApiExcelImport } from '../../store/actions/miscActions/importExport.action';
import { AppDispatch } from '../../store/reduxStore';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { PurchaseOrderSearch } from '../../models/purchaseOrder/purchaseOrderSearch';
import { CallApiState } from '../../store/actions/generic.action';
import { commonStyles, stackTokensNormalGap } from '../../common/common.styles';
import { componentStyles } from './ImportExport.styles';
import { showNotificationPanel } from '../../store/actions/app.action';

interface IImportExportProps extends ICommonPageProps {
    /**
     * Message displayed above the import/export buttons.
     */
    message: string;

    /**
     * Handle error callback.
     * @param errMsg Error message.
     */
    handleError: (errMsg: string) => void;

    /**
     * Callback to get the purchase order search object to be used for export.
     */
    purchaseOrderSearch: () => PurchaseOrderSearch;

    /**
     * If true, then the buttons for import and export will be disabled.
     */
    disabled: boolean;

    /**
     * Show a warning message in the export dialog about refining your search criteria.
     */
    showSearchCriteriaWarning: boolean;
}

/**
 * Import/export component.
 * @param props Component props.
 * @returns JSX for the component.
 */
export const ImportExport: React.FunctionComponent<IImportExportProps> = (props: IImportExportProps): JSX.Element => {
    const [displayExportDialog, { toggle: toggleDisplayExportDialog }] = useBoolean(false);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [displayGenericDialog, { toggle: toggleDisplayGenericDialog }] = useBoolean(false);
    const [genericDialogTitle, setGenericDialogTitle] = useState<string>('');
    const [genericDialogMsg, setGenericDialogMsg] = useState<string>('');
    const [genericDialogCustomContent, setGenericDialogCustomContent] = useState<JSX.Element>();
    const callbackAfterGenericDialogCloseRef = useRef<() => void>();

    // Redux store selectors to get state from the store when it changes.
    const apiExcelExport: IApiExcelExport =
        useAppSelector<IApiExcelExport>((state) => state.importExportReducer.apiExcelExport);
    const apiExcelImport: IApiExcelImport =
        useAppSelector<IApiExcelImport>((state) => state.importExportReducer.apiExcelImport);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        // Pass the error to the parent component.
        if (apiExcelExport.errMsg) {
            props.handleError(apiExcelExport.errMsg);
        }
        if (apiExcelImport.errMsg) {
            props.handleError(apiExcelImport.errMsg);
        }
        // Disabling exhaustive deps because we don't want to run this effect when the props.handleError function changes.
        // See: https://stackoverflow.com/questions/62807829/how-to-correctly-implement-a-props-callback-function-in-the-useeffect-hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiExcelExport.errMsg, apiExcelImport.errMsg]);

    /**
     * Show generic dialog.
     * @param title Dialog title.
     * @param msg Dialog message.
     * @param customContent Custom JSX content.
     * @param callback Callback to call after dialog closed.
     */
    const showGenericDialog = useCallback((title: string, msg: string, customContent?: JSX.Element, callback?: () => void) => {
        setGenericDialogTitle(title);
        setGenericDialogMsg(msg);
        setGenericDialogCustomContent(customContent);
        callbackAfterGenericDialogCloseRef.current = callback;
        toggleDisplayGenericDialog();
    }, [toggleDisplayGenericDialog]);
    
    /**
     * Export button clicked event handler.
     */
    const exportButtonClicked = () => {
        telemetryService.trackEvent({ name: trackedEvent.exportButtonClicked });
        toggleDisplayExportDialog();
    };

    /**
     * Perform export.
     * @param includeReceived Include received lines in the export file.
     */
    const performExport = (includeReceived: boolean) => {
        const newPurchaseOrderSearch: PurchaseOrderSearch = props.purchaseOrderSearch();

        // Set the include received flag.
        newPurchaseOrderSearch.filter!.includeReceived = includeReceived;

        // Call the export api.
        dispatch(callApiExcelExport(newPurchaseOrderSearch));
    };

    /**
     * Effect that runs after the export api returns.
     */
    useEffect(() => {
        if (apiExcelExport.callApiState === CallApiState.DataAvailable) {
            if (apiExcelExport.responseStatusCode === 204) {
                showGenericDialog(
                    'No data found',
                    'Your search did not return any results.'
                );
            }
        }
    }, [apiExcelExport.callApiState, apiExcelExport.responseStatusCode, showGenericDialog]);

    /**
     * Import button clicked event handler.
     */
    const importButtonClicked = () => {
        telemetryService.trackEvent({ name: trackedEvent.importButtonClicked });

        // Trigger a click on the file input element.
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            fileInputRef.current.click();
        }
    };

    /**
     * On import input change handler.
     * @param event Form event. 
     */
    const onImportInput = (event: React.FormEvent<HTMLInputElement>) => {
        const files = fileInputRef.current?.files;

        if (!files || files.length !== 1) {
            return;
        }

        const file: File = files[0];

        if (file.size > (750 * 1024) /* 750k */) {
            showGenericDialog(
                'File size too large',
                `This file is ${file.size} bytes.<br>Please make sure the file is less than 750k.`
            );
            return;
        }

        if (file.name.indexOf('.xlsx') < 0) {
            showGenericDialog(
                'Wrong file type',
                'File must be an Excel .xlsx file.'
            );
            return;
        }

        showGenericDialog(
            'Import started',
            'Check the notification panel for import details.',
            undefined,
            () => {
                dispatch(showNotificationPanel(true));
            }
        );

        dispatch(callApiExcelImport(file));
    };

    /**
     * Memoized helper to check if the export api is running.
     */
    const isExportRunning = useMemo<boolean>(() => {
        if (apiExcelExport.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiExcelExport.callApiState]);

    /**
     * Memoized helper to check if the import api is running.
     */
    const isImportRunning = useMemo<boolean>(() => {
        // Checking for both Running and DataAvailable states to get rid of "flickering" on the import button.
        // The import is a two step api call, first to upload, then to process.
        if (apiExcelImport.callApiState === CallApiState.Running ||
            apiExcelImport.callApiState === CallApiState.DataAvailable) {
            return true;
        }
        return false;
    }, [apiExcelImport.callApiState]);

    return (
        <>
            <Stack tokens={stackTokensNormalGap}>
                <Stack.Item>
                    <Text variant='mediumPlus' className={commonStyles.sectionHeading} role="heading" aria-level={2}>Import / Export</Text>
                </Stack.Item>
                <Stack.Item>
                    <Text>{props.message}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal wrap tokens={stackTokensNormalGap}>
                        <Stack.Item>
                            <DefaultButton
                                onClick={exportButtonClicked}
                                disabled={props.disabled || isExportRunning || isImportRunning}
                            >
                                {isExportRunning && (
                                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                )}
                                {!isExportRunning && (
                                    <>
                                        <FontIcon iconName="ExcelDocument" className={componentStyles.excelIcon} />
                                        <Text>Export</Text>
                                    </>
                                )}
                            </DefaultButton>
                        </Stack.Item>
                        <Stack.Item>
                            <DefaultButton
                                onClick={importButtonClicked}
                                disabled={props.disabled || isExportRunning || isImportRunning}
                            >
                                {isImportRunning && (
                                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                )}
                                {!isImportRunning && (
                                    <>
                                        <FontIcon iconName="ExcelDocument" className={componentStyles.excelIcon} />
                                        <Text>Import</Text>
                                    </>
                                )}
                            </DefaultButton>
                            <input
                                ref={fileInputRef}
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                title="import file"
                                className={componentStyles.hiddenInput}
                                onInput={onImportInput}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>

            <ExportDialog
                display={displayExportDialog}
                onIncludeReceivedYesClicked={() => {
                    performExport(true);
                    toggleDisplayExportDialog();
                }}
                onIncludeReceivedNoClicked={() => {
                    performExport(false);
                    toggleDisplayExportDialog();
                }}
                onCancelClicked={() => {
                    toggleDisplayExportDialog();
                }}
                showSearchCriteriaWarning={props.showSearchCriteriaWarning}
            />

            <GenericDialog
                displayDialog={displayGenericDialog}
                title={genericDialogTitle}
                content={
                    <>
                        <Text block variant="medium">{genericDialogMsg}</Text>
                        {genericDialogCustomContent}
                    </>
                }
                mode={GenericDialogMode.Ok}
                onOkClicked={() => {
                    toggleDisplayGenericDialog();
                    if (callbackAfterGenericDialogCloseRef.current) {
                        callbackAfterGenericDialogCloseRef.current();
                    }
                }}
            />
        </>
    );
};
