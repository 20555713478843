// Public url can be set in .env files like this: PUBLIC_URL=/SPA-v2
let publicUrl = process.env['PUBLIC_URL']?.trim() || '';
if (publicUrl.endsWith('/')) {
    // Trim trailing / if present.
    publicUrl = publicUrl.substring(0, publicUrl.length - 1);
}

/**
 * Constants used app wide.
 */
export const appConstants = {
    publicUrl: publicUrl,
    env: process.env['REACT_APP_UI_ENV']?.trim() || '',
    allCompanyCodeValue: -1
};

/**
 * Local storage key names.
 */
export const localStorageKey = {
    useLocalMockData: 'useLocalMockData'
};
