import { appConfig } from '../../shell/appConfig';
import { ApiClientBase, ApiService } from './apiClientBase';
import { PriorYearAccrualHierarchyScope } from '../../models/priorYearAccrual/priorYearAccrualHierarchyScope';
import { ProfitCenterCodeMapping } from '../../models/priorYearAccrual/profitCenterCodeMapping';
import { CostCategoryConfiguration } from '../../models/priorYearAccrual/costCategoryConfiguration';
import { CodeValue } from '../../models/priorYearAccrual/codeValue';

/**
 * Receipting accruals api client.
 */
class ReceiptingAccrualsApiClient extends ApiClientBase {
    /**
     * Constructor for the receipting service api client.
     */
    constructor() {
        super(ApiService.Receipting)
    }
    
    /**
     * Gets prior year accrual hierarchy scope.
     * @returns Prior uear accrual hierarchy scope.
     */
    public async priorYearAccrualHierarchyScope(): Promise<PriorYearAccrualHierarchyScope | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/priorYearAccrualHierarchyScope.json` : `${appConfig.current.service.receiptingAccrualsApiBaseUrl}prioryearaccrualsadmin/hierarchyScope`;
        return await this.getObject<PriorYearAccrualHierarchyScope>(PriorYearAccrualHierarchyScope, apiUrl);
    }

    /**
     * Saves prior year accrual hierarchy scope.
     * @param priorYearAccrualHierarchyScope Prior uear accrual hierarchy scope.
     * @returns Null.
     */
    public async savePriorYearAccrualHierarchyScope(priorYearAccrualHierarchyScope: PriorYearAccrualHierarchyScope): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingAccrualsApiBaseUrl}prioryearaccrualsadmin/hierarchyScope`;
        return await this.postObject<PriorYearAccrualHierarchyScope>(apiUrl, priorYearAccrualHierarchyScope);
    }

    /**
     * Gets prior year accrual profit center code mapping data.
     * @returns Profit center code mapping data.
     */
    public async priorYearAccrualProfitCenterCodeMapping(): Promise<ProfitCenterCodeMapping[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/profitCenterCodeMapping.json` : `${appConfig.current.service.receiptingAccrualsApiBaseUrl}prioryearaccrualsadmin/profitCenterCodeMapping`;
        return await this.getObjectArray<ProfitCenterCodeMapping>(ProfitCenterCodeMapping, apiUrl);
    }

    /**
     * Gets cost category configuration.
     * @returns Profit center code mapping data.
     */
    public async costCategoryConfiguration(): Promise<CostCategoryConfiguration[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/costCategoryConfiguration.json` : `${appConfig.current.service.receiptingAccrualsApiBaseUrl}prioryearaccrualsadmin/costCategoryConfiguration`;
        return await this.getObjectArray<CostCategoryConfiguration>(CostCategoryConfiguration, apiUrl);
    }

    /**
     * Gets cost categories subclasses.
     * @returns Array of cost category code/value objects.
     */
    public async costCategorySubclasses(): Promise<CodeValue[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/costCategorySubclasses.json` : `${appConfig.current.service.receiptingAccrualsApiBaseUrl}prioryearaccrualsadmin/subclasses`;
        return await this.getObjectArray<CodeValue>(CodeValue, apiUrl);
    }

    /**
     * Gets cost category group names based on subclass code.
     * @param subclassCode Subclass code.
     * @returns Array of group name code/value objects.
     */
    public async costCategoryGroupNames(subclassCode: string): Promise<CodeValue[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/costCategoryGroupNames.json` : `${appConfig.current.service.receiptingAccrualsApiBaseUrl}prioryearaccrualsadmin/costCategoryConfiguration/${subclassCode}/groupNames`;
        return await this.getObjectArray<CodeValue>(CodeValue, apiUrl);
    }

    /**
     * Gets cost category line items based on groupNameCode.
     * @param groupNameCode Group name code.
     * @returns Array of line item code/value objects.
     */
    public async costCategoryLineItems(groupNameCode: string): Promise<CodeValue[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/costCategoryLineItems.json` : `${appConfig.current.service.receiptingAccrualsApiBaseUrl}prioryearaccrualsadmin/costCategoryConfiguration/${groupNameCode}/lineItems`;
        return await this.getObjectArray<CodeValue>(CodeValue, apiUrl);
    }

    /**
     * Gets cost category line item details based on lineItemCode.
     * @param lineItemCode Line Item code.
     * @returns Array of line item detail code/value objects.
     */
    public async costCategoryLineItemDetails(groupNameCode: string): Promise<CodeValue[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/costCategoryLineItemDetails.json` : `${appConfig.current.service.receiptingAccrualsApiBaseUrl}prioryearaccrualsadmin/costCategoryConfiguration/${groupNameCode}/lineItemDetails`;
        return await this.getObjectArray<CodeValue>(CodeValue, apiUrl);
    }

    /**
     * Save cost category configuration.
     * @param costCategoryConfiguration Cost category configuration to save.
     * @returns Null.
     */
    public async saveCostCategoryConfiguration(costCategoryConfiguration: CostCategoryConfiguration): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingAccrualsApiBaseUrl}prioryearaccrualsadmin/costCategoryConfiguration/upsert`;
        return await this.postObject<CostCategoryConfiguration>(apiUrl, costCategoryConfiguration);
    }

    /**
     * Delete cost category configuration.
     * @param id Id of cost category configuration to delete.
     * @returns Null.
     */
    public async deleteCostCategoryConfiguration(id: number): Promise<null> {
        const apiUrl: string = `${appConfig.current.service.receiptingAccrualsApiBaseUrl}prioryearaccrualsadmin/costCategoryConfiguration/${id}`;
        return await this.delete(apiUrl);
    }
    
    /**
     * Gets cost category configuration for a GL account.
     * @param glAccount GL account.
     * @returns Cost category configuration.
     */
    public async costCategoryConfigurationForGlAccount(glAccount: string): Promise<CostCategoryConfiguration | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/costCategoryConfigurationForGl.json` : `${appConfig.current.service.receiptingAccrualsApiBaseUrl}prioryearaccrualsadmin/costCategoryConfiguration/glAccount/${glAccount}`;
        return await this.getObject<CostCategoryConfiguration>(CostCategoryConfiguration, apiUrl);
    }
}

export const receiptingAccrualsApiClient = new ReceiptingAccrualsApiClient();
