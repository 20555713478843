import { currentPstDate, formatDateUsingLocale } from '../../common/common.func.datetime';
import { deepCopyObject, isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../common/common.func.general';
import { toFixedNoRounding, trimLeadingAndTrailingChars } from '../../common/common.func.transform';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

/**
 * Represents a purchase order line item.
 * Directly models the server side object. If that one changes, then this one must change.
 */
export interface IPurchaseOrderLineItem {
    purchaseOrderNumber: string;
    purchaseOrderLineNumber: string;
    assetTag?: string;
    isAssetTagPrePopulatedBySupplier?: boolean;
    serialNumber?: string;
    isSerialNumberPrePopulatedBySupplier?: boolean;
    recipientName?: string;
    isRecipientPrePopulatedBySupplier?: boolean;
    isAsset?: boolean;
    canReceive: boolean;
    received: boolean;
    canClose: boolean;
    isClosed: boolean;
    closedComments?: string;
    deliveryDate?: Date;
    companyCode?: string;
    currency?: string;
    currencyFractionalPart?: number;
    description?: string;
    totalQuantity?: number;
    invoicedQuantity?: number;
    openQuantity?: number;
    receivedQuantity?: number;
    unitPrice?: number;
    lineTotal?: number;
    invoicedAmount?: number;
    openAmount?: number;
    receivedAmount?: number | string;
    percentageReceived?: number;
    originalAmount?: number;
    accruedAmount?: number;
    costCenter?: string;
    glAccount?: string;
    ioNumber?: string;
    purchasingClassification?: string;
    profitCenterCode?: string;
    offlineProcessingRunning?: boolean;
    offlineProcessingFailureMessage?: string;
    requireAssetDataCollection?: boolean;
    vendorNumber?: string;
    docType?: string;
    deleteIndicator?: string;
    accountAssignment?: string;
    isUpdatingAssetDetails: boolean;
    receiptDate?: Date | string; // Receipt date can be a Date or a string in the client. It will be converted to a string before sending back to save api.
    isLineEnabledForGSR?: boolean;
    lineDisabledMessage?: string;
}

export class PurchaseOrderLineItem implements IPurchaseOrderLineItem {
    public purchaseOrderNumber: string;
    public purchaseOrderLineNumber: string;
    public assetTag?: string;
    public isAssetTagPrePopulatedBySupplier?: boolean;
    public serialNumber?: string;
    public isSerialNumberPrePopulatedBySupplier?: boolean;
    public recipientName?: string;
    public isRecipientPrePopulatedBySupplier?: boolean;
    public isAsset?: boolean;
    public canReceive: boolean;
    public received: boolean;
    public canClose: boolean;
    public isClosed: boolean;
    public closedComments?: string;
    public deliveryDate?: Date;
    public companyCode?: string;
    public currency?: string;
    public currencyFractionalPart?: number;
    public description?: string;
    public totalQuantity?: number;
    public invoicedQuantity?: number;
    public openQuantity?: number;
    public receivedQuantity?: number;
    public unitPrice?: number;
    public lineTotal?: number;
    public invoicedAmount?: number;
    public openAmount?: number;
    public receivedAmount?: number | string; // Number or string. It is returned as a number from the api. But can be a string while bound in the UI text box.
    public percentageReceived?: number; // Calculated in this class. See percentageReceivedCalc().
    public originalAmount?: number;
    public accruedAmount?: number; // Calculated in this class. See accruedAmountCalc().
    public costCenter?: string;
    public glAccount?: string;
    public ioNumber?: string;
    public purchasingClassification?: string;
    public profitCenterCode?: string;
    public offlineProcessingRunning?: boolean;
    public offlineProcessingFailureMessage?: string;
    public requireAssetDataCollection?: boolean;
    public vendorNumber?: string;
    public docType?: string;
    public deleteIndicator?: string;
    public accountAssignment?: string;
    public isUpdatingAssetDetails: boolean;
    public receiptDate?: Date | string;
    public isLineEnabledForGSR?: boolean;
    public lineDisabledMessage?: string;

    // Below fields are not part of the server returned object, but are added for client side support.
    public isDirty?: boolean = false;
    public originalState?: IPurchaseOrderLineItem;
    public dirtyProperties?: string[] = [];
    public displayPercentValueBySlider?: boolean = true;
    public currentlyFullyReceived?: boolean = false;
    public clientRowKey?: string = '';

    /**
     * Constructor that creates a new object from JSON data (loose untyped JSON returned from a web api).
     * @param jsonData JSON data.
     */
    constructor(jsonData: IPurchaseOrderLineItem) {
        this.purchaseOrderNumber = jsonData.purchaseOrderNumber;
        this.purchaseOrderLineNumber = jsonData.purchaseOrderLineNumber;
        this.isAsset = jsonData.isAsset;
        this.received = jsonData.received;
        this.canClose = jsonData.canClose;
        this.isClosed = jsonData.isClosed;
        this.closedComments = jsonData.closedComments;
        this.deliveryDate = jsonData.deliveryDate;
        this.companyCode = jsonData.companyCode;
        this.currency = jsonData.currency;
        this.canReceive = jsonData.canReceive;

        this.assetTag = jsonData.assetTag;
        this.isAssetTagPrePopulatedBySupplier = jsonData.isAssetTagPrePopulatedBySupplier;

        this.serialNumber = jsonData.serialNumber;
        this.isSerialNumberPrePopulatedBySupplier = jsonData.isSerialNumberPrePopulatedBySupplier;

        this.recipientName = jsonData.recipientName;
        this.isRecipientPrePopulatedBySupplier = jsonData.isRecipientPrePopulatedBySupplier;

        // The asset tag, serial number, and recipient can be pre-populated by the supplier. In some cases it has been
        // found that there is occassionally a trailing comma added to the end of these fields. This is a workaround to
        // trim this character. This is only done if canReceive is true. The RegEx for asset tag, for example, disallows
        // this character. If this was not done, then the RegEx validation would fail in the UI for this pre-populated field.
        const trimChars: string[] = [','];
        if (this.canReceive) {
            if (this.isAssetTagPrePopulatedBySupplier && this.assetTag) {
                this.assetTag = trimLeadingAndTrailingChars(this.assetTag, trimChars);
            }
            if (this.isSerialNumberPrePopulatedBySupplier && this.serialNumber) {
                this.serialNumber = trimLeadingAndTrailingChars(this.serialNumber, trimChars);
            }
            if (this.isRecipientPrePopulatedBySupplier && this.recipientName) {
                this.recipientName = trimLeadingAndTrailingChars(this.recipientName, trimChars);
            }
        }

        // If canReceive is false and received (indicating fully received) then set the currentlyFullyReceived to true.
        // This is used to determine if we need to set the isUpdatingAssetDetails flag to true if the user edits the line
        // item after it was fully received. The server code has a different flow for updating already received lines.
        if (!this.canReceive && this.received) {
            this.currentlyFullyReceived = true;
        }

        // Some currencies have different allowed currency fractional parts. Typically this is 2 for most
        // currencies. But some currencies have other values like 1 or 3 or 4.
        this.currencyFractionalPart = jsonData.currencyFractionalPart;
        if (isNullOrUndefined(this.currencyFractionalPart)) {
            this.currencyFractionalPart = 2; // Default to 2.
        }

        this.description = jsonData.description;
        this.totalQuantity = jsonData.totalQuantity;
        this.invoicedQuantity = jsonData.invoicedQuantity;
        this.openQuantity = jsonData.openQuantity;
        this.receivedQuantity = jsonData.receivedQuantity;
        this.unitPrice = jsonData.unitPrice;
        this.lineTotal = jsonData.lineTotal;
        this.invoicedAmount = jsonData.invoicedAmount;
        this.openAmount = jsonData.openAmount;
        this.receivedAmount = jsonData.receivedAmount;
        // this.percentageReceived is calculated - See percentageReceivedCalc().
        this.originalAmount = jsonData.originalAmount;
        // this.accruedAmount is calculated - See accruedAmountCalc().
        this.costCenter = jsonData.costCenter;
        this.glAccount = jsonData.glAccount;
        this.ioNumber = jsonData.ioNumber;
        this.purchasingClassification = jsonData.purchasingClassification;
        this.profitCenterCode = jsonData.profitCenterCode;
        this.offlineProcessingRunning = jsonData.offlineProcessingRunning;
        this.offlineProcessingFailureMessage = jsonData.offlineProcessingFailureMessage;
        this.requireAssetDataCollection = jsonData.requireAssetDataCollection;
        this.vendorNumber = jsonData.vendorNumber;
        this.docType = jsonData.docType;
        this.deleteIndicator = jsonData.deleteIndicator;
        this.accountAssignment = jsonData.accountAssignment;

        this.isUpdatingAssetDetails = jsonData.isUpdatingAssetDetails;

        this.isLineEnabledForGSR = jsonData.isLineEnabledForGSR;
        this.lineDisabledMessage = jsonData.lineDisabledMessage;

        // If receipt date is not yet set, then leave it undefined rather than default to a new date.
        this.receiptDate = jsonData.receiptDate ? new Date(jsonData.receiptDate) : undefined;

        // Only need to do this if this is a service, not asset.
        if (!this.isAsset) {
            this.percentageReceivedCalc();
            this.accruedAmountCalc();
            this.percentageReceivedMinCheck();
            this.receivedAmountMinMaxCheck();
        }

        // Only need to do this if this is an asset, not a service.
        if (this.isAsset) {
            // If this is a line item with multiple total quantity, then default the asset tag and serial
            // number to 'n/a'. User may change it if they want, just defaults. User must enter a recipient though.
            if (this.totalQuantity !== undefined && this.totalQuantity > 1) {
                if (isNullOrUndefinedOrEmptyString(this.assetTag)) {
                    this.assetTag = 'N/A';
                }
                if (isNullOrUndefinedOrEmptyString(this.serialNumber)) {
                    this.serialNumber = 'N/A';
                }
            }
        }

        // Record the original state at the bottom here, after above adjustments are done. This is used to determine
        // dirty state changes if the user has changed the values.
        this.originalState = deepCopyObject(this);
    }

    /**
     * Return calculated percentage received.
     */
    public percentageReceivedCalc(): void {
        if (this.receivedAmount === undefined || this.lineTotal === undefined) {
            return;
        }

        // Note this.receivedAmount could be a string, so convert it to a number for this calc.
        const receivedAmountNumber: number = Number(this.receivedAmount || 0);

        // Round the percentage received to 2 fixed decimals.
        this.percentageReceived = parseFloat(((receivedAmountNumber / this.lineTotal) * 100).toFixed(2));
        // This percentage received overage check of > 100 is to account for conditions where for whatever reason the
        // received amount was greater than the line total. This cannot be done from this GSR system, but possibly directly
        // in SAP or some other system.
        if (this.percentageReceived > 100) {
            this.percentageReceived = 100;
        }
    }

    /**
     * Return calculated accrued amount.
     */
    public accruedAmountCalc(): void {
        if (this.receivedAmount === undefined || this.invoicedAmount === undefined || this.lineTotal === undefined) {
            return;
        }

        // If the line is closed, then do not do any calc for the accrued amount. Just set it to 0.
        if (this.isClosed) {
            this.accruedAmount = 0;
            return;
        }

        // Note this.receivedAmount could be a string, so convert it to a number for this calc.
        const receivedAmountNumber: number = Number(this.receivedAmount || 0);

        // Take the lesser value between receivedAmount or lineTotal.
        const lesser: number = receivedAmountNumber < this.lineTotal ? receivedAmountNumber : this.lineTotal;
        // Subtract the invoicedAmount from that lesser value.
        this.accruedAmount = lesser - this.invoicedAmount;
        if (this.accruedAmount < 0) {
            this.accruedAmount = 0;
        }
        this.accruedAmount = Number(toFixedNoRounding(this.accruedAmount, this.currencyFractionalPart));
    }

    /**
     * Percentage received min check.
     */
    public percentageReceivedMinCheck(): void {
        if (this.invoicedAmount === undefined || this.lineTotal === undefined) {
            return;
        }

        let minPercent: number = 0;
        if (this.invoicedAmount >= this.lineTotal) {
            // If the invoiced amount is greater than or equal to the line total then make the min percent be 100;
            minPercent = 100;
        } else {
            // If the invoiced amount is less than the line total then make the min percent be the invoiced amount
            // divided by the line total. The percentage received (tied to received amount) should not go less than
            // the percentage of the invoiced amount of the line total.
            // Round the percentage received to 2 fixed decimals.
            minPercent = Number(((this.invoicedAmount / this.lineTotal) * 100).toFixed(2));
        }

        if (this.percentageReceived !== undefined && this.percentageReceived < minPercent) {
            this.percentageReceived = minPercent;
            // If the received amount fell below the min percent received, set it to the invoiced amount.
            this.receivedAmount = this.invoicedAmount;
        }
    }

    /**
     * Enforce that the received amount (for services line items) is not less than the invoiced amount or greater
     * than the line total amount.
     */
    public receivedAmountMinMaxCheck(): void {
        if (this.receivedAmount === undefined || this.invoicedAmount === undefined || this.lineTotal === undefined) {
            return;
        }

        if (isNullOrUndefined(this.receivedAmount)) {
            this.receivedAmount = 0;
        }

        // If the received amount fell below the invoiced amount, set it to the invoiced amount.
        if (Number(this.receivedAmount) < Number(this.invoicedAmount)) {
            this.receivedAmount = this.invoicedAmount;
        }

        // These two if conditions are separate and not if/else. Because each could be true, in which case this lower if wins.
        // If the received amount went above the line total, set it to the line total.
        if (Number(this.receivedAmount) > Number(this.lineTotal)) {
            this.receivedAmount = this.lineTotal;
        }

        this.receivedAmount = Number(toFixedNoRounding(this.receivedAmount, this.currencyFractionalPart));
    }

    /**
     * Enforce that the received quantity (for goods line items) is not less than the invoiced quantity or greater
     * than the total quantity.
     */
    public receivedQuantityMinMaxCheck(): void {
        if (this.receivedQuantity === undefined || this.invoicedQuantity === undefined || this.totalQuantity === undefined) {
            return;
        }

        // If the received quantity fell below the invoiced quantity, set it to the invoiced quantity.
        if (this.receivedQuantity < this.invoicedQuantity) {
            this.receivedQuantity = this.invoicedQuantity;
        }

        // If the received quantity went above the total quantity, set it to the total quantity.
        // These two if conditions are separate and not if/else. Because each could be true, in which case this lower if wins.
        if (this.receivedQuantity > this.totalQuantity) {
            this.receivedQuantity = this.totalQuantity;
        }
    }

    /**
     * Calculate the receipt date mininum and maximum boundaries.
     * @param boundary Boundary min (PST date) or max (PST date).
     * @returns Date boundary.
     */
    public calculateReceiptDateBoundaries(boundary: 'min' | 'max'): Date {
        switch (boundary) {
            case 'max': {
                return currentPstDate();
            }
            default:
            case 'min': {
                // Get the current PST date.
                let minReceiptDatePST: dayjs.Dayjs = dayjs().local().tz('America/Los_Angeles'); // Date now, in PST time zone.

                const year: number = minReceiptDatePST.year();

                if (minReceiptDatePST.month() < 6 /* If currently less than July (6 is July as month is 0 based). */ ||
                    (minReceiptDatePST.month() === 6 && minReceiptDatePST.date() === 1 /* Or if it is currently July 1st. */)) {
                    // Then adjust the min receipt date year to the prior year.
                    minReceiptDatePST = minReceiptDatePST.year(year - 1);
                }

                // Set the month and day to July 1st.
                minReceiptDatePST = minReceiptDatePST.month(6) // 6 is July as month is 0 based.
                minReceiptDatePST = minReceiptDatePST.date(1);

                // Make a new Date object using PST time. Do not specify Z in the string format.
                return new Date(minReceiptDatePST.format('YYYY-MM-DDTHH:mm:ss.sss'));
            }
        }
    }

    /**
     * Returns the fiscal period based on the selected receipt date.
     * @returns An object with the fiscal year and quarter, and posting period month.
     */
    public get postingFiscalPeriodForSelectedReceiptDate(): { fiscalYearQuarter: string, periodMonth: string} | undefined {
          if (!this.receiptDate) {
            return undefined;
        }

        let fyq: string = '';

        // The receipt date can be set all the way back to the start of the fiscal year, which is July 1st.
        // Every month is considered a fiscal period. All fiscal periods (months) close at the end of the first
        // day of the next month. For example, at the end of the day on April 1st (which is in Q4) then the
        // March (which is in Q3) fiscal period is closed.
        // The posting fiscal period will always be the current month or the prior month (if we are currently on
        // day one of any month). The posting will never go to any month before that as they are all closed.
        // So the date used to evaluate this posting fiscal period is based on the above logic.

        // Get the current PST date.
        const nowPst: dayjs.Dayjs = dayjs().tz('America/Los_Angeles');

        // The evalPst is used to evaluate the posting period message. Start with it at current PST date.
        let evalPst: dayjs.Dayjs = dayjs().tz('America/Los_Angeles');

        const dayjsReceiptDate = dayjs(this.receiptDate);

        // If the year or month of the dayjsReceiptDate is less than the year or month of the nowPst (that is,
        // the user has specified a date in a prior month/period), and the current date is the first of the month.
        if (nowPst.date() === 1 &&
            (dayjsReceiptDate.year() < nowPst.year() || dayjsReceiptDate.month() < nowPst.month())) {
            // Adjust the evalPst month to be one month ago.
            evalPst = evalPst.subtract(1, 'month');
        }

        // Get the fiscal year.
        let year: number = evalPst.year();
        const month: number = evalPst.month() + 1; // Make month 1 based (month() returns 0 based).
        if (month >= 7) {
            // If July or after then increase the year. ex: 7/1/2023 is FY24
            year++;
        }
        const fy: string = String(year).substring(2);

        // Get the fiscal quarter.
        let qtr: number = 0;
        if (month >= 7 && month <= 9) {
            qtr = 1; // Month 7, 8, 9 is Q1.
        } else if (month >= 10 && month <= 12) {
            qtr = 2; // Month 10, 11, 12 is Q2.
        } else if (month >= 1 && month <= 3) {
            qtr = 3; // Month 1, 2, 3 is Q3.
        } else if (month >= 4 && month <= 6) {
            qtr = 4; // Month 4, 5, 6 is Q4.
        }

        // Get the fiscal period string.
        fyq = `FY${fy} Q${qtr}`;

        return {
            fiscalYearQuarter: fyq, // Fiscal year and quarter as a string, such as "FY23 Q2".
            periodMonth: evalPst.format('MMMM') // Month as a string, such as "May".
        };
    }

    /**
     * Checks whether or not the line item is valid.
     * Note that edit controls run their own RegEx validations which is separate from the validations done here.
     * @returns True if the line item is valid. False otherwise.
     */
    public isLineItemValid(): boolean {
        let isValid = true;

        if (this.isDirty) {
            // For goods line items:
            // Perform empty input checks for asset tag, serial number, and recipient.
            if (this.isAsset && !this.isClosed && ((this.canReceive && this.received) || this.isUpdatingAssetDetails) && this.requireAssetDataCollection) {
                if (isNullOrUndefined(this.assetTag) || isNullOrUndefined(this.serialNumber) || isNullOrUndefined(this.recipientName) ||
                    (this.assetTag || '').trim().length === 0 || (this.serialNumber || '').trim().length === 0 || (this.recipientName || '').trim().length === 0) {
                    isValid = false;
                }
            }

            // For service line items:
            if (!this.isAsset && !this.isClosed) {
                const serviceLineInvalidReasons = this.serviceLineInvalidReasons();
                isValid = serviceLineInvalidReasons.length === 0;
            }
        }

        return isValid;
    }

    /**
     * Get invalid line reasons.
     * @returns Invalid line reasons.
     */
    public getInvalidLineReasons(): string[] {
        const reasons: string[] = [];

        // For service line items:
        if (!this.isAsset && this.isDirty) {
            reasons.push(...this.serviceLineInvalidReasons());
        }

        // For goods line items:
        // A goodsLineInvalidReasons() function is not needed as existing validation code (Regex and null
        // checks) is already done. No need to display any special invalid reasons in the UI in this case.

        return reasons;
    }

    /**
     * Gets service line invalid reasons.
     * @returns Array of invalid line reasons.
     */
    private serviceLineInvalidReasons(): string[] {
        // If this is an goods line or if this line is not dirty, then return empty array.
        if (this.isAsset || !this.isDirty) {
            return [];
        }

        const invalidLineReasons: string[] = [];

        if (typeof this.receiptDate === 'string') {
            this.receiptDate = new Date(this.receiptDate);
        }

        // Only need to perform these checks if receivedAmount (or percentageReceived which auto-changes along with receivedAmount)
        // is dirty (edited by the user). Or if receiptDate is dirty. The receiptDate is not editable unless the receivedAmount
        // changes... but a user could change the receivedAmount, then change it back, thus causing receiptDate to change.
        if (this.checkIsDirty('receivedAmount') || this.checkIsDirty('receiptDate')) {
            if (!this.receiptDate) {
                invalidLineReasons.push('Receipt date cannot be empty.');
            } else if (this.dirtyProperties && this.dirtyProperties.length === 1 && this.dirtyProperties[0] === 'receiptDate') {
                invalidLineReasons.push('Only receipt date cannot be modified for existing receipts. To modify the receipt date, it is recommended to first perform a new receipt reducing the received amount to the current invoiced amount (with any receipt date) and then perform a second receipt with the correct receipt date and received amount.');
            } else {
                // Check to ensure the receipt date is within a valid period.
                const minReceiptDatePst: Date = this.calculateReceiptDateBoundaries('min');
                const maxReceiptDatePst: Date = this.calculateReceiptDateBoundaries('max');
    
                if (this.receiptDate && this.receiptDate < minReceiptDatePst) {
                    invalidLineReasons.push(`The receipt date ${formatDateUsingLocale(this.receiptDate)} cannot be before ${formatDateUsingLocale(minReceiptDatePst)}`);
                } else if (this.receiptDate && this.receiptDate > maxReceiptDatePst) {
                    invalidLineReasons.push(`The receipt date ${formatDateUsingLocale(this.receiptDate)} cannot be after ${formatDateUsingLocale(maxReceiptDatePst)}`);
                }
            }
        }

        return invalidLineReasons;
    }

    /**
     * Update the receipt date to the current PST date.
     * Only set receipt date to current date if it was not yet dirty (already changed by the user).
     */
    public setReceiptDateToCurrentPstDate(): void {
        if (!this.dirtyProperties?.includes('receiptDate')) {
            this.receiptDate = currentPstDate();
        }
    }

    /**
     * Return reduced data for save purposes. Only the mutable data is needed to be sent for saving, along with
     * certain key identifier data.
     * No need to send the full object back for saving.
     * @returns Purchase order line item.
     */
    public reduceDataForSave(): PurchaseOrderLineItem {
        const copy: PurchaseOrderLineItem = deepCopyObject(this);

        // Below is in the same order as shown in the IPurchaseOrderLineItem.

        // Keep purchaseOrderNumber, purchaseOrderLineNumber, assetTag.
        delete copy.isAssetTagPrePopulatedBySupplier;
        // Keep serialNumber.
        delete copy.isSerialNumberPrePopulatedBySupplier;
        // Keep recipientName.
        delete copy.isRecipientPrePopulatedBySupplier;
        // Keep isAsset, received.
        // Keep canReceive and canClose because the server side code needs these to know if it was just closed or received.
        // Keep isClosed, closedComments.
        // Keep deliveryDate.
        // todo: deliveryDate and certain other readonly fields are used from client sent data during save process.
        // Server side should use immutable readonly data from server, and not rely on client sent data.
        delete copy.deliveryDate;
        delete copy.companyCode;
        delete copy.currency;
        delete copy.description;
        delete copy.totalQuantity;
        delete copy.invoicedQuantity;
        // Keep openQuantity, receivedQuantity.
        delete copy.unitPrice;
        // Keep lineTotal, invoicedAmount. Reason is because during PO expansion, we get data live from SAP (quick search api call).
        // Saving code will use these values as part of validation.
        delete copy.openAmount;
        // Keep percentageReceived, receivedAmount, accruedAmount.
        delete copy.originalAmount;
        delete copy.costCenter;
        delete copy.glAccount;
        delete copy.ioNumber;
        delete copy.purchasingClassification;
        delete copy.profitCenterCode;
        delete copy.offlineProcessingRunning;
        delete copy.offlineProcessingFailureMessage;
        delete copy.requireAssetDataCollection;
        delete copy.vendorNumber;
        delete copy.docType;
        delete copy.deleteIndicator;
        delete copy.accountAssignment;
        // Keep isUpdatingAssetDetails.
        // Keep receiptDate.
        delete copy.isLineEnabledForGSR;
        delete copy.lineDisabledMessage;

        // Delete this data used on client side only.
        delete copy.isDirty;
        delete copy.originalState;
        delete copy.dirtyProperties;
        delete copy.displayPercentValueBySlider;
        delete copy.currentlyFullyReceived;
        delete copy.clientRowKey;

        return copy;
    }

    /**
     * Make the line item dirty. Or make it not dirty if the values are the same as the original state.
     * If the data was changed back to the original state then make it not dirty again.
     * @param property Property that is dirty.
     */
    public dirtyItem(property: string): void {
        if (!this.dirtyProperties) {
            this.dirtyProperties = [];
        }

        const indexOfDirtyProperty: number = this.dirtyProperties.indexOf(property);

        // If currently in dirty list but values are the same again. Remove it from the dirty list.
        if (this.originalState !== undefined && indexOfDirtyProperty > -1 &&
            // Both values are equal. Using valueOf for comparison of all types including strings, numbers, and dates
            // but really only useful for dates as Date is an object and comparing two identical dates will be false
            // since the two objects are different. While using valueOf will compare the numeric value of each date.
            // Using ? optional chaining operator here in the event that the property is null or undefined for either side.
            (this[property]?.valueOf() === this.originalState[property]?.valueOf() ||
            // Or both values are null/undefined/empty.
            (isNullOrUndefinedOrEmptyString(this[property]) && isNullOrUndefinedOrEmptyString(this.originalState[property])))) {
            this.dirtyProperties.splice(indexOfDirtyProperty, 1);
        }

        // If not in the dirty list but values are different. Add it to the dirty list.
        if (this.originalState !== undefined && indexOfDirtyProperty === -1 &&
            // Both values are not equal.
            this[property]?.valueOf() !== this.originalState[property]?.valueOf() &&
            // And both values are not null/undefined/empty.
            (!(isNullOrUndefinedOrEmptyString(this[property]) && isNullOrUndefinedOrEmptyString(this.originalState[property])))) {
            this.dirtyProperties.push(property);
        }

        this.isDirty = this.dirtyProperties.length > 0;
    }

    /**
     * Check to see if a property is dirty.
     * @param property Property to check.
     */
    public checkIsDirty(property: string): boolean {
        if (this.dirtyProperties && this.dirtyProperties.indexOf(property) > -1) {
            return true;
        }
        return false;
    }

    /**
     * Checks if item can be marked as received.
     * @param lineItem PO line item.
     * @returns True if can receive the line item.
     */
     public canReceiveItem(): boolean {
        // If the line item is not enabled for GSR (both goods or services).
        // This is to handle the case where some lines may not be enabled in the system due to exclusions, such as
        // excluded company code, or profit center based on hierarchy mapping. Note that for some POs, some lines may
        // be enabled, while other ones are not.
        if (!this.isLineEnabledForGSR) {
            return false;
        }

        // If already saved as received, cannot receive again.
        if (!this.canReceive) {
            return false;
        }

        // If closed then cannot receive.
        if (this.isClosed) {
            return false;
        }

        // If offline processing is running then cannot receive.
        if (this.offlineProcessingRunning) {
            return false;
        }

        return true;
    }

    /**
     * Merge cost center and internal order as 'Cost Center/IO' (only one of these 2 will generally be applicable).
     * @returns Merged cost center and internal order.
     */
    public get costCenterInternalOrder(): string | undefined {
        if (isNullOrUndefinedOrEmptyString(this.costCenter) && !isNullOrUndefinedOrEmptyString(this.ioNumber)) {
            return this.ioNumber;
        } else if (!isNullOrUndefinedOrEmptyString(this.costCenter) && isNullOrUndefinedOrEmptyString(this.ioNumber)) {
            return this.costCenter;
        } else if (!isNullOrUndefinedOrEmptyString(this.costCenter) && !isNullOrUndefinedOrEmptyString(this.ioNumber)) {
            return `${this.costCenter}/${this.ioNumber}`;
        }
        return '';
    }

    /**
     * Check if editing should be disabled for any input control.
     * @param checkQuantity Include quantities in check to disable editing.
     * @returns True if editing is to be disabled, otherwise false.
     */
    public checkToDisableEditing(checkQuantity: boolean = true): boolean {
        // If the line item is not enabled for GSR (both goods or services).
        // This is to handle the case where some lines may not be enabled in the system due to exclusions, such as
        // excluded company code, or profit center based on hierarchy mapping. Note that for some POs, some lines may
        // be enabled, while other ones are not.
        if (!this.isLineEnabledForGSR) {
            return true;
        }

        // Always disable when the app has an offline process running or the app is blocked.
        if (this.offlineProcessingRunning) {
            return true;
        }

        // If cannot be closed and is closed then disable editing.
        if (!!this.canClose === false && this.isClosed) {
            return true;
        }

        // Note that we are not checking this.canReceive here to disable editing, this is because we will always
        // allow editing even after received is done. See code referencing this.currentlyFullyReceived
        // and this.isUpdatingAssetDetails for how editing is done after receipt is done for goods line items.

        if (checkQuantity) {
            // If this is a goods line item.
            if (this.isAsset) {
                // If this has multiple quantity > 1. If nothing has been received yet, then disable editing.
                // That is, the user must specify at least 1 quantity is to be received before editing is enabled.
                if (this.totalQuantity !== undefined && this.totalQuantity > 1 &&
                    this.receivedQuantity !== undefined && this.receivedQuantity === 0) {
                    return true;
                }

                // If this has one total quantity. If it is not marked received yet, then disable editing.
                // The user must check the box indicating it is received before editing is enabled.
                if (this.totalQuantity !== undefined && this.totalQuantity === 1 &&
                    (this.receivedQuantity === undefined || this.receivedQuantity === 0)) {
                    return true;
                }

                // If the line was already fully received (receivedQuantity === totalQuantity) then we will still
                // allow editing. This is because the user may want to change the asset tag, serial number, or recipient.
                // See code referencing this.currentlyFullyReceived and this.isUpdatingAssetDetails.
            }
        }

        return false;
    }
}
