export const STORE_LAST_SEARCH = 'STORE_LAST_SEARCH';
export const STORE_SEARCH_RESULTS = 'STORE_SEARCH_RESULTS';
export const STORE_VISIBILITY_CHECK_RESULTS = 'STORE_VISIBILITY_CHECK_RESULTS';
export const API_LOAD_USER_PROFILE = 'API_LOAD_USER_PROFILE'; // Used during app load.
export const API_RELOAD_USER_PROFILE = 'API_RELOAD_USER_PROFILE'; // Used on user profile page.
export const API_HOME_TILE_INFO = 'API_HOME_TILE_INFO';
export const API_SEARCH = 'API_SEARCH';
export const API_SEARCH_FOR_PO = 'API_SEARCH_FOR_PO';
export const INPUT_FILTER_DRI_SEARCH_ENABLED = 'INPUT_FILTER_DRI_SEARCH_ENABLED';
export const INPUT_FILTER_PO_NUMBER = 'INPUT_FILTER_PO_NUMBER';
export const INPUT_FILTER_SUPPLIER_NUMBER = 'INPUT_FILTER_SUPPLIER_NUMBER';
export const INPUT_FILTER_COMPANY_CODE = 'INPUT_FILTER_COMPANY_CODE';
export const INPUT_FILTER_PO_APPROVER = 'INPUT_FILTER_PO_APPROVER';
export const INPUT_FILTER_PO_OWNER = 'INPUT_FILTER_PO_OWNER';
export const INPUT_FILTER_INVOICE_APPROVER = 'INPUT_FILTER_INVOICE_APPROVER';
export const INPUT_FILTER_DATE_CREATED_FROM = 'INPUT_FILTER_DATE_CREATED_FROM';
export const INPUT_FILTER_DATE_CREATED_TO = 'INPUT_FILTER_DATE_CREATED_TO';
export const INPUT_FILTER_DELIVERY_DATE_FROM = 'INPUT_FILTER_DELIVERY_DATE_FROM';
export const INPUT_FILTER_DELIVERY_DATE_TO = 'INPUT_FILTER_DELIVERY_DATE_TO';
export const INPUT_FILTER_DELEGATED_BY = 'INPUT_FILTER_DELEGATED_BY';
export const INPUT_FILTER_DELEGATED_BY_OPTIONS = 'INPUT_FILTER_DELEGATED_BY_OPTIONS';
export const INPUT_FILTER_HIERARCHY_SALES_DISTRICT_CODES = 'INPUT_FILTER_HIERARCHY_SALES_DISTRICT_CODES';
export const INPUT_FILTER_FILTER_HIERARCHY_CHANNEL_FUNCTION_DETAIL_CODES = 'INPUT_FILTER_FILTER_HIERARCHY_CHANNEL_FUNCTION_DETAIL_CODES';
export const INPUT_FILTER_HIERARCHY_EXECUTIVE_FUNCTION_DETAIL_CODES = 'INPUT_FILTER_HIERARCHY_EXECUTIVE_FUNCTION_DETAIL_CODES';
export const INPUT_FILTER_INCLUDE_RECEIVED = 'INPUT_FILTER_INCLUDE_RECEIVED';
export const INPUT_FILTER_INCLUDE_GOODS_SERVICES = 'INPUT_FILTER_INCLUDE_GOODS_SERVICES';
export const EDIT_PO = 'EDIT_PO';
export const EDIT_STORE_PAGEDATA = 'EDIT_STORE_PAGEDATA';
export const EDIT_STORE_PAGEDATA_CLEAR = 'EDIT_STORE_PAGEDATA_CLEAR';
export const EDIT_NAV_FROM_AUDIT_HISTORY = 'EDIT_NAV_FROM_AUDIT_HISTORY';
export const EDIT_LINEITEM_ASSETTAG = 'EDIT_LINEITEM_ASSETTAG';
export const EDIT_LINEITEM_ASSETTAG_HASERROR = 'EDIT_LINEITEM_ASSETTAG_HASERROR';
export const EDIT_LINEITEM_SERIALNUMBER = 'EDIT_LINEITEM_SERIALNUMBER';
export const EDIT_LINEITEM_SERIALNUMBER_HASERROR = 'EDIT_LINEITEM_SERIALNUMBER_HASERROR';
export const EDIT_LINEITEM_RECIPIENTNAME = 'EDIT_LINEITEM_RECIPIENTNAME';
export const EDIT_LINEITEM_RECIPIENTNAME_HASERROR = 'EDIT_LINEITEM_RECIPIENTNAME_HASERROR';
export const EDIT_LINEITEM_RECEIVED = 'EDIT_LINEITEM_RECEIVED';
export const EDIT_LINEITEM_RECEIVED_PARTIAL = 'EDIT_LINEITEM_RECEIVED_PARTIAL';
export const EDIT_LINEITEM_RECEIVED_AMOUNT = 'EDIT_LINEITEM_RECEIVED_AMOUNT';
export const EDIT_LINEITEM_RECEIVED_AMOUNT_HASERROR = 'EDIT_LINEITEM_RECEIVED_AMOUNT_HASERROR';
export const EDIT_LINEITEM_PERCENTAGE_RECEIVED = 'EDIT_LINEITEM_PERCENTAGE_RECEIVED';
export const EDIT_LINEITEM_RECEIPT_DATE = 'EDIT_LINEITEM_RECEIPT_DATE';
export const API_EXCEL_EXPORT = 'API_EXCEL_EXPORT';
export const API_EXCEL_IMPORT = 'API_EXCEL_IMPORT';
export const API_PROCESS_EXCEL = 'API_PROCESS_EXCEL';
export const TEACHING_BUBBLE_SHOW = 'TEACHING_BUBBLE_SHOW';
export const TEACHING_BUBBLE_GOTIT = 'TEACHING_BUBBLE_GOTIT';
export const TEACHING_BUBBLE_CLEAR_ARRAY = 'TEACHING_BUBBLE_CLEAR_ARRAY';
export const SHOW_DETAILS_FOR_LINEITEM = 'SHOW_DETAILS_FOR_LINEITEM';
export const SHOW_AUDIT_FOR_LINEITEM = 'SHOW_AUDIT_FOR_LINEITEM';
export const SHOW_SHIPMENT_INFO_FOR_LINEITEM = 'SHOW_SHIPMENT_INFO_FOR_LINEITEM';
export const CHANGE_TILESET = 'CHANGE_TILESET';
export const API_LOAD_AUDIT_HISTORY = 'API_LOAD_AUDIT_HISTORY';
export const API_SHIPMENT_STATUS_TRAIL = 'API_SHIPMENT_STATUS_TRAIL';
export const API_PRIOR_YEAR_ACCRUAL_USER_SUMMARY = 'API_PRIOR_YEAR_ACCRUAL_USER_SUMMARY';
export const API_PRIOR_YEAR_ACCRUAL_ELEVATED_SUMMARY = 'API_PRIOR_YEAR_ACCRUAL_ELEVATED_SUMMARY';
export const API_PRIOR_YEAR_ACCRUAL_REGIONS = 'API_PRIOR_YEAR_ACCRUAL_REGIONS';
export const API_PRIOR_YEAR_ACCRUAL_SEARCH = 'API_PRIOR_YEAR_ACCRUAL_SEARCH';
export const API_PRIOR_YEAR_ACCRUAL_EXPORT = 'API_PRIOR_YEAR_ACCRUAL_EXPORT';
export const API_PRIOR_YEAR_ACCRUAL_EXPORT_CORP_DETAILS = 'API_PRIOR_YEAR_ACCRUAL_EXPORT_CORP_DETAILS';
export const API_PRIOR_YEAR_ACCRUAL_IMPORT_CORP_DETAILS = 'API_PRIOR_YEAR_ACCRUAL_IMPORT_CORP_DETAILS';
export const API_PRIOR_YEAR_ACCRUAL_RETAIN_LINE = 'API_PRIOR_YEAR_ACCRUAL_RETAIN_LINE';
export const API_PRIOR_YEAR_ACCRUAL_REFRESH_LINE = 'API_PRIOR_YEAR_ACCRUAL_REFRESH_LINE';
export const API_PRIOR_YEAR_ACCRUAL_CLOSE_LINE = 'API_PRIOR_YEAR_ACCRUAL_CLOSE_LINE';
export const SHOW_DETAILS_FOR_PRIOR_YEAR_ACCRUAL_LINE_ITEM = 'SHOW_DETAILS_FOR_PRIOR_YEAR_ACCRUAL_LINE_ITEM';
export const SHOW_AUDIT_FOR_PRIOR_YEAR_ACCRUAL_LINE_ITEM = 'SHOW_AUDIT_FOR_PRIOR_YEAR_ACCRUAL_LINE_ITEM';
export const PRIOR_YEAR_ACCRUAL_LINE_ITEM_SELECTION = 'PRIOR_YEAR_ACCRUAL_LINE_ITEM_SELECTION';
export const PRIOR_YEAR_ACCRUAL_TOGGLE_ALL_SELECTION = 'PRIOR_YEAR_ACCRUAL_TOGGLE_ALL_SELECTION';
export const PRIOR_YEAR_ACCRUAL_CHANGE_VIEW_AS_ROLE = 'PRIOR_YEAR_ACCRUAL_CHANGE_VIEW_AS_ROLE';
export const API_PRIOR_YEAR_ACCRUAL_HIERARCHY_SCOPE = 'API_PRIOR_YEAR_ACCRUAL_HIERARCHY_SCOPE';
export const API_SAVE_PRIOR_YEAR_ACCRUAL_HIERARCHY_SCOPE = 'API_SAVE_PRIOR_YEAR_ACCRUAL_HIERARCHY_SCOPE';
export const API_PRIOR_YEAR_ACCRUAL_PROFIT_CENTER_CODE_MAPPING = 'API_PRIOR_YEAR_ACCRUAL_PROFIT_CENTER_CODE_MAPPING';
export const API_GET_PRIOR_YEAR_ACCRUAL_EMAIL_DETAILS = 'API_GET_PRIOR_YEAR_ACCRUAL_EMAIL_DETAILS';
export const API_TRIGGER_PRIOR_YEAR_ACCRUAL_MANUAL_NOTIFICATION = 'API_TRIGGER_PRIOR_YEAR_ACCRUAL_MANUAL_NOTIFICATION';
export const API_UPDATE_ACCOUNTING_DETAILS = 'API_UPDATE_ACCOUNTING_DETAILS';
export const EDIT_DELEGATE_TO = 'EDIT_DELEGATE_TO';
export const EDIT_DELEGATE_TO_VALID = 'EDIT_DELEGATE_TO_VALID';
export const DELETE_DELEGATE_TO = 'DELETE_DELEGATE_TO';
export const ADD_DELEGATE_TO = 'ADD_DELEGATE_TO';
export const EDIT_DELEGATE_TO_ACCESSRIGHTS = 'EDIT_DELEGATE_TO_ACCESSRIGHTS';
export const EDIT_DELEGATE_TO_COMPANYCODES = 'EDIT_DELEGATE_TO_COMPANYCODES';
export const EDIT_DELEGATE_TO_STARTDATE = 'EDIT_DELEGATE_TO_STARTDATE';
export const EDIT_DELEGATE_TO_ENDDATE = 'EDIT_DELEGATE_TO_ENDDATE';
export const USERPROFILE_STORE_PAGEDATA = 'USERPROFILE_STORE_PAGEDATA';
export const API_LOAD_COMPANIES = 'API_LOAD_COMPANIES';
export const API_SAVE_USER_PROFILE = 'API_SAVE_USER_PROFILE';
export const API_SGCHECK_REPORTS = 'API_SGCHECK_REPORTS';
export const API_DAILY_1010_REPORTS = 'API_DAILY_1010_REPORTS';
export const API_DAILY_NON_1010_REPORTS = 'API_DAILY_NON_1010_REPORTS';
export const API_DAILY_OPEN_ACCRUAL_1010_REPORTS = 'API_DAILY_OPEN_ACCRUAL_1010_REPORTS';
export const API_DAILY_OPEN_ACCRUAL_NON_1010_REPORTS = 'API_DAILY_OPEN_ACCRUAL_NON_1010_REPORTS';
export const API_DAILY_PRIOR_YEAR_ACCRUAL_REPORTS = 'API_DAILY_PRIOR_YEAR_ACCRUAL_REPORTS';
export const API_EXISTING_NOTIFICATIONS = 'API_EXISTING_NOTIFICATIONS';
export const API_DISMISS_NOTIFICATION = 'API_DISMISS_NOTIFICATION';
export const API_DISMISS_ALL_NOTIFICATIONS = 'API_DISMISS_ALL_NOTIFICATIONS';
export const UPSERT_NOTIFICATION_ITEM = 'UPSERT_NOTIFICATION_ITEM';
export const SHOW_NOTIFICATION_PANEL = 'SHOW_NOTIFICATION_PANEL';
export const CLEAR_NOTIFICATION_ITEM = 'CLEAR_NOTIFICATION_ITEM';
export const CLEAR_ALL_NOTIFICATION_ITEMS = 'CLEAR_ALL_NOTIFICATION_ITEMS';
export const CLOSE_LINE_ITEM_SELECTION = 'CLOSE_LINE_ITEM_SELECTION';
export const CLOSE_LINE_ITEM_COMMENTS = 'CLOSE_LINE_ITEM_COMMENTS';
export const CLOSE_LINE_ITEM_COMMENTS_HASERROR = 'CLOSE_LINE_ITEM_COMMENTS_HASERROR';
export const API_CLOSE_LINE = 'API_CLOSE_LINE';
export const API_ACCRUAL_OUT_OF_SYNC = 'API_ACCRUAL_OUT_OF_SYNC';
export const API_ACCRUAL_FAILURES = 'API_ACCRUAL_FAILURES';
export const API_PRIOR_YEAR_ACCRUAL_FAILURES = 'API_PRIOR_YEAR_ACCRUAL_FAILURES';
export const API_BULK_SAVE_PURCHASE_ORDERS = 'API_BULK_SAVE_PURCHASE_ORDERS';
export const API_LOAD_HIERARCHY = 'API_LOAD_HIERARCHY';
export const STORE_FINANCE_GEOGRAPHY_HIERARCHY_TREE_ITEM_STATES = 'STORE_FINANCE_GEOGRAPHY_HIERARCHY_TREE_ITEM_STATES';
export const STORE_CHANNEL_FUNCTION_HIERARCHY_TREE_ITEM_STATES = 'STORE_CHANNEL_FUNCTION_HIERARCHY_TREE_ITEM_STATES';
export const STORE_EXECUTIVE_FUNCTION_HIERARCHY_TREE_ITEM_STATES = 'STORE_EXECUTIVE_FUNCTION_HIERARCHY_TREE_ITEM_STATES';
export const CLEAR_HIERARCHY_STATE_INSTANCE = 'CLEAR_HIERARCHY_STATE_INSTANCE';
export const API_SUBMIT_ACCESS_REQUEST = 'API_SUBMIT_ACCESS_REQUEST';
export const API_LOAD_FINANCE_CONTROLLER_ACCESS_REQUEST_HISTORY = 'API_LOAD_FINANCE_CONTROLLER_ACCESS_REQUEST_HISTORY';
export const API_LOAD_ACCESS_REQUESTS_FOR_AGENT = 'API_LOAD_ACCESS_REQUESTS_FOR_AGENT';
export const RESET_API_LOAD_ACCESS_REQUESTS_FOR_AGENT = 'RESET_API_LOAD_ACCESS_REQUESTS_FOR_AGENT';
export const API_LOAD_ACCESS_REQUESTS_FOR_FINANCE_CONTROLLER = 'API_LOAD_ACCESS_REQUESTS_FOR_FINANCE_CONTROLLER';
export const RESET_API_LOAD_ACCESS_REQUESTS_FOR_FINANCE_CONTROLLER = 'RESET_API_LOAD_ACCESS_REQUESTS_FOR_FINANCE_CONTROLLER';
export const API_IS_AGENT_ACCESS_REQUEST_APPROVER = 'API_IS_AGENT_ACCESS_REQUEST_APPROVER';
export const PROFIT_CENTER_CODE_MAPPING_SORT_ON_COLUMN = 'PROFIT_CENTER_CODE_MAPPING_SORT_ON_COLUMN';
export const PROFIT_CENTER_CODE_MAPPING_UPDATE_SORTED = 'PROFIT_CENTER_CODE_MAPPING_UPDATE_SORTED';
export const API_LOAD_OPEN_AI_SAMPLE_QUESTIONS = 'API_LOAD_OPEN_AI_SAMPLE_QUESTIONS';
export const API_ASK_OPEN_AI = 'API_ASK_OPEN_AI';
export const API_REVERSE_GOODS_RECEIPT = 'API_REVERSE_GOODS_RECEIPT';
export const API_PURCHASE_ORDER_CHECK = 'API_PURCHASE_ORDER_CHECK';
export const API_COST_CATEGORY_CONFIG = 'API_COST_CATEGORY_CONFIG';
export const COST_CATEGORY_CONFIGURATION_SORT_ON_COLUMN = 'COST_CATEGORY_CONFIGURATION_SORT_ON_COLUMN';
export const COST_CATEGORY_CONFIGURATION_UPDATE_SORTED = 'COST_CATEGORY_CONFIGURATION_UPDATE_SORTED';
export const API_COST_CATEGORY_SUBCLASSES = 'API_COST_CATEGORY_SUBCLASSES';
export const API_COST_CATEGORY_GROUP_NAMES = 'API_COST_CATEGORY_GROUP_NAMES';
export const API_COST_CATEGORY_LINE_ITEMS = 'API_COST_CATEGORY_LINE_ITEMS';
export const API_COST_CATEGORY_LINE_ITEM_DETAILS = 'API_COST_CATEGORY_LINE_ITEM_DETAILS';
export const API_SAVE_COST_CATEGORY_CONFIG = 'API_SAVE_COST_CATEGORY_CONFIG';
export const API_DELETE_COST_CATEGORY_CONFIG = 'API_DELETE_COST_CATEGORY_CONFIG';
export const EDIT_COST_CATEGORY_CONFIGURATION = 'EDIT_COST_CATEGORY_CONFIGURATION';
export const DELETE_COST_CATEGORY_CONFIGURATION = 'DELETE_COST_CATEGORY_CONFIGURATION';
export const API_COST_CATEGORY_CONFIGURATION_FOR_GL = 'API_COST_CATEGORY_CONFIGURATION_FOR_GL';
export const API_PRIOR_YEAR_ACCRUAL_UPLOAD_MANUAL_POSTINGS = 'API_PRIOR_YEAR_ACCRUAL_UPLOAD_MANUAL_POSTINGS';
export const COPILOT_PANEL_IS_OPEN = 'COPILOT_PANEL_IS_OPEN';
export const NAV_IS_OPEN = 'NAV_IS_OPEN';
export const SHOW_VIDEO_GUIDE = 'SHOW_VIDEO_GUIDE';
export const DISMISS_VIDEO_GUIDE = 'DISMISS_VIDEO_GUIDE';
