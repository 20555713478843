import axios, { AxiosRequestConfig } from 'axios';
import { appConstants, localStorageKey } from '../common/appConstants';

/**
 * Interface that should match the structure of the environment config file.
 */
export interface IAppConfig {
    settings: {
        environmentName: string;
        displayEnvNameInHeader: boolean;
        displayDriToolsPage: boolean;
        displayDiagnosticsPage: boolean;
        priorYearAccrualDefaultPageSize: number;
        priorYearAccrualPageSizes: number[];
        videoGuideRootUrl: string;
        jemUrl: string;
    }
    copilot: {
        copilotWebComponentUrl: string;
        copilotApiBaseUrl: string;
        copilotApiScope: string;
        copilotAppId: string;
        fceAppInsightsInstrumentationKey: string;
    }
    ocv: {
        isProdEnv: boolean;
        appId: number;
    }
    instrumentation: {
        appInsightsInstrumentationKey: string;
    }
    service: {
        receiptingApiBaseUrl: string;
        receiptingAccrualsApiBaseUrl: string;
        receiptingShipmentApiBaseUrl: string;
        useLocalMockData: boolean;
        useLocalMockDataSimulatedDelay: number;
    }
    signalR: {
        clientNotificationHub: string;
        useLocalMockData: boolean;
    }
    graph: {
        baseUrl: string;
    }
    msal: {
        clientId: string;
        authority: string;
        redirectUri: string;
        postLogoutRedirectUri: string;
        storeAuthStateInCookie: boolean;
        cacheLocation: 'localStorage' | 'sessionStorage' | undefined;
        graphOpenIdScope: string;
        graphUserReadScope: string;
        receiptingApiScope: string;
        shipmentApiScope: string;
    }
    financeBot: {
        fileUrl: string;
        hostAppId: string;
    }
    featureFlighting: {
        exportImportAccountingDetails: boolean;
        serviceLineAdjustAmount: boolean;
        copilot: boolean;
    }
    build: string;
}

class AppConfig {
    private config?: IAppConfig = undefined;
    private afterLoadCallbacks: (() => void)[] = [];

    /**
     * Gets the current config.
     */
    public get current(): IAppConfig {
        if (!this.config) {
            throw new Error('App config is undefined.');
        }
        return (this.config as unknown) as IAppConfig;
    }

    /**
     * Loads config json file for current environment.
     * Uses environment variable set in environment files such as .env.development and .env.production.
     * See: https://create-react-app.dev/docs/adding-custom-environment-variables
     * Example value: REACT_APP_UI_ENV=dev
     * Note that when running the project using 'npm start' this will cause the .env.development to be used
     * while building with npm run build will cause the .env.production to be used.
     * Based on this environment variable, this function will load the corresponding app config json file
     * from the /public/config folder (such as dev.json or prod.json).
     */
    public async loadConfig(): Promise<void> {
        const config: AxiosRequestConfig = {
            headers: { 'Content-Type': 'application/json' }
        }

        try {
            const response = await axios.get(`${appConstants.publicUrl}/config/${appConstants.env}.json`, config);
            this.config = response.data as IAppConfig;

            if (this.config.settings.environmentName !== 'PROD') {
                // Override the useLocalMockData option for service and signalR if there is a item in local storage
                // to override this value. This is used only in PPE to toggle between real data or mock data.
                // If there is no value in local storage for this, then leave it as set in the config file.
                if (localStorage.getItem(localStorageKey.useLocalMockData) === 'true') {
                    this.config.service.useLocalMockData = true;
                } else if (localStorage.getItem(localStorageKey.useLocalMockData) === 'false') {
                    this.config.service.useLocalMockData = false;
                }
            }
        } catch (error) {
            // If the response is not valid json then the response.json() will throw an exception. This could happen
            // if the config json didn't exist on the web server, in which case the React app will be loaded with that
            // path as the page route. For example, if the file ${envConfigFile}.json is not in the public folder then
            // the attempted route would be (example): https://siteroot/config/configfilemissing.json).
            // Of which the page is html and not json, thus the response.json() will throw an exception.
            console.error(error);
            throw new Error(`Failed to load config. ${JSON.stringify(error)}`);
        }

        this.callRegisteredCallbacks();
    }

    /**
     * Register a callback to be called after config is loaded.
     * If config is already loaded, callback will be called immediately.
     * @param afterLoadCallback Callback function to be called after config is loaded.
     */
    public async registerConfigLoadedCallback(afterLoadCallback: () => void) {
        if (this.config) {
            afterLoadCallback();
        } else {
            this.afterLoadCallbacks.push(afterLoadCallback);
        }
    }

    /**
     * Call registered callback functions.
     */
    private callRegisteredCallbacks(): void {
        this.afterLoadCallbacks.forEach(callback => {
            callback();
        });

        // Reset the array.
        this.afterLoadCallbacks = [];
    }
}

/**
 * AppConfig singleton instance.
 */
export const appConfig: AppConfig = new AppConfig();
