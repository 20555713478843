import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { CoherenceTheme } from '@coherence-design-system/styles';
import {
    ThemeProvider,
    Text,
    Spinner,
    SpinnerSize,
    Stack
} from '@fluentui/react';
import { AppHeader } from './shell/AppHeader/AppHeader';
import { AppNav } from './shell/AppNav/AppNav';
import { HomePage } from './pages/HomePage/HomePage';
import { DiagnosticsPage } from './pages/DiagnosticsPage/DiagnosticsPage';
import { appStyles } from './App.styles';
import { UserProfilePage } from './pages/UserProfilePage/UserProfilePage';
import { AccessRequestPage } from './pages/AccessRequestPage/AccessRequestPage';
import { ReportsPage } from './pages/ReportsPage/ReportsPage';
import { AccrualDashboardPage } from './pages/AccrualDashboardPage/AccrualDashboardPage';
import { DriToolsPage } from './pages/DriToolsPage/DriToolsPage';
import { useMountEffect } from './common/hooks/useMountEffect';
import { getUserAlias } from './services/auth/msalHelper';
import { CallApiState } from './store/actions/generic.action';
import { commonStyles } from './common/common.styles';
import { EditPage } from './pages/EditPage/EditPage';
import { SearchResultsPage } from './pages/SearchResultsPage/SearchResultsPage';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { callApiLoadUserProfile, IApiLoadUserProfile, setCopilotPanelIsOpen } from './store/actions/app.action';
import { appConstants } from './common/appConstants';
import { Fda } from './shell/Fda/Fda';
import { PriorYearAccrualPage } from './pages/PriorYearAccrualPage/PriorYearAccrualPage';
import { AppDispatch } from './store/reduxStore';
import { AccessDeniedPage } from './pages/AccessDeniedPage/AccessDeniedPage';
import { CloseLinePage } from './pages/CloseLinePage/CloseLinePage';
import { FaqPage } from './pages/FaqPage/FaqPage';
import { CopilotPanel } from './shell/CopilotPanel/CopilotPanel';

interface IAppProps {
}

export const App: React.FunctionComponent<IAppProps> = (props: IAppProps): JSX.Element => {
    // Redux store selectors to get state from the store when it changes.
    const apiLoadUserProfile: IApiLoadUserProfile =
        useAppSelector<IApiLoadUserProfile>((state) => state.appReducer.apiLoadUserProfile);
    const copilotPanelIsOpen: boolean =
        useAppSelector<boolean>((state) => state.appReducer.copilotPanelIsOpen);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        // Load the user profile.
        const userAlias: string = getUserAlias();
        dispatch(callApiLoadUserProfile(userAlias));
    });

    return (
        <ThemeProvider theme={CoherenceTheme}>
            <div className={appStyles.windowContainer}>
                { apiLoadUserProfile.callApiState === CallApiState.Running && (
                    <div className={commonStyles.absoluteCenter}>
                        <Text variant='mediumPlus'>Loading...</Text>
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    </div>
                )}
                { apiLoadUserProfile.callApiState === CallApiState.Failed && (
                    <div className={`${commonStyles.errorText} ${commonStyles.absoluteCenter}`}>
                        <Text variant='mediumPlus'>Sorry, we are having technical difficulties. Please try again later.</Text>
                    </div>
                )}
                { apiLoadUserProfile.callApiState === CallApiState.Completed && (
                    <>
                        {/* Only load header if user profile call succeeded. As it displays the user profile in the */}
                        {/* header, and also the notification panel, which both first require that the user profile loaded. */}
                        <BrowserRouter>
                            {/* The AppHeader is in the BrowserRouter so that it can use useNavigate() */}
                            <AppHeader />

                            <div className={appStyles.pageContainer}>
                                <AppNav />
                                <Stack horizontal wrap={false}>
                                    <Stack.Item>
                                        <main id="main" tabIndex={-1} className={appStyles.mainContainer}>
                                            <div className={appStyles.mainContent}>
                                                <Routes>
                                                    <Route path={`${appConstants.publicUrl}/Home`} caseSensitive={false} element={
                                                        <HomePage />
                                                    } />
                                                    <Route path={`${appConstants.publicUrl}/SearchResults`} caseSensitive={false} element={
                                                        <SearchResultsPage />
                                                    } />
                                                    <Route path={`${appConstants.publicUrl}/Edit/:poNumber`} caseSensitive={false} element={
                                                        <EditPage />
                                                    } />
                                                    <Route path={`${appConstants.publicUrl}/CloseLine/:poNumber`} caseSensitive={false} element={
                                                        <CloseLinePage />
                                                    } />
                                                    <Route path={`${appConstants.publicUrl}/UserProfile`} caseSensitive={false} element={
                                                        <UserProfilePage />
                                                    } />
                                                    <Route path={`${appConstants.publicUrl}/AccessRequest/:activeTab`} caseSensitive={false} element={
                                                        <AccessRequestPage />
                                                    } />
                                                    <Route path={`${appConstants.publicUrl}/AccessRequest`} caseSensitive={false} element={<Navigate to={`${appConstants.publicUrl}/AccessRequest/Requests`} />} />
                                                    <Route path={`${appConstants.publicUrl}/Reports`} caseSensitive={false} element={
                                                        <ReportsPage />
                                                    } />
                                                    <Route path={`${appConstants.publicUrl}/PriorYearAccrual/:activeTab`} caseSensitive={false} element={
                                                        <PriorYearAccrualPage />
                                                    } />
                                                    <Route path={`${appConstants.publicUrl}/PriorYearAccrual`} caseSensitive={false} element={<Navigate to={`${appConstants.publicUrl}/PriorYearAccrual/ForYou`} />} />
                                                    <Route path={`${appConstants.publicUrl}/AccrualDashboard`} caseSensitive={false} element={
                                                        <AccrualDashboardPage />
                                                    } />
                                                    <Route path={`${appConstants.publicUrl}/FAQ`} caseSensitive={false} element={
                                                        <FaqPage />
                                                    } />
                                                    <Route path={`${appConstants.publicUrl}/DriTools`} caseSensitive={false} element={
                                                        <DriToolsPage />
                                                    } />
                                                    <Route path={`${appConstants.publicUrl}/Diagnostics`} caseSensitive={false} element={ 
                                                        <DiagnosticsPage />
                                                    } />
                                                    <Route path={`${appConstants.publicUrl}/AccessDenied`} caseSensitive={false} element={ 
                                                        <AccessDeniedPage />
                                                    } />
                                                    <Route path="*" caseSensitive={false} element={<Navigate to={`${appConstants.publicUrl}/Home`} />} />
                                                </Routes>
                                            </div>
                                        </main>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <div className={copilotPanelIsOpen ? appStyles.copilotContainer : appStyles.copilotContainerCollapsed}>
                                            <CopilotPanel
                                                isOpen={copilotPanelIsOpen}
                                                onDismissCopilotPanel={() => {
                                                    dispatch(setCopilotPanelIsOpen(!copilotPanelIsOpen));
                                                }}
                                            />
                                        </div>
                                    </Stack.Item>
                                </Stack>
                            </div>
                        </BrowserRouter>
                        <Fda />
                    </>
                )}
            </div>
        </ThemeProvider>
    );
};
