import React from 'react';
import {
    IColumn,
    IDetailsColumnProps,
    Stack,
    Text
} from '@fluentui/react';
import { PurchaseOrderLineItem } from '../../models/purchaseOrder/purchaseOrderLineItem';
import { ReceivedQuantityInput } from './ReceivedQuantityInput';
import { AssetTagInput } from './AssetTagInput';
import { SerialNumberInput } from './SerialNumberInput';
import { RecipientNameInput } from './RecipientNameInput';
import { commonStyles } from '../../common/common.styles';
import { DetailsActionButton } from '../../components/DetailsActionButton/DetailsActionButton';
import { LineItemStatusIndicator } from '../../components/LineItemStatusIndicator/LineItemStatusIndicator';
import { ClosedIndicator } from '../../components/ClosedIndicator/ClosedIndicator';
import { CustomColumnHeader } from '../../components/CustomColumnHeader/CustomColumnHeader';
import { tooltips } from '../../common/tooltips';
import { AuditActionButton } from '../../components/AuditActionButton/AuditActionButton';
import { ShipmentInfoActionButton } from '../../components/ShipmentInfoActionButton/ShipmentInfoActionButton';

export const goodsLineItemColumns: IColumn[] = [
    {
        key: 'lineStatus',
        fieldName: undefined,
        name: '',
        isResizable: false,
        minWidth: 10,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <div className={commonStyles.statusColumnContainer}>
                    <LineItemStatusIndicator usedOnPage='edit' item={item} />
                </div>
            );
        }
    },
    {
        key: 'purchaseOrderLineNumber',
        fieldName: 'purchaseOrderLineNumber',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1=''
                    headerLine2='Line'
                    tooltip={tooltips.poLineNumber}
                />
            );
        },
        isResizable: false,
        minWidth: 50,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <>
                    <DetailsActionButton usedOnPage='editOrCloseLineItem' item={item} />
                    <ClosedIndicator item={item} usedOnPage='editPage' />
                </>
            );
        },
        isRowHeader: true
    },
    {
        key: 'info',
        fieldName: undefined,
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1=''
                    headerLine2='Info'
                    tooltip={tooltips.auditHistory}
                />
            );
        },
        isResizable: false,
        minWidth: 56,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <Stack horizontal wrap={false}>
                    <AuditActionButton usedOnPage='editOrCloseLineItem' item={item} />
                    <ShipmentInfoActionButton item={item} />
                </Stack>
            );
        },
        isRowHeader: true
    },
    {
        key: 'received',
        fieldName: 'received',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1=''
                    headerLine2='Received'
                    tooltip={tooltips.received}
                />
            );
        },
        isResizable: false,
        minWidth: 70,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <ReceivedQuantityInput item={item} />
            );
        }
    },
    {
        key: 'assetTag',
        fieldName: 'assetTag',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Asset'
                    headerLine2='Tag'
                    tooltip={tooltips.assetTag}
                />
            );
        },
        isResizable: true,
        minWidth: 160,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <AssetTagInput item={item} />
            );
        }
    },
    {
        key: 'serialNumber',
        fieldName: 'serialNumber',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Serial'
                    headerLine2='Number'
                    tooltip={tooltips.serialNumber}
                />
            );
        },
        isResizable: true,
        minWidth: 160,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <SerialNumberInput item={item} />
            );
        }
    },
    {
        key: 'recipientName',
        fieldName: 'recipientName',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Recipient'
                    headerLine2='Alias'
                    tooltip={tooltips.recipientName}
                />
            );
        },
        isResizable: true,
        minWidth: 160,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <RecipientNameInput item={item} />
            );
        }
    },
    {
        key: 'lineDescription',
        fieldName: 'lineDescription',
        name: '', // Specified in onRenderHeader below.
        onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
            return (
                <CustomColumnHeader
                    headerLine1='Line'
                    headerLine2='Description'
                    tooltip={tooltips.lineDescription}
                />
            );
        },
        isResizable: true,
        minWidth: 250,
        onRender: (item: PurchaseOrderLineItem) => {
            return (
                <Text className={commonStyles.textCell}>
                    {item.description}
                </Text>
            );
        }
    }
];
