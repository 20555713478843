import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useId } from '@fluentui/react-hooks';
import {
    ChoiceGroup,
    ComboBox,
    DatePicker,
    DefaultButton,
    FontIcon,
    IChoiceGroup,
    IChoiceGroupOption,
    IComboBox,
    IComboBoxOption,
    Image,
    Label,
    Link,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField,
    Toggle,
    TooltipDelay,
    TooltipHost
} from '@fluentui/react';
import { ICommonPageProps } from '../../common/common.types';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import { SectionWrapper } from '../../components/SectionWrapper/SectionWrapper';
import { Section } from '../../components/Section/Section';
import { getUserFirstName } from '../../services/auth/msalHelper';
import { commonStyles, horizontalChoiceGroupStyle, stackTokensLargeGap, stackTokensNormalGap } from '../../common/common.styles';
import { pageStyles } from './HomePage.styles';
import { validationConstants } from '../../common/validationConstants';
import {
    ViewAsRoleOptionKey,
    viewAsRoleOptions,
    SearchType,
    purchaseOrderSearchInitial,
    filterIncludeReceivedOptions,
    filterIncludeGoodsServicesOptions,
    IncludeReceivedOptionKey,
    IncludeGoodsServicesOptionKey,
    pageStrings
} from './homePageConstants';
import { numberAsLocaleString, padNumberWithLeadingChar } from '../../common/common.func.transform';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { TileSet } from '../../models/dashboard/tileSet';
import { CallApiState } from '../../store/actions/generic.action';
import { LoadFailureDisplay } from '../../components/LoadFailureDisplay/LoadFailureDisplay';
import { DashboardTile } from '../../models/dashboard/dashboardTile';
import { TileType } from '../../models/dashboard/tileType';
import { PurchaseOrderSearch } from '../../models/purchaseOrder/purchaseOrderSearch';
import { deepCopyObject } from '../../common/common.func.general';
import { PurchaseOrderSearchFilter } from '../../models/purchaseOrder/purchaseOrderSearchFilter';
import { NavigateFunction, useLocation, useNavigate } from 'react-router';
import {
    callApiHomeTileInfo,
    callApiSearch,
    clearSearchResults,
    IApiHomeTileInfo,
    IApiSearch,
    inputFilterCompanyCode,
    inputFilterDateCreatedFrom,
    inputFilterDateCreatedTo,
    inputFilterDelegatedBy,
    inputFilterDelegatedByOptions,
    inputFilterDeliveryDateFrom,
    inputFilterDeliveryDateTo,
    inputFilterDriSearchEnabled,
    inputFilterHierarchyChannelFunctionDetailCodes,
    inputFilterHierarchyExecutiveFunctionDetailCodes,
    inputFilterHierarchySalesDistrictCodes,
    inputFilterIncludeGoodsServices,
    inputFilterIncludeReceived,
    inputFilterInvoiceApprover,
    inputFilterPoApprover,
    inputFilterPoNumber,
    inputFilterPoOwner,
    inputFilterSupplierNumber,
    resetApiCallStateForHomeTileInfo,
    resetApiCallStateForSearch,
    resetApiCallStateForSearchForPo
} from '../../store/actions/search.action';
import { UserProfile } from '../../models/user/userProfile';
import { appConstants } from '../../common/appConstants';
import { AppDispatch } from '../../store/reduxStore';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { HierarchySelection } from '../../components/HierarchySelection/HierarchySelection';
import { tooltips } from '../../common/tooltips';
import { commonString } from '../../common/commonString';
import { GraphUserInput } from '../../components/GraphUserInput/GraphUserInput';
import { GraphUser } from '../../models/user/graphUser';
import { SupplierInput } from '../../components/SupplierInput/SupplierInput';
import { Supplier } from '../../models/domain/supplier';
import { showVideoGuide, teachingBubbleClearArray } from '../../store/actions/app.action';
import { InitialVideo } from '../../shell/VideoGuide/VideoGuide';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { changeTileSet } from '../../store/actions/pageActions/homePage.action';
import { ImportExport } from '../../components/ImportExport/ImportExport';
import { appConfig } from '../../shell/appConfig';

interface IPageProps extends ICommonPageProps {
}

/**
 * Home page.
 * @param props Page props.
 * @returns JSX for the page.
 */
export const HomePage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);

    const tileHomeOpen = useRef<DashboardTile>();
    const tileHomeDueThisMonth = useRef<DashboardTile>();
    const tileHomeOverDue = useRef<DashboardTile>();
    const tileFinanceControllerOpen = useRef<DashboardTile>();
    const tileFinanceControllerDueThisMonth = useRef<DashboardTile>();
    const tileFinanceControllerUnavailableOwner = useRef<DashboardTile>();
    const tileFinanceControllerUnavailableApprover = useRef<DashboardTile>();
    const tileFinanceControllerReceiptedByFinanceController = useRef<DashboardTile>();
    const searchType = useRef<SearchType>(SearchType.None);

    const poNumberInputId: string = useId();
    const companyCodeInputId: string = useId();
    const dateCreatedFromInputId: string = useId();
    const dateCreatedToInputId: string = useId();
    const deliveryDateFromInputId: string = useId();
    const deliveryDateToInputId: string = useId(); 
    const delegatedByInputId: string = useId();
    const includeReceivedInputId: string = useId();
    const includeGoodsServicesInputId: string = useId();

    const [poNumberValidationError, setPoNumberValidationError] = useState<string>('');
    const [supplierNumberValidationError, setSupplierNumberValidationError] = useState<string>('');
    const [companyCodeValidationError, setCompanyCodeValidationError] = useState<string>('');
    const [poApproverValidationError, setPoApproverValidationError] = useState<string>('');
    const [poOwnerValidationError, setPoOwnerValidationError] = useState<string>('');
    const [invoiceApproverValidationError, setInvoiceApproverValidationError] = useState<string>('');

    const [clearSupplierInputCounter, setClearSupplierInputCounter] = useState<number>(0);
    const [clearPoApproverInputCounter, setClearPoApproverInputCounter] = useState<number>(0);
    const [clearPoOwnerInputCounter, setClearPoOwnerInputCounter] = useState<number>(0);
    const [clearInvoiceApproverInputCounter, setClearInvoiceApproverInputCounter] = useState<number>(0);

    const [autoSearch, setAutoSearch] = useState<string | undefined>();
    const autoSearchInitiated = useRef<boolean>(false);

    const viewAsRoleChoiceGroupRef = useRef<IChoiceGroup | null>();
    const viewAsRoleChoiceGroupFocused = useRef<boolean>();

    const randomImageNum = useRef<number>(Math.floor(Math.random() * 10) + 1); // Currently have 10 images.

    // Redux store selectors to get state from the store when it changes.
    const apiHomeTileInfo: IApiHomeTileInfo =
        useAppSelector<IApiHomeTileInfo>((state) => state.searchReducer.apiHomeTileInfo);
    const apiSearch: IApiSearch =
        useAppSelector<IApiSearch>((state) => state.searchReducer.apiSearch);
    const userProfile: UserProfile | undefined =
        useAppSelector<UserProfile | undefined>(state => state.appReducer.apiLoadUserProfile.userProfile);
    const currentTileSet: TileSet =
        useAppSelector<TileSet>(state => state.homePageReducer.currentTileSet);
    const selectedViewAsRoleOptionKey: ViewAsRoleOptionKey =
        useAppSelector<ViewAsRoleOptionKey>(state => state.homePageReducer.selectedViewAsRoleOptionKey);
    // Input filters are stored in Redux, so that they persist when navigating pages and then back to here.
    const driSearchEnabled: boolean =
        useAppSelector<boolean>((state) => state.searchReducer.inputFilter.driSearchEnabled);
    const filterPoNumber: string =
        useAppSelector<string>((state) => state.searchReducer.inputFilter.filterPoNumber);
    const filterSupplierNumber: string =
        useAppSelector<string>((state) => state.searchReducer.inputFilter.filterSupplierNumber);
    const filterCompanyCode: string =
        useAppSelector<string>((state) => state.searchReducer.inputFilter.filterCompanyCode);
    const filterPoApprover: string =
        useAppSelector<string>((state) => state.searchReducer.inputFilter.filterPoApprover);
    const filterPoOwner: string =
        useAppSelector<string>((state) => state.searchReducer.inputFilter.filterPoOwner);
    const filterInvoiceApprover: string =
        useAppSelector<string>((state) => state.searchReducer.inputFilter.filterInvoiceApprover);
    const filterDateCreatedFrom: Date | undefined =
        useAppSelector<Date | undefined>((state) => state.searchReducer.inputFilter.filterDateCreatedFrom);
    const filterDateCreatedTo: Date | undefined =
        useAppSelector<Date | undefined>((state) => state.searchReducer.inputFilter.filterDateCreatedTo);
    const filterDeliveryDateFrom: Date | undefined =
        useAppSelector<Date | undefined>((state) => state.searchReducer.inputFilter.filterDeliveryDateFrom);
    const filterDeliveryDateTo: Date | undefined =
        useAppSelector<Date | undefined>((state) => state.searchReducer.inputFilter.filterDeliveryDateTo);
    const filterDelegatedBy: string[] | undefined =
        useAppSelector<string[] | undefined>((state) => state.searchReducer.inputFilter.filterDelegatedBy);
    const filterDelegatedByOptions: IComboBoxOption[] =
        useAppSelector<IComboBoxOption[]>((state) => state.searchReducer.inputFilter.filterDelegatedByOptions);
    const filterHierarchySalesDistrictCodes: string[] =
        useAppSelector<string[]>((state) => state.searchReducer.inputFilter.filterHierarchySalesDistrictCodes);
    const filterHierarchyChannelFunctionDetailCodes: string[] =
        useAppSelector<string[]>((state) => state.searchReducer.inputFilter.filterHierarchyChannelFunctionDetailCodes);
    const filterHierarchyExecutiveFunctionDetailCodes: string[] =
        useAppSelector<string[]>((state) => state.searchReducer.inputFilter.filterHierarchyExecutiveFunctionDetailCodes);
    const filterIncludeReceived: IncludeReceivedOptionKey =
        useAppSelector<IncludeReceivedOptionKey>((state) => state.searchReducer.inputFilter.filterIncludeReceived);
    const filterIncludeGoodsServices: IncludeGoodsServicesOptionKey =
        useAppSelector<IncludeGoodsServicesOptionKey>((state) => state.searchReducer.inputFilter.filterIncludeGoodsServices);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    // If the PO filter is used, then this flag will make it so only that field can be used and disable all the other filters.
    const [useFilterPoLineOnly, setUseFilterPoLineOnly] = useState<boolean>(filterPoNumber.length > 0);

    const location = useLocation();
    const navigate: NavigateFunction = useNavigate();

    /**
     * Effect that returns a cleanup function.
     */
    useEffect(() => {
        return () => {
            // Clear the teaching bubble array for this page.
            dispatch(teachingBubbleClearArray());
        }
    }, [dispatch]);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiHomeTileInfo.errMsg) {
            handleError(apiHomeTileInfo.errMsg);
        }
        if (apiSearch.errMsg) {
            handleError(apiSearch.errMsg);
        }
    }, [apiHomeTileInfo.errMsg, apiSearch.errMsg, handleError]);

    /**
     * Effect to check for autoSearch URL parameter.
     */
    useEffect(() => {
        const usp: URLSearchParams = new URLSearchParams(location.search);

        let ats: string | undefined = usp.get('autoSearch') || undefined;
        // Only allow certain values for autoSearch.
        if (!(ats === 'HomeOpen' || ats === 'HomeDueThisMonth' || ats === 'HomeOverDue')) {
            // If not a valid value, then set to undefined.
            ats = undefined;
        }

        setAutoSearch(ats);
    }, [location.search]);

    /**
     * Load for home tiles.
     */
    const loadHomeTiles = useCallback(() => {
        apiHomeTileInfo.dashboardTiles?.forEach(x => {
            switch (x.tileType) {
                case TileType.HomeOpen: {
                    tileHomeOpen.current = x;
                    break;
                }
                case TileType.HomeDueThisMonth: {
                    tileHomeDueThisMonth.current = x;
                    break;
                }
                case TileType.HomeOverDue: {
                    tileHomeOverDue.current = x;
                    break;
                }
                case TileType.FinanceControllerUnavailableOwner: {
                    tileFinanceControllerUnavailableOwner.current = x;
                    break;
                }
                case TileType.FinanceControllerUnavailableApprover: {
                    tileFinanceControllerUnavailableApprover.current = x;
                    break;
                }
                case TileType.FinanceControllerDueThisMonth: {
                    tileFinanceControllerDueThisMonth.current = x;
                    break;
                }
                case TileType.FinanceControllerOpen: {
                    tileFinanceControllerOpen.current = x;
                    break;
                }
                case TileType.FinanceControllerReceiptedByFinanceController: {
                    tileFinanceControllerReceiptedByFinanceController.current = x;
                    break;
                }
                default:
            }
        });
    }, [apiHomeTileInfo.dashboardTiles]);

    /**
     * Performs a search.
     * @param newPurchaseOrderSearch New purchase order search.
     * @param poSearchByNumber PO search by number only.
     */
    const performSearch = useCallback((newPurchaseOrderSearch: PurchaseOrderSearch, poSearchByNumber: boolean) => {
        dispatch(resetApiCallStateForSearch());
        dispatch(resetApiCallStateForSearchForPo());
        dispatch(clearSearchResults());

        if (poSearchByNumber) {
            // If searching using PO number only, then navigate directly to the edit screen for that PO.
            const po: string = padNumberWithLeadingChar(filterPoNumber, 10, '0');
            navigate(`${appConstants.publicUrl}/Edit/${po}`);
        } else {
            dispatch(callApiSearch(newPurchaseOrderSearch));
        }
    }, [dispatch, filterPoNumber, navigate]);

    /**
     * Effect for when userProfile changes.
     */
    useEffect(() => {
        // Add all unexpired delegate from users (for all rights including owner and invoice approver).
        const unexpiredDelegateFromUsers: string[] = userProfile?.getUnexpiredDelegateFromUsers() || [];

        // Update the filter for delegated by.
        dispatch(inputFilterDelegatedBy(unexpiredDelegateFromUsers))

        // Update delegated by options.
        dispatch(inputFilterDelegatedByOptions([
            ...(unexpiredDelegateFromUsers).map((alias)=> {
                return {
                    key: alias,
                    text: alias,
                    selected: true
                } as IComboBoxOption;
            })
        ]));
    }, [dispatch, userProfile]);

    /**
     * Set standard search filter options for regular searches.
     * Not used with tile searches as all criteria for tile searches comes from the server side.
     * @param purchaseOrderSearchFilter Purchase order search filter.
     */
    const setStandardSearchFilterOptions = useCallback((purchaseOrderSearchFilter: PurchaseOrderSearchFilter) => {
        purchaseOrderSearchFilter.includeReceived = filterIncludeReceived === IncludeReceivedOptionKey.Yes;

        switch (filterIncludeGoodsServices) {
            case IncludeGoodsServicesOptionKey.Goods: {
                purchaseOrderSearchFilter.includeGsrCor = 'COR';
                break;
            }
            case IncludeGoodsServicesOptionKey.Services: {
                purchaseOrderSearchFilter.includeGsrCor = 'GSR';
                break;
            }
            default:
            case IncludeGoodsServicesOptionKey.Both: {
                purchaseOrderSearchFilter.includeGsrCor = 'Both';
                break;
            }
        }
    }, [filterIncludeGoodsServices, filterIncludeReceived]);

    /**
     * Effect for when current tile set changes.
     * This is called automatically during initial load and any time tile set changes when a
     * Finance Controller user toggles between tile sets.
     */
    useEffect(() => {
        if (apiHomeTileInfo.callApiState === CallApiState.Completed &&
            apiHomeTileInfo.dashboardTiles) {
            loadHomeTiles();

            // This will reset the focus back onto the ChoiceGroup after the data loads. Between render passes, the focus
            // gets lost on the ChoiceGroup if it was previously selected. If using keyboard navigation and a choice in the
            // ChoiceGroup is selected, this will keep focus on the ChoiceGroup between renders.
            if (viewAsRoleChoiceGroupFocused.current) {
                viewAsRoleChoiceGroupRef.current?.focus();
            }
        } else if (apiHomeTileInfo.callApiState === CallApiState.Initial) {
            // Using deepCopyObject to keep the purchaseOrderSearchInitial object unmodified. 
            const newPurchaseOrderSearch: PurchaseOrderSearch = deepCopyObject(purchaseOrderSearchInitial) as PurchaseOrderSearch;
            newPurchaseOrderSearch.filter = newPurchaseOrderSearch.filter || {};

            // Not calling setStandardSearchFilterOptions as that is not needed for the home tile info api call.
            // The includeReceived and includeGsrCor are not used in this call to get tile info.

            // For this api call to get tile info (PO and line counts for various tiles - for regular user or finance controller),
            // most all filter options are not used by the api and are left blank here. The selectedUsers (for delegates) is used
            // only for regular user home tile set and not finance controller tile set.

            // Add all unexpired delegate from users (for all rights including owner and invoice approver). Plus the active user alias.
            newPurchaseOrderSearch.filter.selectedUsers = [...(userProfile?.getUnexpiredDelegateFromUsers() || []), userProfile!.alias];

            dispatch(callApiHomeTileInfo(newPurchaseOrderSearch, currentTileSet));
        }
    }, [apiHomeTileInfo.callApiState, apiHomeTileInfo.dashboardTiles, currentTileSet, dispatch, loadHomeTiles, setStandardSearchFilterOptions, userProfile]);

    /**
     * Effect that returns a cleanup function. This is called when the component is destroyed when the user changes pages.
     */
    useEffect(() => {
        return () => {
            // Reset the call state for the tile info. So when the user returns to this page, it will reload
            // the tiles. The reason this is done is because when users do receipting or line close on pages like
            // the edit, close, or PYA pages... when they come back here the tile counts should be updated to
            // reflect any changes after the user actions.
            dispatch(resetApiCallStateForHomeTileInfo());
        };
    }, [dispatch]);

    /**
     * Effect for when apiSearch returns data.
     */
     useEffect(() => {
        if (apiSearch.callApiState === CallApiState.DataAvailable) {
            navigate(`${appConstants.publicUrl}/SearchResults`);
        }
    }, [apiSearch.callApiState, navigate]);

    /**
     * DRI search toggle handler.
     * @param ev Mouse event.
     * @param checked Checked boolean.
     */
    const driSearchToggle = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        dispatch(inputFilterDriSearchEnabled(checked || false));
    };

    /**
     * Search using tile.
     * @param dashboardTile Dashboard tile containing search filter.
     */
    const searchUsingTile = useCallback((dashboardTile: DashboardTile) => {
        const newPurchaseOrderSearch: PurchaseOrderSearch = deepCopyObject(purchaseOrderSearchInitial) as PurchaseOrderSearch;

        // Set the search filter to be the filter used with this tile.
        // Use deepCopyObject so any changes can be reverted back to the original filter. The filter metadata
        // comes with the tile data.
        newPurchaseOrderSearch.filter = deepCopyObject(dashboardTile.purchaseOrderSearchFilter) as PurchaseOrderSearchFilter;

        // For the standard For You tiles, when searching add the delegates.
        if (dashboardTile.tileType === TileType.HomeOpen ||
            dashboardTile.tileType === TileType.HomeDueThisMonth ||
            dashboardTile.tileType === TileType.HomeOverDue) {
            // Add all unexpired delegate from users (for all rights including owner and invoice approver). Plus the active user alias.
            newPurchaseOrderSearch.filter.selectedUsers = [...(userProfile?.getUnexpiredDelegateFromUsers() || []), userProfile!.alias];
        }

        performSearch(newPurchaseOrderSearch, false);
    }, [performSearch, userProfile]);

    /**
     * Build purchase order search based on filter inputs.
     * @returns Purchase order search object.
     */
    const buildPurchaseOrderSearch = useCallback((): PurchaseOrderSearch => {
        const newPurchaseOrderSearch: PurchaseOrderSearch = deepCopyObject(purchaseOrderSearchInitial) as PurchaseOrderSearch;

        newPurchaseOrderSearch.filter = newPurchaseOrderSearch.filter || {};
        setStandardSearchFilterOptions(newPurchaseOrderSearch.filter);

        // Note that search using PO number is not offered with the search filter. Searching by PO number is a feature
        // offered in the app header.
        newPurchaseOrderSearch.filter.vendorNumber = filterSupplierNumber.trim().length === 0 ? undefined : padNumberWithLeadingChar(filterSupplierNumber, 10, '0');
        newPurchaseOrderSearch.filter.companyCode = filterCompanyCode.trim().length === 0 ? undefined : filterCompanyCode;
        newPurchaseOrderSearch.filter.purchaseOrderApprover = filterPoApprover.trim().length === 0 ? undefined : filterPoApprover;
        newPurchaseOrderSearch.filter.purchaseOrderRequestor = filterPoOwner.trim().length === 0 ? undefined : filterPoOwner;
        newPurchaseOrderSearch.filter.purchaseOrderInvoiceApprover = filterInvoiceApprover.trim().length === 0 ? undefined : filterInvoiceApprover;
        newPurchaseOrderSearch.filter.dateCreatedFrom = filterDateCreatedFrom;
        newPurchaseOrderSearch.filter.dateCreatedTo = filterDateCreatedTo;
        newPurchaseOrderSearch.filter.deliveryDateFrom = filterDeliveryDateFrom;
        newPurchaseOrderSearch.filter.deliveryDateTo = filterDeliveryDateTo;

        // Add the delegates only if the user is not a Finance Controller or Agent (delegation is irrelevant
        // and does not work in these cases).
        if (!(userProfile?.isFinanceController || userProfile?.isAgent)) {
            // Set the selected users to be the selected delegates. Plus the active user alias.
            newPurchaseOrderSearch.filter.selectedUsers = [...filterDelegatedBy || [], userProfile!.alias];
        }

        newPurchaseOrderSearch.filter.financeGeographySalesDistrictCodes = filterHierarchySalesDistrictCodes;
        newPurchaseOrderSearch.filter.channelFunctionDetailCodes = filterHierarchyChannelFunctionDetailCodes;
        newPurchaseOrderSearch.filter.executiveFunctionDetailCodes = filterHierarchyExecutiveFunctionDetailCodes;

        return newPurchaseOrderSearch;
    }, [filterCompanyCode, filterDateCreatedFrom, filterDateCreatedTo, filterDelegatedBy, filterDeliveryDateFrom, filterDeliveryDateTo, filterHierarchyChannelFunctionDetailCodes, filterHierarchyExecutiveFunctionDetailCodes, filterHierarchySalesDistrictCodes, filterInvoiceApprover, filterPoApprover, filterPoOwner, filterSupplierNumber, setStandardSearchFilterOptions, userProfile]);

    /**
     * Open button clicked event handler.
     */
    const openButtonClicked = useCallback(() => {
        if (tileHomeOpen.current) {
            telemetryService.trackEvent({ name: trackedEvent.homeOpenButtonClicked });

            searchType.current = SearchType.Open;
            searchUsingTile(tileHomeOpen.current);
        }
    }, [searchUsingTile, tileHomeOpen]);

    /**
     * Due This Month button clicked event handler.
     */
    const dueThisMonthButtonClicked = useCallback(() => {
        if (tileHomeDueThisMonth.current) {
            telemetryService.trackEvent({ name: trackedEvent.homeDueThisMonthButtonClicked });

            searchType.current = SearchType.DueThisMonth;
            searchUsingTile(tileHomeDueThisMonth.current);
        }
    }, [searchUsingTile, tileHomeDueThisMonth]);

    /**
     * Overdue button clicked event handler.
     */
    const overdueButtonClicked = useCallback(() => {
        if (tileHomeOverDue.current) {
            telemetryService.trackEvent({ name: trackedEvent.homeOverdueButtonClicked });

            searchType.current = SearchType.Overdue;
            searchUsingTile(tileHomeOverDue.current);
        }
    }, [searchUsingTile, tileHomeOverDue]);

    /**
     * Finance Controller open clicked event handler.
     */
    const financeControllerOpenButtonClicked = () => {
        if (tileFinanceControllerOpen.current) {
            telemetryService.trackEvent({ name: trackedEvent.homeFcOpenButtonClicked });

            searchType.current = SearchType.FinanceControllerOpen;
            searchUsingTile(tileFinanceControllerOpen.current);
        }
    };

    /**
     * Finance Controller due this month clicked event handler.
     */
    const financeControllerDueThisMonthButtonClicked = () => {
        if (tileFinanceControllerDueThisMonth.current) {
            telemetryService.trackEvent({ name: trackedEvent.homeFcDueThisMonthButtonClicked });

            searchType.current = SearchType.FinanceControllerDueThisMonth;
            searchUsingTile(tileFinanceControllerDueThisMonth.current);
        }
    };
    
    /**
     * Finance Controller unavailable owner clicked event handler.
     */
    const financeControllerUnavailableOwnerButtonClicked = () => {
        if (tileFinanceControllerUnavailableOwner.current) {
            telemetryService.trackEvent({ name: trackedEvent.homeFcUnavailableOwnerButtonClicked });

            searchType.current = SearchType.FinanceControllerUnavailableOwner;
            searchUsingTile(tileFinanceControllerUnavailableOwner.current);
        }
    };

    /**
     * Finance Controller unavailable approver clicked event handler.
     */
    const financeControllerUnavailableApproverButtonClicked = () => {
        if (tileFinanceControllerUnavailableApprover.current) {
            telemetryService.trackEvent({ name: trackedEvent.homeFcUnavailableApproverButtonClicked });

            searchType.current = SearchType.FinanceControllerUnavailableApprover;
            searchUsingTile(tileFinanceControllerUnavailableApprover.current);
        }
    };

    /**
     * Finance Controller receipted by finance controller clicked event handler.
     */
    const financeControllerReceiptedByFinanceControllerButtonClicked = () => {
        if (tileFinanceControllerReceiptedByFinanceController.current) {
            telemetryService.trackEvent({ name: trackedEvent.homeFcReceiptedByFinanceControllerButtonClicked });

            searchType.current = SearchType.FinanceControllerReceiptedByFinanceController;
            searchUsingTile(tileFinanceControllerReceiptedByFinanceController.current);
        }
    };

    /**
     * Search button clicked event handler.
     */
    const searchButtonClicked = () => {
        telemetryService.trackEvent({ name: trackedEvent.homeSearchButtonClicked });

        searchType.current = SearchType.CustomSearch;

        const newPurchaseOrderSearch: PurchaseOrderSearch = buildPurchaseOrderSearch();
        performSearch(newPurchaseOrderSearch, useFilterPoLineOnly);
    };

    /**
     * Clear filters.
     * @param excludePoLine Exclude PO line. If true, then all fields except the PO line filter are cleared.
     */
    const clearFilters = useCallback((excludePoLine: boolean = false) => {
        if (!excludePoLine) {
            dispatch(inputFilterPoNumber(''));
        }
        dispatch(inputFilterSupplierNumber(''));
        dispatch(inputFilterCompanyCode(''));
        dispatch(inputFilterPoApprover(''));
        dispatch(inputFilterPoOwner(''));
        dispatch(inputFilterInvoiceApprover(''));
        dispatch(inputFilterDateCreatedFrom(undefined));
        dispatch(inputFilterDateCreatedTo(undefined));
        dispatch(inputFilterDeliveryDateFrom(undefined));
        dispatch(inputFilterDeliveryDateTo(undefined));
        dispatch(inputFilterHierarchySalesDistrictCodes([]));
        dispatch(inputFilterHierarchyChannelFunctionDetailCodes([]));
        dispatch(inputFilterHierarchyExecutiveFunctionDetailCodes([]));
        dispatch(inputFilterIncludeReceived(IncludeReceivedOptionKey.Yes));
        dispatch(inputFilterIncludeGoodsServices(IncludeGoodsServicesOptionKey.Both));

        // Incrementing these counters will cause these autocomplete controls for supplier and graph user to clear.
        setClearSupplierInputCounter(x => x + 1);
        setClearPoApproverInputCounter(x => x + 1);
        setClearPoOwnerInputCounter(x => x + 1);
        setClearInvoiceApproverInputCounter(x => x + 1);

        // Clear the validation errors if any had been set.
        setPoNumberValidationError('');
        setSupplierNumberValidationError('');
        setCompanyCodeValidationError('');
        setPoApproverValidationError('');
        setPoOwnerValidationError('');
        setInvoiceApproverValidationError('');
    }, [dispatch]);

    /**
     * Clear button clicked event handler.
     */
    const clearButtonClicked = () => {
        telemetryService.trackEvent({ name: trackedEvent.homeClearButtonClicked });
        clearFilters();
        setUseFilterPoLineOnly(false);
    };

    /**
     * Effect for when apiHomeTileInfo returns data.
     */
    useEffect(() => {
        if (apiHomeTileInfo.callApiState === CallApiState.DataAvailable) {
            loadHomeTiles();

            // If autoSearch is enabled, then simulate a button click for the autoSearch value.
            // This will auto-navigate the user to the search results page.
            // The autoSearch feature is used by external systems to auto-navigate users to the search results page.
            // Such as the GsrPoSummary web component (see repo for /WebComponents/GsrPoSummary). 
            if (autoSearch && !autoSearchInitiated.current) {
                // Prevent multiple auto-searches. Can happen as this effect triggers on both apiHomeTileInfo and autoSearch state changes.
                autoSearchInitiated.current = true;

                switch (autoSearch) {
                    case 'HomeOpen': {
                        openButtonClicked();
                        break;
                    }
                    case 'HomeDueThisMonth': {
                        dueThisMonthButtonClicked();
                        break;
                    }
                    case 'HomeOverDue': {
                        overdueButtonClicked();
                        break;
                    }
                    default:
                }
            }
        }
    }, [apiHomeTileInfo.callApiState, autoSearch, dueThisMonthButtonClicked, loadHomeTiles, openButtonClicked, overdueButtonClicked]);

    /**
     * On change handler for view as role.
     * @param ev Form event.
     * @param option Choice group option.
     */
    const onChangeViewAsRole = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        // Reset the api call state for the home tile info api call so that the existing state will not get
        // reused and a new call will be made. See effect for when current tile set changes.
        dispatch(resetApiCallStateForHomeTileInfo());

        switch (option?.key) {
            case ViewAsRoleOptionKey.FinanceController:
                dispatch(changeTileSet(TileSet.FinanceController, ViewAsRoleOptionKey.FinanceController));
                break;
            default:
            case ViewAsRoleOptionKey.ForYou:
                dispatch(changeTileSet(TileSet.Home, ViewAsRoleOptionKey.ForYou));
                break;
        }
    };

    /**
     * Renders a tile row.
     * @param barColor Color of the bar on the left.
     * @param title Title for tile.
     * @param description Description for tile.
     * @param dashboardTile Dashboard tile object.
     * @param findButtonClickHandler Find button click handler.
     * @param searchTypeForTile Search type enum value.
     * @param findButtonAriaText Find button aria label.
     * @returns JSX for a tile row.
     */
    const renderTileRow = (
        barColor: string,
        title: string,
        description: string,
        dashboardTile: DashboardTile | undefined,
        findButtonClickHandler: () => void,
        searchTypeForTile: SearchType,
        findButtonAriaText: string
    ): JSX.Element => {
        return (
            <tr>
                <td className={pageStyles.poSummaryTileColorBar} style={{ backgroundColor: barColor }} />
                <td className={pageStyles.poSummaryTileContent}>
                    <Text variant='large' className={pageStyles.poSummaryTileCategoryText}>{title}</Text>
                    <Text> - <span className={pageStyles.poSummaryTileNumber}> {numberAsLocaleString(dashboardTile?.purchaseOrderCount || 0, 0)}</span> purchase orders / <span className={pageStyles.poSummaryTileNumber}>{numberAsLocaleString(dashboardTile?.lineItemCount || 0, 0)}</span> line items</Text><br/>
                    <Text>{description}</Text>
                </td>
                <td className={pageStyles.poSummaryTileButton}>
                    <DefaultButton
                        onClick={findButtonClickHandler}
                        disabled={isAnySearchRunning}
                        ariaLabel={findButtonAriaText}
                    >
                        { searchTypeForTile === searchType.current &&
                            apiSearch.callApiState === CallApiState.Running ? (
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerGrid} />
                        ) : (
                            <Text>Find</Text>
                        )}
                    </DefaultButton>
                </td>
            </tr>
        );
    };

    /**
     * Renders blank tile spacer row.
     * @returns JSX for blank tile spacer row.
     */
    const renderTileSpacerRow = (): JSX.Element => {
        return <tr>{/* blank line spacer, see css for even lines */}</tr>
    };

    /**
     * Memoized helper to check if any of the search apis are running.
     * @returns True or false.
     */
    const isAnySearchRunning = useMemo<boolean>(() => {
        if (apiHomeTileInfo.callApiState === CallApiState.Running ||
            apiSearch.callApiState === CallApiState.Running) {
            return true;
        }
        return false;
    }, [apiHomeTileInfo.callApiState, apiSearch.callApiState]);

    /**
     * Memoized field to check if search input is valid.
     * @returns True or false.
     */
    const searchInputValid = useMemo<boolean>(() => {
        // Ensure at least one input was supplied.
        if (
            filterPoNumber.trim().length === 0 &&
            filterSupplierNumber.trim().length === 0 &&
            filterCompanyCode.trim().length === 0 &&
            filterPoApprover.trim().length === 0 &&
            filterPoOwner.trim().length === 0 &&
            filterInvoiceApprover.trim().length === 0 &&
            filterDateCreatedFrom === undefined &&
            filterDateCreatedTo === undefined &&
            filterDeliveryDateFrom === undefined &&
            filterDeliveryDateTo === undefined) {
            return false;
        }

        // Check for validation error messages.
        if (poNumberValidationError.length > 0 ||
            supplierNumberValidationError.length > 0 ||
            companyCodeValidationError.length > 0 ||
            poApproverValidationError.length > 0 ||
            poOwnerValidationError.length > 0 ||
            invoiceApproverValidationError.length > 0) {
            return false;
        }

        return true;
    }, [companyCodeValidationError.length, filterCompanyCode, filterDateCreatedFrom, filterDateCreatedTo, filterDeliveryDateFrom, filterDeliveryDateTo, filterInvoiceApprover, filterPoApprover, filterPoNumber, filterPoOwner, filterSupplierNumber, invoiceApproverValidationError.length, poApproverValidationError.length, poNumberValidationError.length, poOwnerValidationError.length, supplierNumberValidationError.length]);

    /**
     * Date Created From change event handler.
     * @param event The text field input event.
     * @param newValue The new value entered into the text field.
     */
    const onSelectDateCreatedFrom = (date: Date | null | undefined) => {
        dispatch(inputFilterDateCreatedFrom(date || undefined));
    };

    /**
     * Date Created To change event handler.
     * @param event The text field input event.
     * @param newValue The new value entered into the text field.
     */
    const onSelectDateCreatedTo = (date: Date | null | undefined) => {
        dispatch(inputFilterDateCreatedTo(date || undefined));
    };

    /**
     * Delivery Date From change event handler.
     * @param event The text field input event.
     * @param newValue The new value entered into the text field.
     */
    const onSelectDeliveryDateFrom = (date: Date | null | undefined) => {
        dispatch(inputFilterDeliveryDateFrom(date || undefined));
    };

    /**
     * Delivery Date To change event handler.
     * @param event The text field input event.
     * @param newValue The new value entered into the text field.
     */
    const onSelectDeliveryDateTo = (date: Date | null | undefined) => {
        dispatch(inputFilterDeliveryDateTo(date || undefined));
    };

    /**
     * Delegated By change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onChangeDelegatedBy = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option === undefined || index === undefined) {
            return;
        }

        const delegatedBy: string[] = [...filterDelegatedBy || []];
        const optionIndex: number = delegatedBy.indexOf(option.text);
        if (option.selected && optionIndex < 0) {
            // If selected and not in the array yet, add it.
            delegatedBy.push(option.text);
        } else if (!option.selected && optionIndex > -1) {
            // If not selected and in the array, remove it.
            delegatedBy.splice(optionIndex, 1);
        }

        dispatch(inputFilterDelegatedBy(delegatedBy));

        // Update the filter delegated by options with the changed option (now selected or unselected).
        const options: IComboBoxOption[] = [...filterDelegatedByOptions];
        options[index] = option;
        dispatch(inputFilterDelegatedByOptions(options));
    };

    /**
     * Include received change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onIncludeReceived = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        dispatch(inputFilterIncludeReceived(value as IncludeReceivedOptionKey));
    };

    /**
     * Include goods/services change event handler.
     * @param event The combo box change event.
     * @param option Combo box option.
     * @param index Index of selected option.
     * @param value Value of selected option.
     */
    const onIncludeGoodsServices = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        dispatch(inputFilterIncludeGoodsServices(value as IncludeGoodsServicesOptionKey));
    };

    return (
        <>
            <PageWrapper {...props}>
                <ErrorBar errors={errors} onDismiss={(index: number) => {
                    setErrors(clearErrorByIndex(errors, index));
                }} />

                {autoSearch && (
                    <SectionWrapper>
                        <Section>
                            <Stack horizontal tokens={stackTokensNormalGap}>
                                <Stack.Item>
                                    <Text variant='mediumPlus'>Please wait while we get your search results...</Text>
                                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                </Stack.Item>
                            </Stack>
                        </Section>
                    </SectionWrapper>
                )}
                {!autoSearch && (
                    <SectionWrapper>
                        <Section>
                            <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                <Stack.Item>
                                    <Stack tokens={stackTokensNormalGap}>
                                        <Stack.Item>
                                            <Text>
                                                Hello {getUserFirstName()}, welcome to the Goods and Services Receipting (GSR) site. On this site you can:
                                            </Text>
                                            <ul style={{ marginTop: '4px' }}>
                                                <li>
                                                    <Text>
                                                        Indicate you have received goods or services for PO line items.
                                                    </Text>
                                                    <ul>
                                                        <li>
                                                            Marking good items as received is required before approving an invoice on MS Invoice.
                                                        </li>
                                                        <li>
                                                            Updating service items is an important step to ensure accurate monthly accruals.
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Text>
                                                        View and edit any PO for which you are an owner, invoice approver, or delegate.
                                                    </Text>
                                                </li>
                                            </ul>
                                            <Text>
                                                Training resources:
                                            </Text>
                                            <Stack horizontal wrap tokens={stackTokensLargeGap} style={{ marginTop: '4px', marginLeft: '22px' }}>
                                                <Stack.Item>
                                                    <Text>
                                                        <FontIcon
                                                            iconName='Video'
                                                            className={pageStyles.icon}
                                                        />&nbsp;
                                                        <Link
                                                            onClick={() => {
                                                                dispatch(showVideoGuide(InitialVideo.Intro));
                                                            }}>
                                                            GSR introduction video
                                                        </Link>
                                                    </Text>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Text>
                                                        <FontIcon
                                                            iconName='Video'
                                                            className={pageStyles.icon}
                                                        />&nbsp;
                                                        <Link
                                                            onClick={() => {
                                                                dispatch(showVideoGuide(InitialVideo.QuarterlyTraining));
                                                            }}>
                                                            Quarterly training video
                                                        </Link>
                                                    </Text>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Text>
                                                        <FontIcon
                                                            iconName='PowerPointDocument'
                                                            className={pageStyles.icon}
                                                        />&nbsp;
                                                        {/*
                                                            The Quarterly Training.pptx is in blob storage along with the Quarterly Training video.
                                                            This file is obviously not a video, but storing it there out of convenience so we can
                                                            change both at the same time each quarter in that same video guides blob container.
                                                        */}
                                                        <Link
                                                            href={`${appConfig.current.settings.videoGuideRootUrl}Quarterly training.pptx`}>
                                                            Quarterly training PowerPoint
                                                        </Link>
                                                    </Text>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <Image height={140} src={`${appConstants.publicUrl}/images/home/${randomImageNum.current}.jpg`} alt="Person receiving package"></Image>
                                </Stack.Item>
                            </Stack>

                            <Separator />

                            <Stack tokens={stackTokensNormalGap}>
                                <Stack.Item>
                                    <Text variant='mediumPlus' className={commonStyles.sectionHeading} role="heading" aria-level={1}>{pageStrings.yourPersonalizedSummary}</Text>
                                </Stack.Item>
                                {
                                    // If user has FC role, then display toggle switch to show normal or FC tile sets.
                                    userProfile?.isFinanceController && (
                                        <Stack.Item>
                                            <ChoiceGroup
                                                componentRef={(ref) => viewAsRoleChoiceGroupRef.current = ref}
                                                styles={horizontalChoiceGroupStyle}
                                                selectedKey={selectedViewAsRoleOptionKey}
                                                options={viewAsRoleOptions}
                                                onChange={onChangeViewAsRole}
                                                onFocus={() => {
                                                    viewAsRoleChoiceGroupFocused.current = true;
                                                }}
                                                onBlur={() => {
                                                    viewAsRoleChoiceGroupFocused.current = false;
                                                }}
                                                disabled={isAnySearchRunning}
                                            />
                                        </Stack.Item>
                                    )
                                }
                                { // Only check the apiHomeTileInfo here for when tiles are loading to display a spinner here.
                                    apiHomeTileInfo.callApiState === CallApiState.Running && (
                                    <Stack.Item>
                                        <Text variant='mediumPlus'>Loading...</Text>
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                                    </Stack.Item>
                                )}
                                {apiHomeTileInfo.callApiState === CallApiState.Failed && (
                                    <Stack.Item>
                                        <LoadFailureDisplay />
                                    </Stack.Item>
                                )}
                                { // Only check the apiHomeTileInfo here for when tiles have loaded.
                                    apiHomeTileInfo.callApiState === CallApiState.Completed && (
                                    <>
                                        <Stack.Item>
                                            <table className={pageStyles.poSummaryTileTable} role="presentation">
                                                { currentTileSet === TileSet.Home && (
                                                    <tbody>
                                                        {renderTileRow(
                                                            'green',
                                                            'Open',
                                                            'Includes all open purchase orders and line items regardless of received status.',
                                                            tileHomeOpen.current,
                                                            openButtonClicked,
                                                            SearchType.Open,
                                                            'Find open purchase orders'
                                                        )}
                                                        {renderTileSpacerRow()}
                                                        {renderTileRow(
                                                            'rgb(0, 120, 212)',
                                                            'Due this month',
                                                            'Includes purchase orders and line items that require receipt where the delivery date is within this month (PST time).',
                                                            tileHomeDueThisMonth.current,
                                                            dueThisMonthButtonClicked,
                                                            SearchType.DueThisMonth,
                                                            'Find due this month purchase orders'
                                                        )}
                                                        {renderTileSpacerRow()}
                                                        {renderTileRow(
                                                            'red',
                                                            'Overdue',
                                                            'Includes purchase orders and line items that require receipt where the delivery date is in the past (PST time).',
                                                            tileHomeOverDue.current,
                                                            overdueButtonClicked,
                                                            SearchType.Overdue,
                                                            'Find overdue purchase orders'
                                                        )}
                                                    </tbody>
                                                )}
                                                { currentTileSet === TileSet.FinanceController && (
                                                    <tbody>
                                                        {renderTileRow(
                                                            'green',
                                                            'Open',
                                                            'Includes all open purchase orders and line items regardless of received status.',
                                                            tileFinanceControllerOpen.current,
                                                            financeControllerOpenButtonClicked,
                                                            SearchType.FinanceControllerOpen,
                                                            'Find open purchase orders'
                                                        )}
                                                        {renderTileSpacerRow()}
                                                        {renderTileRow(
                                                            'rgb(0, 120, 212)',
                                                            'Due this month',
                                                            'Includes purchase orders and line items that require receipt where the delivery date is within this month.',
                                                            tileFinanceControllerDueThisMonth.current,
                                                            financeControllerDueThisMonthButtonClicked,
                                                            SearchType.FinanceControllerDueThisMonth,
                                                            'Find due this month purchase orders'
                                                        )}
                                                        {renderTileSpacerRow()}
                                                        {renderTileRow(
                                                            'red',
                                                            'POs with unavailable owners',
                                                            'Purchase orders for which owners are not available.',
                                                            tileFinanceControllerUnavailableOwner.current,
                                                            financeControllerUnavailableOwnerButtonClicked,
                                                            SearchType.FinanceControllerUnavailableOwner,
                                                            'Find purchase orders with unavailable owners'
                                                        )}
                                                        {renderTileSpacerRow()}
                                                        {renderTileRow(
                                                            'red',
                                                            'POs with unavailable approvers',
                                                            'Purchase orders for which approvers are not available.',
                                                            tileFinanceControllerUnavailableApprover.current,
                                                            financeControllerUnavailableApproverButtonClicked,
                                                            SearchType.FinanceControllerUnavailableApprover,
                                                            'Find purchase orders with unavailable approvers'
                                                        )}
                                                        {renderTileSpacerRow()}
                                                        {renderTileRow(
                                                            'green',
                                                            'POs receipted by finance controller',
                                                            'Purchase orders and line items receipted by finance controller.',
                                                            tileFinanceControllerReceiptedByFinanceController.current,
                                                            financeControllerReceiptedByFinanceControllerButtonClicked,
                                                            SearchType.FinanceControllerReceiptedByFinanceController,
                                                            'Find purchase orders receipted by finance controller'
                                                        )}
                                                    </tbody>
                                                )}
                                            </table>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Text variant="medium"><i>PO and line counts may be delayed up to 24 hours.</i></Text>
                                        </Stack.Item>
                                    </>
                                )}
                            </Stack>

                            <Separator />

                            <Stack tokens={stackTokensNormalGap}>
                                <Stack.Item>
                                    <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                        <Stack.Item>
                                            { /* Using verticalAlign: 'sub' to align text with toggle text. */}
                                            <Text variant='mediumPlus' className={commonStyles.sectionHeading} role="heading" aria-level={2} style={{ verticalAlign: 'sub' }}>{pageStrings.customSearch}</Text>
                                        </Stack.Item>
                                        {userProfile?.isDri && (
                                            // If user is DRI, and on the basic search showing only the PO number input
                                            // then show this toggle to enable DRI search which will disable the applySiteFilters
                                            // option during the search api call. This allows for searching for otherwise blocked
                                            // POs like 8 series (CSCP POs).
                                            <Stack.Item>
                                                <TooltipHost content={tooltips.driSearch} delay={TooltipDelay.long}>
                                                    <Toggle
                                                        className={pageStyles.driSearchToggle}
                                                        label="DRI Search"
                                                        ariaLabel={`${pageStrings.customSearch /* Accessibility: First interactive element to speak heading name. */} DRI search`}
                                                        inlineLabel
                                                        onText="Enabled"
                                                        offText="Disabled"
                                                        defaultChecked={driSearchEnabled}
                                                        onChange={driSearchToggle} />
                                                </TooltipHost>
                                            </Stack.Item>
                                        )}
                                    </Stack>
                                </Stack.Item>

                                <Stack.Item>
                                    <Stack horizontal wrap tokens={stackTokensNormalGap}>
                                        <Stack.Item>
                                            <Label htmlFor={poNumberInputId}>{commonString.poNumber}</Label>
                                            <TooltipHost content={validationConstants.poNumber.tooltip} delay={TooltipDelay.long}>
                                                <TextField
                                                    id={poNumberInputId}
                                                    autoComplete='off'
                                                    ariaLabel={`${pageStrings.customSearch /* Accessibility: First interactive element to speak heading name. */} ${validationConstants.poNumber.tooltip}`}
                                                    className={pageStyles.filterTextField}
                                                    value={filterPoNumber}
                                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                        newValue = newValue || '';
                                                        newValue = newValue.trim();

                                                        if (newValue.length > 0) {
                                                            // Clear all other filters except for this PO filter. All these other filters will also be disabled in the JSX.
                                                            clearFilters(true);
                                                            setUseFilterPoLineOnly(true);
                                                        } else {
                                                            setUseFilterPoLineOnly(false);
                                                        }

                                                        if (newValue.length > 0 && (newValue.length > validationConstants.poNumber.maxLength! ||
                                                            !RegExp(validationConstants.poNumber.pattern!).test(newValue))) {
                                                            setPoNumberValidationError(validationConstants.poNumber.errorMsg!);
                                                        } else {
                                                            setPoNumberValidationError('');
                                                        }
                                                        dispatch(inputFilterPoNumber(newValue));
                                                    }}
                                                    onKeyDown={(key: React.KeyboardEvent<HTMLElement>) => {
                                                        if (key.code === 'Enter') {
                                                            searchButtonClicked();
                                                        }
                                                    }}
                                                    errorMessage={poNumberValidationError}
                                                />
                                            </TooltipHost>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <SupplierInput 
                                                showLabel={true}
                                                label={commonString.supplierNameNumber}
                                                disabled={useFilterPoLineOnly}
                                                tooltip={validationConstants.supplierNameOrNumber.tooltip}
                                                onChange={(supplier: Supplier | undefined) => {
                                                    if (supplier) {
                                                        dispatch(inputFilterSupplierNumber(supplier.supplierNumber));
                                                    } else {
                                                        dispatch(inputFilterSupplierNumber(''));
                                                    }
                                                }}
                                                onInputValidation={(msg: string) => {
                                                    setSupplierNumberValidationError(msg);
                                                }}
                                                initialSelectedSupplier={filterSupplierNumber}
                                                handleError={(error: string) => {
                                                    handleError(error);
                                                }}
                                                allowNotFoundSupplierNumber={true}
                                                clearInputCounter={clearSupplierInputCounter}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Label htmlFor={companyCodeInputId}>{commonString.companyCode}</Label>
                                            <TooltipHost content={validationConstants.companyCode.tooltip} delay={TooltipDelay.long}>
                                                <TextField
                                                    id={companyCodeInputId}
                                                    autoComplete='off'
                                                    ariaLabel={validationConstants.companyCode.tooltip}
                                                    className={pageStyles.filterTextField}
                                                    disabled={useFilterPoLineOnly}
                                                    value={filterCompanyCode}
                                                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                                        newValue = newValue || '';
                                                        newValue = newValue.trim();
                                                        if (newValue.length > 0 && (newValue.length > validationConstants.companyCode.maxLength! ||
                                                            !RegExp(validationConstants.companyCode.pattern!).test(newValue))) {
                                                            setCompanyCodeValidationError(validationConstants.companyCode.errorMsg!);
                                                        } else {
                                                            setCompanyCodeValidationError('');
                                                        }
                                                        dispatch(inputFilterCompanyCode(newValue));
                                                    }}
                                                    errorMessage={companyCodeValidationError}
                                                />
                                            </TooltipHost>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <GraphUserInput
                                                showLabel={true}
                                                label={commonString.poApproverAlias}
                                                disabled={useFilterPoLineOnly}
                                                required={false}
                                                tooltip={tooltips.poApprover}
                                                onChange={(graphUser: GraphUser | undefined) => {
                                                    if (graphUser && graphUser.alias) {
                                                        dispatch(inputFilterPoApprover(graphUser.alias));
                                                    } else {
                                                        dispatch(inputFilterPoApprover(''));
                                                    }
                                                }}
                                                onInputValidation={(msg: string) => {
                                                    setPoApproverValidationError(msg);
                                                }}
                                                disableDoesNotExistError={true}
                                                initialSelectedAlias={filterPoApprover}
                                                clearInputCounter={clearPoApproverInputCounter}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <GraphUserInput
                                                showLabel={true}
                                                label={commonString.poOwnerAlias}
                                                disabled={useFilterPoLineOnly}
                                                required={false}
                                                tooltip={tooltips.poOwner}
                                                onChange={(graphUser: GraphUser | undefined) => {
                                                    if (graphUser && graphUser.alias) {
                                                        dispatch(inputFilterPoOwner(graphUser.alias));
                                                    } else {
                                                        dispatch(inputFilterPoOwner(''));
                                                    }
                                                }}
                                                onInputValidation={(msg: string) => {
                                                    setPoOwnerValidationError(msg);
                                                }}
                                                disableDoesNotExistError={true}
                                                initialSelectedAlias={filterPoOwner}
                                                clearInputCounter={clearPoOwnerInputCounter}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <GraphUserInput
                                                showLabel={true}
                                                label={commonString.invoiceApproverAlias}
                                                disabled={useFilterPoLineOnly}
                                                required={false}
                                                tooltip={tooltips.invoiceApprover}
                                                onChange={(graphUser: GraphUser | undefined) => {
                                                    if (graphUser && graphUser.alias) {
                                                        dispatch(inputFilterInvoiceApprover(graphUser.alias));
                                                    } else {
                                                        dispatch(inputFilterInvoiceApprover(''));
                                                    }
                                                }}
                                                onInputValidation={(msg: string) => {
                                                    setInvoiceApproverValidationError(msg);
                                                }}
                                                disableDoesNotExistError={true}
                                                initialSelectedAlias={filterInvoiceApprover}
                                                clearInputCounter={clearInvoiceApproverInputCounter}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Label htmlFor={dateCreatedFromInputId}>{commonString.dateCreatedFrom}</Label>
                                            <DatePicker
                                                id={dateCreatedFromInputId}
                                                ariaLabel={commonString.dateCreatedFrom}
                                                className={pageStyles.filterDatePicker}
                                                disabled={useFilterPoLineOnly}
                                                onSelectDate={onSelectDateCreatedFrom}
                                                maxDate={filterDateCreatedTo}
                                                value={filterDateCreatedFrom}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Label htmlFor={dateCreatedToInputId}>{commonString.dateCreatedTo}</Label>
                                            <DatePicker
                                                id={dateCreatedToInputId}
                                                ariaLabel={commonString.dateCreatedTo}
                                                className={pageStyles.filterDatePicker}
                                                disabled={useFilterPoLineOnly}
                                                onSelectDate={onSelectDateCreatedTo}
                                                minDate={filterDateCreatedFrom}
                                                value={filterDateCreatedTo}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Label htmlFor={deliveryDateFromInputId}>{commonString.deliveryDateFrom}</Label>
                                            <DatePicker
                                                id={deliveryDateFromInputId}
                                                ariaLabel={commonString.deliveryDateFrom}
                                                className={pageStyles.filterDatePicker}
                                                disabled={useFilterPoLineOnly}
                                                onSelectDate={onSelectDeliveryDateFrom}
                                                maxDate={filterDeliveryDateTo}
                                                value={filterDeliveryDateFrom}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Label htmlFor={deliveryDateToInputId}>{commonString.deliveryDateTo}</Label>
                                            <DatePicker
                                                id={deliveryDateToInputId}
                                                ariaLabel={commonString.deliveryDateTo}
                                                className={pageStyles.filterDatePicker}
                                                disabled={useFilterPoLineOnly}
                                                onSelectDate={onSelectDeliveryDateTo}
                                                minDate={filterDeliveryDateFrom}
                                                value={filterDeliveryDateTo}
                                            />
                                        </Stack.Item>
                                        {
                                            // If the user is not a Finance Controller or Agent (delegation is irrelevant
                                            // and does not work in these cases).
                                            !(userProfile?.isFinanceController || userProfile?.isAgent) &&
                                            // And if the user is a delegate from other users.
                                            filterDelegatedByOptions.length > 0 && (
                                                // The show the delegated by ComboBox.
                                                <Stack.Item>
                                                    <Label htmlFor={delegatedByInputId}>{commonString.delegatedBy}</Label>
                                                    <ComboBox
                                                        id={delegatedByInputId}
                                                        ariaLabel={commonString.delegatedBy}
                                                        className={pageStyles.filterComboBox}
                                                        disabled={useFilterPoLineOnly}
                                                        multiSelect={true}
                                                        autoComplete='off' // Turn off autoComplete when multiSelect is true.
                                                        options={filterDelegatedByOptions}
                                                        selectedKey={filterDelegatedBy}
                                                        onChange={onChangeDelegatedBy}
                                                        useComboBoxAsMenuWidth={true}
                                                    />
                                                </Stack.Item>
                                            )
                                        }
                                        <Stack.Item>
                                            <HierarchySelection
                                                showLabel={true}
                                                readonly={false}
                                                tooltip={tooltips.filterHierarchy}
                                                disabled={isAnySearchRunning || useFilterPoLineOnly}
                                                financeGeographySalesDistrictCodes={filterHierarchySalesDistrictCodes || []}
                                                channelFunctionDetailCodes={filterHierarchyChannelFunctionDetailCodes || []}
                                                executiveFunctionDetailCodes={filterHierarchyExecutiveFunctionDetailCodes || []}
                                                showGeography={true}
                                                showWarningIfBothChannelAndExecSelected={true}
                                                onChanged={(financeGeographySalesDistrictCodes, channelFunctionDetailCodes, executiveFunctionDetailCodes) => {
                                                    dispatch(inputFilterHierarchySalesDistrictCodes(financeGeographySalesDistrictCodes));
                                                    dispatch(inputFilterHierarchyChannelFunctionDetailCodes(channelFunctionDetailCodes));
                                                    dispatch(inputFilterHierarchyExecutiveFunctionDetailCodes(executiveFunctionDetailCodes));
                                                }}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Label htmlFor={includeReceivedInputId}>{commonString.includeReceived}</Label>
                                            <TooltipHost content={tooltips.includeReceived} delay={TooltipDelay.long}>
                                                <ComboBox
                                                    id={includeReceivedInputId}
                                                    ariaLabel={commonString.includeReceived}
                                                    className={pageStyles.filterComboBox}
                                                    disabled={useFilterPoLineOnly}
                                                    options={filterIncludeReceivedOptions}
                                                    selectedKey={filterIncludeReceived}
                                                    onChange={onIncludeReceived}
                                                    useComboBoxAsMenuWidth={true}
                                                />
                                            </TooltipHost>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Label htmlFor={includeGoodsServicesInputId}>{commonString.includeGoodsServices}</Label>
                                            <TooltipHost content={tooltips.includeGoodsServices} delay={TooltipDelay.long}>
                                                <ComboBox
                                                    id={includeGoodsServicesInputId}
                                                    ariaLabel={commonString.includeGoodsServices}
                                                    className={pageStyles.filterComboBox}
                                                    disabled={useFilterPoLineOnly}
                                                    options={filterIncludeGoodsServicesOptions}
                                                    selectedKey={filterIncludeGoodsServices}
                                                    onChange={onIncludeGoodsServices}
                                                    useComboBoxAsMenuWidth={true}
                                                />
                                            </TooltipHost>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>

                                <Stack.Item>
                                    <Stack horizontal wrap tokens={stackTokensNormalGap} className={pageStyles.searchClearButtonHorizontalStack}>
                                        <Stack.Item>
                                            <DefaultButton
                                                onClick={searchButtonClicked}
                                                disabled={isAnySearchRunning || !searchInputValid}
                                            >
                                                {(searchType.current === SearchType.CustomSearch &&
                                                (apiSearch.callApiState === CallApiState.Running)) ? (
                                                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerGrid} />
                                                ) : (
                                                    <Text>Search</Text>
                                                )}
                                            </DefaultButton>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <DefaultButton
                                                text="Clear"
                                                onClick={clearButtonClicked}
                                                disabled={isAnySearchRunning}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>

                                {useFilterPoLineOnly && (
                                    <Stack.Item>
                                        <Text variant="medium">Note: When searching for a specific PO number, some filters will be cleared and disabled.</Text>
                                    </Stack.Item>
                                )}
                            </Stack>

                            <Separator />

                            <ImportExport
                                message="You can export the search results to an Excel file for bulk editing. This uses your search criteria entered above. You can make bulk updates for goods and services line items and import the Excel file."
                                handleError={(error: string) => {
                                    handleError(error);
                                }}
                                purchaseOrderSearch={() => {
                                    return buildPurchaseOrderSearch()
                                }}
                                disabled={isAnySearchRunning}
                                showSearchCriteriaWarning={true}
                            />
                        </Section>
                    </SectionWrapper>
                )}
            </PageWrapper>
        </>
    );
};
