import React from 'react';
import {
    IColumn,
    Text,
    IDetailsColumnProps,
    Stack
} from '@fluentui/react';
import { numberAsLocaleString } from '../../common/common.func.transform';
import { CustomColumnHeader } from '../../components/CustomColumnHeader/CustomColumnHeader';
import { PriorYearAccrualLineItem } from '../../models/priorYearAccrual/priorYearAccrualLineItem';
import { formatDateUsingLocale } from '../../common/common.func.datetime';
import { LineItemSelectionCheckbox } from '../../components/LineItemSelectionCheckbox/LineItemSelectionCheckbox';
import { LineItemStatusIndicator } from '../../components/LineItemStatusIndicator/LineItemStatusIndicator';
import { DetailsActionButton } from '../../components/DetailsActionButton/DetailsActionButton';
import { commonStyles, stackTokensTinyGap } from '../../common/common.styles';
import { ClosedIndicator } from '../../components/ClosedIndicator/ClosedIndicator';
import { ApprovalOptionKey, AvailabilityOptionKey } from './priorYearAccrualPageConstants';
import { PriorYearAccrualStatus } from '../../models/priorYearAccrual/priorYearAccrualStatus';
import { commonString } from '../../common/commonString';
import { tooltips } from '../../common/tooltips';
import { ICustomColumn } from '../../components/CustomDetailsList/ICustomColumn';
import { AuditActionButton } from '../../components/AuditActionButton/AuditActionButton';

export const lineSelectionColumn: ICustomColumn = {
    key: 'lineSelectionColumn',
    // fieldName is not needed as we use custom onRender and Excel export is using server side export.
    name: '',
    isResizable: false,
    minWidth: 42,
    onRender: (item: PriorYearAccrualLineItem) => {
        // Only show the checkbox if the item is not a duplicate. See comments in oriorYearAccrualLineItem.ts.
        if (item.isDuplicate) {
            return (
                <></>
            );
        } else {
            return (
                <div className={commonStyles.statusColumnContainer}>
                    <Stack horizontal tokens={stackTokensTinyGap}>
                        <LineItemSelectionCheckbox usedOnPage='priorYearAccrual' item={item} />
                        <LineItemStatusIndicator usedOnPage='priorYearAccrual' item={item} />
                    </Stack>
                </div>
            );
        }
    }
};

export const purchaseOrderLineNumberColumn: ICustomColumn = {
    key: 'purchaseOrderLineNumberColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='PO'
                headerLine2='Line'
                tooltip={tooltips.poLineNumber}
            />
        );
    },
    isResizable: false,
    minWidth: 130,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <>
                <DetailsActionButton usedOnPage='priorYearAccrual' item={item} />
                <ClosedIndicator item={item} usedOnPage='priorYearAccrual' />
            </>
        );
    },
    isRowHeader: true // Consider this the header row as it has the PO and line number.
};

export const auditColumn: ICustomColumn = {
    key: 'info',
    fieldName: undefined,
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1=''
                headerLine2='Info'
                tooltip={tooltips.auditHistory}
            />
        );
    },
    isResizable: false,
    minWidth: 56,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Stack horizontal wrap={false}>
                <AuditActionButton usedOnPage='priorYearAccrual' item={item} />
            </Stack>
        );
    },
    isRowHeader: true
};

export const companyCodeColumn: ICustomColumn = {
    key: 'companyCodeColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Company'
                headerLine2='Code'
                tooltip={tooltips.companyCode}
            />
        );
    },
    isResizable: true,
    minWidth: 90,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.companyCode}
            </Text>
        );
    }
};

export const systemPostedAccrualAmountLocalColumn: ICustomColumn = {
    key: 'systemPostedAccrualAmountLocalColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='S2S Open Accrual'
                headerLine2='YTD Amount'
                tooltip={tooltips.s2sOpenAccrualYtdAmount}
            />
        );
    },
    isResizable: true,
    minWidth: 126,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.systemPostedAccrualAmount, item.currencyFractionalPart)}
                </Text>
            </div>
        );
    }
};

export const systemPostedAccrualAmountUsdColumn: ICustomColumn = {
    key: 'systemPostedAccrualAmountUsdColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='S2S Open Accrual'
                headerLine2='YTD Amount (USD)'
                tooltip={tooltips.s2sOpenAccrualYtdAmount}
            />
        );
    },
    isResizable: true,
    minWidth: 126,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.systemPostedAccrualAmountUsd, 2)}
                </Text>
            </div>
        );
    }
};

export const openAccrualAmountLocalColumn: ICustomColumn = {
    key: 'openAccrualAmountLocalColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='PYA Open Amount'
                headerLine2='(Static Data-30 Jun)'
                tooltip={tooltips.pyaOpenAmount}
            />
        );
    },
    isResizable: true,
    minWidth: 150,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.openAccrualAmount, item.currencyFractionalPart)}
                </Text>
            </div>
        );
    }
};

export const openAccrualAmountUsdColumn: ICustomColumn = {
    key: 'openAccrualAmountUsdColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='PYA Open Amount'
                headerLine2='(Static Data-30 Jun) (USD)'
                tooltip={tooltips.pyaOpenAmount}
            />
        );
    },
    isResizable: true,
    minWidth: 126,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.openAccrualAmountUsd, 2)}
                </Text>
            </div>
        );
    }
};

export const currencyColumn: ICustomColumn = {
    key: 'currencyColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1=''
                headerLine2='Currency'
                tooltip={tooltips.currencyCode}
            />
        );
    },
    isResizable: true,
    minWidth: 80,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.currency}
            </Text>
        );
    }
};

export const execOrgColumn: ICustomColumn = {
    key: 'execOrgColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Exec'
                headerLine2='Org'
                tooltip={tooltips.execOrg}
            />
        );
    },
    isResizable: true,
    minWidth: 100,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.execOrg}
            </Text>
        );
    }
};

export const orgDetailColumn: ICustomColumn = {
    key: 'orgDetailColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Org'
                headerLine2='Detail'
                tooltip={tooltips.orgDetail}
            />
        );
    },
    isResizable: true,
    minWidth: 100,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.orgDetail}
            </Text>
        );
    }
};

export const execOrgSummaryColumn: ICustomColumn = {
    key: 'execOrgSummaryColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Exec Org'
                headerLine2='Summary'
                tooltip={tooltips.execOrgSummary}
            />
        );
    },
    isResizable: true,
    minWidth: 130,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.execOrgSummary}
            </Text>
        );
    }
};

export const channelOrgSummaryColumn: ICustomColumn = {
    key: 'channelOrgSummaryColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Channel Org'
                headerLine2='Summary'
                tooltip={tooltips.channelOrgSummary}
            />
        );
    },
    isResizable: true,
    minWidth: 130,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.channelOrgSummary}
            </Text>
        );
    }
};

export const channelOrgColumn: ICustomColumn = {
    key: 'channelOrgColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Channel'
                headerLine2='Org'
                tooltip={tooltips.channelOrg}
            />
        );
    },
    isResizable: true,
    minWidth: 130,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.channelOrg}
            </Text>
        );
    }
};

export const staticGLSubclassColumn: ICustomColumn = {
    key: 'staticGLSubclassColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Subclass'
                headerLine2='Static'
                tooltip={tooltips.staticGLSubclass}
            />
        );
    },
    isResizable: true,
    minWidth: 130,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.staticGLSubclassCode} - {item.staticGLSubclassName}
            </Text>
        );
    }
};

export const staticGlAccountColumn: ICustomColumn = {
    key: 'staticGlAccountColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='GL Account'
                headerLine2='Static'
                tooltip={tooltips.staticGlAccount}
            />
        );
    },
    isResizable: true,
    minWidth: 130,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.staticGlAccount}
            </Text>
        );
    }
};

export const staticProfitCenterColumn: ICustomColumn = {
    key: 'staticProfitCenterColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Profit Center'
                headerLine2='Static'
                tooltip={tooltips.staticProfitCenter}
            />
        );
    },
    isResizable: true,
    minWidth: 130,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.staticProfitCenter}
            </Text>
        );
    }
};

export const purchaseOrderOwnerColumn: ICustomColumn = {
    key: 'purchaseOrderOwnerColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='PO'
                headerLine2='Owner'
                tooltip={tooltips.poOwner}
            />
        );
    },
    isResizable: true,
    minWidth: 80,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.purchaseOrderOwner}
            </Text>
        );
    }
};

export const invoiceApproverColumn: ICustomColumn = {
    key: 'invoiceApproverColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Invoice'
                headerLine2='Approver'
                tooltip={tooltips.invoiceApprover}
            />
        );
    },
    isResizable: true,
    minWidth: 80,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.invoiceApprover}
            </Text>
        );
    }
};

export const fiscalYearColumn: ICustomColumn = {
    key: 'fiscalYearColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Fiscal'
                headerLine2='Year'
                tooltip={tooltips.fiscalYear}
            />
        );
    },
    isResizable: true,
    minWidth: 70,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.fiscalYear}
            </Text>
        );
    }
};

export const invoicedAmountLocalColumn: ICustomColumn = {
    key: 'invoicedAmountLocalColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Invoiced'
                headerLine2='Amount'
                tooltip={tooltips.invoicedAmount}
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.invoicedAmount, item.currencyFractionalPart)}
                </Text>
            </div>
        );
    }
};

export const invoicedAmountUsdColumn: ICustomColumn = {
    key: 'invoicedAmountUsdColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Invoiced'
                headerLine2='Amount (USD)'
                tooltip={tooltips.invoicedAmount}
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.invoicedAmountUsd, 2)}
                </Text>
            </div>
        );
    }
};

export const invoicedAmountForCurrentFyLocalColumn: ICustomColumn = {
    key: 'invoicedAmountForCurrentFyLocalColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Total Invoiced'
                headerLine2='Current FY'
                tooltip={tooltips.invoicedAmountForCurrentFy}
            />
        );
    },
    isResizable: true,
    minWidth: 120,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.invoicedAmountForCurrentFy, item.currencyFractionalPart)}
                </Text>
            </div>
        );
    }
};

export const invoicedAmountForCurrentFyUsdColumn: ICustomColumn = {
    key: 'invoicedAmountForCurrentFyUsdColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Total Invoiced'
                headerLine2='Current FY (USD)'
                tooltip={tooltips.invoicedAmountForCurrentFy}
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.invoicedAmountForCurrentFyUsd, 2)}
                </Text>
            </div>
        );
    }
};

export const accrualReversalForCurrentFyLocalColumn: ICustomColumn = {
    key: 'accrualReversalForCurrentFyLocalColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='S2S Accrual Reversal Or New'
                headerLine2='Accrual In Current FY'
                tooltip={tooltips.accrualReversalForCurrentFy}
            />
        );
    },
    isResizable: true,
    minWidth: 210,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.accrualReversalForCurrentFy, item.currencyFractionalPart)}
                </Text>
            </div>
        );
    }
};

export const accrualReversalForCurrentFyUsdColumn: ICustomColumn = {
    key: 'accrualReversalForCurrentFyUsdColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='S2S Accrual Reversal Or New'
                headerLine2='Accrual In Current FY (USD)'
                tooltip={tooltips.accrualReversalForCurrentFy}
            />
        );
    },
    isResizable: true,
    minWidth: 185,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.accrualReversalForCurrentFyUsd, 2)}
                </Text>
            </div>
        );
    }
};

export const lineTotalLocalColumn: ICustomColumn = {
    key: 'lineTotalLocalColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Line'
                headerLine2='Total'
                tooltip={tooltips.lineTotal}
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.lineTotal, item.currencyFractionalPart)}
                </Text>
            </div>
        );
    }
};

export const lineTotalUsdColumn: ICustomColumn = {
    key: 'lineTotalUsdColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Line'
                headerLine2='Total (USD)'
                tooltip={tooltips.lineTotal}
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.lineTotalUsd, 2)}
                </Text>
            </div>
        );
    }
};

export const openAmountLocalColumn: ICustomColumn = {
    key: 'openAmountLocalColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Open'
                headerLine2='Amount'
                tooltip={tooltips.openAmount}
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.openAmount, item.currencyFractionalPart)}
                </Text>
            </div>
        );
    }
};

export const openAmountUsdColumn: ICustomColumn = {
    key: 'openAmountUsdColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Open'
                headerLine2='Amount (USD)'
                tooltip={tooltips.openAmount}
            />
        );
    },
    isResizable: true,
    minWidth: 110,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.openAmountUsd, 2)}
                </Text>
            </div>
        );
    }
};

export const originalPlImpactColumn: ICustomColumn = {
    key: 'originalPlImpactColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='(-) Over / (+) Under'
                headerLine2='Original P&L Impact'
            />
        );
    },
    isResizable: true,
    minWidth: 160,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.originalPlImpact, item.currencyFractionalPart)}
                </Text>  
            </div>
        );
    }
};

export const dedicatedPlColumn: ICustomColumn = {
    key: 'dedicatedPlColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='(-) Over / (+) Under MCAPS/MCB'
                headerLine2='Dedicated P&L Impact'
            />
        );
    },
    isResizable: true,
    minWidth: 250,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <div className={commonStyles.textCellRightAlign}>
                <Text>
                    { numberAsLocaleString(item.dedicatedPl, item.currencyFractionalPart)}
                </Text>  
            </div>
        );
    }
};

export const supplierNameNumberColumn: ICustomColumn = {
    key: 'supplierNameNumberColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Supplier'
                headerLine2='Name / Number'
                tooltip={tooltips.supplierNameNumber}
            />
        );
    },
    isResizable: true,
    minWidth: 160,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.supplierNameNumberCombo}
            </Text>
        );
    }
};

export const lineDescriptionColumn: ICustomColumn = {
    key: 'lineDescriptionColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Line'
                headerLine2='Description'
                tooltip={tooltips.lineDescription}
            />
        );
    },
    isResizable: true,
    minWidth: 200,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.lineDescription}
            </Text>
        );
    }
};

export const receiptDateColumn: ICustomColumn = {
    key: 'receiptDateColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Receipt'
                headerLine2='Date'
                tooltip={tooltips.receiptDatePstPya}
            />
        );
    },
    isResizable: true,
    minWidth: 100,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {formatDateUsingLocale(item.receiptDate)}
            </Text>
        );
    }
};

export const deliveryDateColumn: ICustomColumn = {
    key: 'deliveryDateColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Delivery'
                headerLine2='Date'
                tooltip={tooltips.deliveryDate}
            />
        );
    },
    isResizable: true,
    minWidth: 100,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {formatDateUsingLocale(item.deliveryDate)}
            </Text>
        );
    }
};

export const statusColumn: ICustomColumn = {
    key: 'statusColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1=''
                headerLine2='Status'
                tooltip={tooltips.pyaStatus}
            />
        );
    },
    isResizable: true,
    minWidth: 70,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {
                    item.status === PriorYearAccrualStatus.Open ? commonString.open :
                    item.status === PriorYearAccrualStatus.Closed ? commonString.closed :
                    item.status === PriorYearAccrualStatus.Retained ? commonString.retaied : ''
                }
            </Text>
        );
    }
};

export const retentionExpirationDateColumn: ICustomColumn = {
    key: 'retentionExpirationDateColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Retention'
                headerLine2='Expiration Date'
                tooltip={tooltips.pyaRetentionExpirationDate}
            />
        );
    },
    isResizable: true,
    minWidth: 120,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {formatDateUsingLocale(item.retentionExpirationDate)}
            </Text>
        );
    }
};

// Following are "Corporate Accounting" (ca) columns shown only on the Finance Controller.

export const caCorpApprovalColumn: ICustomColumn = {
    key: 'caCorpApprovalColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Corp'
                headerLine2='Approval'
                tooltip={tooltips.corpApproval}
            />
        );
    },
    isResizable: true,
    minWidth: 100,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                { (item.corpDetails?.corpApproval?.approved !== undefined && item.corpDetails?.corpApproval?.approved !== null) && (
                    item.corpDetails.corpApproval.approved ? ApprovalOptionKey.Approved : ApprovalOptionKey.NotApproved
                )}
            </Text>
        );
    }
};

export const caStcApprovalColumn: ICustomColumn = {
    key: 'caStcApprovalColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='STC'
                headerLine2='Approval'
                tooltip={tooltips.stcApproval}
            />
        );
    },
    isResizable: true,
    minWidth: 100,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                { (item.corpDetails?.stcApproval?.approved !== undefined && item.corpDetails?.stcApproval?.approved !== null) && (
                    item.corpDetails.stcApproval.approved ? ApprovalOptionKey.Approved : ApprovalOptionKey.NotApproved
                )}
            </Text>
        );
    }
};

export const caPoePodAvailabilityColumn: ICustomColumn = {
    key: 'caPoePodAvailabilityColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='POE/POD'
                headerLine2='Availability'
                tooltip={tooltips.poePodAvailability}
            />
        );
    },
    isResizable: true,
    minWidth: 100,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                { (item.corpDetails?.poePodAvailability?.available !== undefined && item.corpDetails?.poePodAvailability?.available !== null) && (
                    item.corpDetails.poePodAvailability.available ? AvailabilityOptionKey.Yes : AvailabilityOptionKey.No
                )}
            </Text>
        );
    }
};

export const caInvoiceExpectedDateColumn: ICustomColumn = {
    key: 'caInvoiceExpectedDateColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Invoice'
                headerLine2='Expected Date'
                tooltip={tooltips.invoiceExpectedDate}
            />
        );
    },
    isResizable: true,
    minWidth: 120,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                <Text className={commonStyles.textCell}>
                    {formatDateUsingLocale(item.corpDetails?.invoiceAvailability?.expectedDate)}
                </Text>
            </Text>
        );
    }
};

export const caCorpAcctCommentsColumn: ICustomColumn = {
    key: 'caCorpAcctCommentsColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='Corp Accounting'
                headerLine2='Comments'
                tooltip={tooltips.corpComments}
            />
        );
    },
    isResizable: true,
    minWidth: 200,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.corpDetails?.corpApproval?.comment}
            </Text>
        );
    }
};

export const caStcCommentsColumn: ICustomColumn = {
    key: 'caStcCommentsColumn',
    name: '', // Specified in onRenderHeader below.
    onRenderHeader: (detailsColumnProps: IDetailsColumnProps | undefined) => {
        return (
            <CustomColumnHeader
                headerLine1='STC'
                headerLine2='Comments'
                tooltip={tooltips.stcComments}
            />
        );
    },
    isResizable: true,
    minWidth: 200,
    onRender: (item: PriorYearAccrualLineItem) => {
        return (
            <Text className={commonStyles.textCell}>
                {item.corpDetails?.stcApproval?.comment}
            </Text>
        );
    }
};

// The "For You" view displays these columns in this order.
// Note that the amount of columns and the order differs from the Finance Controller view.
// There are different sets for local or USD currency.
export const forYouPriorYearLocalCurrencyAccrualColumns: IColumn[] = [
    lineSelectionColumn,
    purchaseOrderLineNumberColumn,
    auditColumn,
    fiscalYearColumn,
    companyCodeColumn,
    lineDescriptionColumn,
    receiptDateColumn,
    supplierNameNumberColumn,
    currencyColumn,
    lineTotalLocalColumn,
    invoicedAmountLocalColumn,
    openAmountLocalColumn,
    openAccrualAmountLocalColumn,
    systemPostedAccrualAmountLocalColumn,
    purchaseOrderOwnerColumn,
    statusColumn,
    retentionExpirationDateColumn
];

export const forYouPriorYearUsdCurrencyAccrualColumns: IColumn[] = [
    lineSelectionColumn,
    purchaseOrderLineNumberColumn,
    auditColumn,
    fiscalYearColumn,
    companyCodeColumn,
    lineDescriptionColumn,
    receiptDateColumn,
    supplierNameNumberColumn,
    // Do not show a currency column.
    lineTotalUsdColumn,
    invoicedAmountUsdColumn,
    openAmountUsdColumn,
    openAccrualAmountUsdColumn,
    systemPostedAccrualAmountUsdColumn,
    purchaseOrderOwnerColumn,
    statusColumn,
    retentionExpirationDateColumn
];

// The Finance Controller view displays these columns in this order.
// Note that the amount of columns and the order differs from the For You view.
export const getElevatedPriorYearLocalCurrencyAccrualColumns = (): IColumn[] => {
    const columns: IColumn[] = [];
    columns.push(
        lineSelectionColumn,
        purchaseOrderLineNumberColumn,
        auditColumn,
        fiscalYearColumn,
        companyCodeColumn,
        lineDescriptionColumn,
        receiptDateColumn,
        deliveryDateColumn,
        execOrgSummaryColumn,
        execOrgColumn,
        channelOrgSummaryColumn,
        channelOrgColumn,
        orgDetailColumn,
        staticProfitCenterColumn,
        staticGlAccountColumn,
        staticGLSubclassColumn,
        currencyColumn,
        lineTotalLocalColumn,
        invoicedAmountLocalColumn,
        openAmountLocalColumn,
        openAccrualAmountLocalColumn,
        invoicedAmountForCurrentFyLocalColumn,
        accrualReversalForCurrentFyLocalColumn,
        systemPostedAccrualAmountLocalColumn,
        originalPlImpactColumn,
        dedicatedPlColumn,
        statusColumn,
        retentionExpirationDateColumn,
        purchaseOrderOwnerColumn,
        invoiceApproverColumn,
        supplierNameNumberColumn,
        caCorpApprovalColumn,
        caCorpAcctCommentsColumn,
        caStcApprovalColumn,
        caStcCommentsColumn,
        caPoePodAvailabilityColumn,
        caInvoiceExpectedDateColumn
    );
    return columns;
};

export const getElevatedPriorYearUsdCurrencyAccrualColumns = (): IColumn[] => {
    const columns: IColumn[] = [];
    columns.push(
        lineSelectionColumn,
        purchaseOrderLineNumberColumn,
        auditColumn,
        fiscalYearColumn,
        companyCodeColumn,
        lineDescriptionColumn,
        receiptDateColumn,
        deliveryDateColumn,
        execOrgSummaryColumn,
        execOrgColumn,
        channelOrgSummaryColumn,
        channelOrgColumn,
        orgDetailColumn,
        staticProfitCenterColumn,
        staticGlAccountColumn,
        staticGLSubclassColumn,
        // Do not show a currency column.
        lineTotalUsdColumn,
        invoicedAmountUsdColumn,
        openAmountUsdColumn,
        openAccrualAmountUsdColumn,
        invoicedAmountForCurrentFyUsdColumn,
        accrualReversalForCurrentFyUsdColumn,
        systemPostedAccrualAmountUsdColumn,
        originalPlImpactColumn,
        dedicatedPlColumn,
        statusColumn,
        retentionExpirationDateColumn,
        purchaseOrderOwnerColumn,
        invoiceApproverColumn,
        supplierNameNumberColumn,
        caCorpApprovalColumn,
        caCorpAcctCommentsColumn,
        caStcApprovalColumn,
        caStcCommentsColumn,
        caPoePodAvailabilityColumn,
        caInvoiceExpectedDateColumn
    );
    return columns;
};
