import { AnyAction } from 'redux';
import { BulkUpdateResult } from '../../../models/purchaseOrder/bulkUpdateResult';
import { PurchaseOrderHeader } from '../../../models/purchaseOrder/purchaseOrderHeader';
import { PurchaseOrderLineClose } from '../../../models/purchaseOrder/purchaseOrderLineClose';
import { PurchaseOrderLineItem } from '../../../models/purchaseOrder/purchaseOrderLineItem';
import { receiptingApiClient } from '../../../services/api/receiptingApiClient';
import { AppDispatch } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, ICallApiBase } from '../generic.action';

// The edit page also makes use of search.action.tsx and search.reducer.tsx for common search
// functionality used across multiple pages.

export interface IEditPageData {
    purchaseOrderHeader?: PurchaseOrderHeader;
    goodsPurchaseOrderLineItems?: PurchaseOrderLineItem[];
    servicesPurchaseOrderLineItems?: PurchaseOrderLineItem[];
    lineItemsWithError?: string[];
}

/**
 * Action creator: Store edit page date.
 * @param purchaseOrderHeader Purchase order header.
 * @param goodsPurchaseOrderLineItems Goods purchase order line items.
 * @param servicesPurchaseOrderLineItems Services purchase order line items.
 * @returns Redux action.
 */
export const storeEditPageData = (purchaseOrderHeader: PurchaseOrderHeader, goodsPurchaseOrderLineItems: PurchaseOrderLineItem[], servicesPurchaseOrderLineItems: PurchaseOrderLineItem[]): AnyAction => {
    return {
        type: actionTypes.EDIT_STORE_PAGEDATA,
        editPageData: {
            purchaseOrderHeader: purchaseOrderHeader,
            goodsPurchaseOrderLineItems: goodsPurchaseOrderLineItems,
            servicesPurchaseOrderLineItems: servicesPurchaseOrderLineItems
        } as IEditPageData
    } as AnyAction;
};

/**
 * Action creator: Clear edit page data.
 * @returns Redux action.
 */
export const clearEditPageData = (): AnyAction => {
    return {
        type: actionTypes.EDIT_STORE_PAGEDATA_CLEAR
    } as AnyAction;
};

export interface IEditLineItem {
    lineNumber?: string;
    assetTag?: string;
    serialNumber?: string;
    recipientName?: string;
    isReceived?: boolean;
    partialAmount?: number;
    receivedAmount?: number | string;
    percentageReceived?: number;
    receiptDate?: Date;
    isClosed?: boolean;
    closedComments?: string;
    hasError?: boolean;
}

/**
 * Action creator: Edit line item asset tag.
 * @param lineNumber Line number.
 * @param assetTag Asset tag.
 * @returns Redux action.
 */
export const editLineItemAssetTag = (lineNumber: string, assetTag: string): AnyAction => {
    return {
        type: actionTypes.EDIT_LINEITEM_ASSETTAG,
        editLineItem: {
            lineNumber: lineNumber,
            assetTag: assetTag
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: Edit line item asset tag has error.
 * @param lineNumber Line number.
 * @param hasError Has error or not.
 * @returns Redux action.
 */
export const editLineItemAssetTagHasError = (lineNumber: string, hasError: boolean) => {
    return {
        type: actionTypes.EDIT_LINEITEM_ASSETTAG_HASERROR,
        editLineItem: {
            lineNumber: lineNumber,
            hasError: hasError
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: Edit line item serial number.
 * @param lineNumber Line number.
 * @param serialNumber Serial number.
 * @returns Redux action.
 */
export const editLineItemSerialNumber = (lineNumber: string, serialNumber: string): AnyAction => {
    return {
        type: actionTypes.EDIT_LINEITEM_SERIALNUMBER,
        editLineItem: {
            lineNumber: lineNumber,
            serialNumber: serialNumber
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: Edit line item serial number has error.
 * @param lineNumber Line number.
 * @param hasError Has error or not.
 * @returns Redux action.
 */
export const editLineItemSerialNumberHasError = (lineNumber: string, hasError: boolean) => {
    return {
        type: actionTypes.EDIT_LINEITEM_SERIALNUMBER_HASERROR,
        editLineItem: {
            lineNumber: lineNumber,
            hasError: hasError
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: Edit line item recipient name.
 * @param lineNumber Line number.
 * @param recipientName Recipient name.
 * @returns Redux action.
 */
export const editLineItemRecipientName = (lineNumber: string, recipientName: string): AnyAction => {
    return {
        type: actionTypes.EDIT_LINEITEM_RECIPIENTNAME,
        editLineItem: {
            lineNumber: lineNumber,
            recipientName: recipientName
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: Edit line item serial number has error.
 * @param lineNumber Line number.
 * @param hasError Has error or not.
 * @returns Redux action.
 */
export const editLineItemRecipientNameHasError = (lineNumber: string, hasError: boolean) => {
    return {
        type: actionTypes.EDIT_LINEITEM_RECIPIENTNAME_HASERROR,
        editLineItem: {
            lineNumber: lineNumber,
            hasError: hasError
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: Edit line item received.
 * @param lineNumber Line number.
 * @param isReceived Is received flag.
 * @returns Redux action.
 */
export const editLineItemReceived = (lineNumber: string, isReceived: boolean): AnyAction => {
    return {
        type: actionTypes.EDIT_LINEITEM_RECEIVED,
        editLineItem: {
            lineNumber: lineNumber,
            isReceived: isReceived
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: edit line item received partial.
 * @param lineNumber Line number.
 * @param partialAmount Partial amount.
 * @returns Redux action.
 */
export const editLineItemReceivedPartial = (lineNumber: string, partialAmount: number): AnyAction => {
    return {
        type: actionTypes.EDIT_LINEITEM_RECEIVED_PARTIAL,
        editLineItem: {
            lineNumber: lineNumber,
            partialAmount: partialAmount
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: Edit line item received amount.
 * @param lineNumber Line number.
 * @param receivedAmount Received amount.
 * @returns Redux action.
 */
export const editLineItemReceivedAmount = (lineNumber: string, receivedAmount: number | string): AnyAction => {
    return {
        type: actionTypes.EDIT_LINEITEM_RECEIVED_AMOUNT,
        editLineItem: {
            lineNumber: lineNumber,
            receivedAmount: receivedAmount
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: Edit line item closed comments has error.
 * @param lineNumber Line number.
 * @param hasError Has error or not.
 * @returns Redux action.
 */
export const editLineItemReceivedAmountHasError = (lineNumber: string, hasError: boolean) => {
    return {
        type: actionTypes.EDIT_LINEITEM_RECEIVED_AMOUNT_HASERROR,
        editLineItem: {
            lineNumber: lineNumber,
            hasError: hasError
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: Edit line item received amount.
 * @param lineNumber Line number.
 * @param receivedAmount Received amount.
 * @returns Redux action.
 */
 export const editLineItemPercentageReceived = (lineNumber: string, percentageReceived: number): AnyAction => {
    return {
        type: actionTypes.EDIT_LINEITEM_PERCENTAGE_RECEIVED,
        editLineItem: {
            lineNumber: lineNumber,
            percentageReceived: percentageReceived
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: Edit line item receipt date.
 * @param lineNumber Line number.
 * @param receiptDate Received date.
 * @returns Redux action.
 */
 export const editLineItemReceiptDate = (lineNumber: string, receiptDate: Date | null | undefined): AnyAction => {
    return {
        type: actionTypes.EDIT_LINEITEM_RECEIPT_DATE,
        editLineItem: {
            lineNumber: lineNumber,
            receiptDate: receiptDate
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: Show details for line item.
 * @param purchaseOrderLineItem Purchase order line item.
 * @returns Redux action.
 */
export const showDetailsForLineItem = (purchaseOrderLineItem: PurchaseOrderLineItem): AnyAction => {
    return {
        type: actionTypes.SHOW_DETAILS_FOR_LINEITEM,
        showDetailsForLineItem: purchaseOrderLineItem
    } as AnyAction;
};

/**
 * Action creator: Hide details for line item.
 * @returns Redux action.
 */
export const hideDetailsForLineItem = (): AnyAction => {
    return {
        type: actionTypes.SHOW_DETAILS_FOR_LINEITEM,
        showDetailsForLineItem: undefined
    } as AnyAction;
};

/**
 * Action creator: Show audit history for line item.
 * @param purchaseOrderLineItem Purchase order line item.
 * @returns Redux action.
 */
export const showAuditForLineItem = (purchaseOrderLineItem: PurchaseOrderLineItem): AnyAction => {
    return {
        type: actionTypes.SHOW_AUDIT_FOR_LINEITEM,
        showAuditForLineItem: purchaseOrderLineItem
    } as AnyAction;
};

/**
 * Action creator: Hide audit history for line item.
 * @returns Redux action.
 */
export const hideAuditForLineItem = (): AnyAction => {
    return {
        type: actionTypes.SHOW_AUDIT_FOR_LINEITEM,
        showAuditForLineItem: undefined
    } as AnyAction;
};

/**
 * Action creator: Show shipment information for line item.
 * @param purchaseOrderLineItem Purchase order line item.
 * @returns Redux action.
 */
export const showShipmentInfoForLineItem = (purchaseOrderLineItem: PurchaseOrderLineItem): AnyAction => {
    return {
        type: actionTypes.SHOW_SHIPMENT_INFO_FOR_LINEITEM,
        showShipmentInfoForLineItem: purchaseOrderLineItem
    } as AnyAction;
};

/**
 * Action creator: Hide shipment information for line item.
 * @returns Redux action.
 */
export const hideShipmentInfoForLineItem = (): AnyAction => {
    return {
        type: actionTypes.SHOW_SHIPMENT_INFO_FOR_LINEITEM,
        showShipmentInfoForLineItem: undefined
    } as AnyAction;
};

/**
 * Action creator: Close line item selection.
 * @param lineNumber Line number.
 * @param isClosed Mark as closed.
 * @returns Redux action.
 */
export const closeLineItemSelection = (lineNumber: string, isClosed: boolean): AnyAction => {
    return {
        type: actionTypes.CLOSE_LINE_ITEM_SELECTION,
        editLineItem: {
            lineNumber: lineNumber,
            isClosed: isClosed
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: Close line item comments change.
 * @param lineNumber Line number.
 * @param closeComments Close line comments.
 * @returns Redux action.
 */
export const closeLineItemCommentsChange = (lineNumber: string, closedComments: string): AnyAction => {
    return {
        type: actionTypes.CLOSE_LINE_ITEM_COMMENTS,
        editLineItem: {
            lineNumber: lineNumber,
            closedComments: closedComments
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Action creator: Edit line item closed comments has error.
 * @param lineNumber Line number.
 * @param hasError Has error or not.
 * @returns Redux action.
 */
export const editLineItemClosedCommentsHasError = (lineNumber: string, hasError: boolean) => {
    return {
        type: actionTypes.CLOSE_LINE_ITEM_COMMENTS_HASERROR,
        editLineItem: {
            lineNumber: lineNumber,
            hasError: hasError
        } as IEditLineItem
    } as AnyAction;
};

/**
 * Payload used with callApiClosePoLines action.
 */
export interface IApiClosePoLines extends ICallApiBase {
    // No data returned.
}

/**
 * Call API to close PO lines.
 * @param purchaseOrderLineCloseArray Purchase order lines to close.
 * @returns Redux dispatch function.
 */
export const callApiClosePoLines = (purchaseOrderLineCloseArray: PurchaseOrderLineClose[]): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_CLOSE_LINE,
        async () => {
            return await receiptingApiClient.closePoLines(purchaseOrderLineCloseArray);
        }
    );
};

/**
 * Payload used with callApiBulkSavePurchaseOrders action.
 */
export interface IApiBulkSavePurchaseOrders extends ICallApiBase {
    bulkUpdateResult?: BulkUpdateResult | null;
}

/**
 * Saves PO data in bulk.
 * @param lineItems Line items to save.
 * @returns Redux dispatch function.
 */
export const callApiBulkSavePurchaseOrders = (lineItems: PurchaseOrderLineItem[]): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<BulkUpdateResult | null>(
        actionTypes.API_BULK_SAVE_PURCHASE_ORDERS,
        async () => {
            return await receiptingApiClient.bulkSavePurchaseOrders(lineItems);
        },
        (payload: IApiBulkSavePurchaseOrders, data: BulkUpdateResult | null, dispatch: AppDispatch) => {
            payload.bulkUpdateResult = data;
        }
    );
};
