import {
    IStyle,
    mergeStyleSets
} from '@fluentui/react';
import { getScrollBarWidth, navCollapsedWidth } from '@coherence-design-system/controls';
import { copilotWidth } from '../../App.styles';

const scrollablePaneStyles = {
    position: 'fixed',
    top: 58,
    bottom: 0,
    right: 0
};

let scrollBarWidth = 0;
let currentZoomLevel = 0;

const calculcateScrollBarWidth = () => {
    [scrollBarWidth, currentZoomLevel] = getScrollBarWidth(scrollBarWidth, currentZoomLevel, window.devicePixelRatio);
    return scrollBarWidth;
}

const navExpandedWidth: number = 280;

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    container: {
        width: '100%'
    } as IStyle,
    footer: {
        margin: '10px 10px 20px 10px',
        // The following properties are to make the footer sticky.
        // The footer will stick to the bottom of the page if the content is shorter than the page.
        // If the content is longer than the page, the footer will stick to the bottom of the content.
        // The containing element must have a height of 100%.
        position: 'sticky',
        top: '100vh'
    } as IStyle,
    msLogo: {
        display: 'inline-block',
        margin: '0 10px 0 10px'
    } as IStyle,
    contentWrapper: {
        maxWidth: `calc(100% - ${calculcateScrollBarWidth()}px)`
    } as IStyle,
    scrollablePaneNavCollapsedCopilotCollapsed: {
        ...scrollablePaneStyles,
        left: navCollapsedWidth + calculcateScrollBarWidth(),
        maxWidth: `calc(100vw - ${navCollapsedWidth + calculcateScrollBarWidth()}px)`
    } as IStyle,
    scrollablePaneNavCollapsedCopilotExpanded: {
        ...scrollablePaneStyles,
        left: navCollapsedWidth + calculcateScrollBarWidth(),
        maxWidth: `calc(100vw - ${navCollapsedWidth + copilotWidth + calculcateScrollBarWidth()}px)`
    } as IStyle,
    scrollablePaneNavExpandedCopilotCollapsed: {
        ...scrollablePaneStyles,
        left: navExpandedWidth + calculcateScrollBarWidth(),
        maxWidth: `calc(100vw - ${navExpandedWidth + calculcateScrollBarWidth()}px)`
    } as IStyle,
    scrollablePaneNavExpandedCopilotExpanded: {
        ...scrollablePaneStyles,
        left: navExpandedWidth + calculcateScrollBarWidth(),
        maxWidth: `calc(100vw - ${navExpandedWidth + copilotWidth + calculcateScrollBarWidth()}px)`
    } as IStyle,
    serviceAlertContainer: {
        background: 'lightgoldenrodyellow',
        margin: '0 0 10px 0',
        padding: '2px 4px 2px 4px',
        width: 'calc(100% - 26px)'
    } as IStyle,
    serviceAlertHeading: {
        color: 'red',
        fontWeight: '500'
    } as IStyle,
    serviceAlertIcon: {
        fontSize: '18px',
        verticalAlign: 'text-bottom',
        margin: '0 4px 0 0'
    } as IStyle,
    serviceAlertText: {
        color: 'black',
        margin: '2px 0 0 0'
    } as IStyle
});
