import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../actions/actionTypes';
import { CallApiState } from '../actions/generic.action';
import {
    IApiDismissAllNotifications,
    IApiDismissNotification,
    IApiExistingNotifications,
    IApiLoadCompanies,
    IApiLoadHierarchy,
    IApiLoadUserProfile
} from '../actions/app.action';
import { TeachingBubbleItem } from '../../components/CustomTeachingBubble/CustomTeachingBubble';
import { ClientNotificationItem } from '../../models/clientNotification/clientNotificationItem';
import { deepCopyObject } from '../../common/common.func.general';
import { IKeyValuePair } from '../../models/utility/keyValuePair';
import { IHierarchyTreeItemState } from '../../components/HierarchySelection/IHierarchyTreeItemState';
import { InitialVideo } from '../../shell/VideoGuide/VideoGuide';

export interface IHierarchyState {
    financeGeographyHierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState>;
    channelFunctionHierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState>;
    executiveFunctionHierarchyTreeItemStates: IKeyValuePair<IHierarchyTreeItemState>;
}

export interface IAppReducerState {
    apiLoadUserProfile: IApiLoadUserProfile;
    apiLoadCompanies: IApiLoadCompanies;
    apiExistingNotifications: IApiExistingNotifications;
    apiDismissNotification: IApiDismissNotification;
    apiDismissAllNotifications: IApiDismissAllNotifications;

    // Separate state for notification items returned from api call to get existing notifications, and also updated
    // by SignalR when notification data gets updated.
    clientNotificationItems: ClientNotificationItem[];

    isNotificationPanelOpen: boolean;

    teachingBubbleItemArray: TeachingBubbleItem[];
    teachingBubbleItemShown: TeachingBubbleItem | undefined;

    apiLoadHierarchy: IApiLoadHierarchy;
    // The hierarchy state can handle multiple instances. The key is the instance id. The value is the set
    // of hierarchy tree item states for each of the 3 hierarchies.
    hierarchyStateInstances: IKeyValuePair<IHierarchyState>;

    copilotPanelIsOpen: boolean;
    navIsOpen: boolean;

    showVideoGuideDialog: boolean;
    initialVideo?: InitialVideo;
}

const initialAppReducerState: IAppReducerState = {
    apiLoadUserProfile: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        userProfile: undefined
    },
    apiLoadCompanies: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        companies: undefined
    },
    apiExistingNotifications: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        clientNotificationData: undefined
    },
    apiDismissNotification: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiDismissAllNotifications: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    clientNotificationItems: [],
    isNotificationPanelOpen: false,
    teachingBubbleItemArray: [],
    teachingBubbleItemShown: undefined,
    apiLoadHierarchy: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        hierarchy: undefined
    },
    hierarchyStateInstances: {},
    copilotPanelIsOpen: false,
    navIsOpen: false,
    showVideoGuideDialog: false,
    initialVideo: undefined
};

export const appReducer: Reducer<IAppReducerState, AnyAction> = (
    state: IAppReducerState = initialAppReducerState, action: AnyAction
): IAppReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_USER_PROFILE: {
            const payload: IApiLoadUserProfile = action.payload as IApiLoadUserProfile;
            return {
                ...state,
                apiLoadUserProfile: { ...payload }
            } as IAppReducerState;
        }
        case actionTypes.API_LOAD_COMPANIES: {
            const payload: IApiLoadCompanies = action.payload as IApiLoadCompanies;
            return {
                ...state,
                apiLoadCompanies: { ...payload }
            } as IAppReducerState;
        }
        case actionTypes.API_EXISTING_NOTIFICATIONS: {
            const payload: IApiExistingNotifications = action.payload as IApiExistingNotifications;

            let clientNotificationItems: ClientNotificationItem[];
            if (payload.callApiState === CallApiState.Completed && payload.clientNotificationData) {
                // First, remember all the pre-existing items in the clientNotificationItems state.
                const preExistingIds: string[] = state.clientNotificationItems.filter(x => x.isPreExisting).map(x => x.id);
                // If the api call succeeded, then replace the items in the clientNotificationItems state.
                // Make a copy of the notification item array.
                clientNotificationItems = deepCopyObject(payload.clientNotificationData.items);
                // For items that were pre-existing, set the isPreExisting flag to true.
                clientNotificationItems.forEach(item => {
                    if (preExistingIds.indexOf(item.id) > -1) {
                        item.isPreExisting = true;
                    }
                });
            } else {
                // If the api call is in progress or failed, then keep the existing items in the clientNotificationItems state.
                clientNotificationItems = state.clientNotificationItems;
            }

            return {
                ...state,
                apiExistingNotifications: { ...payload },
                // The notifications are in the apiExistingNotifications payload, but also store a copy of these items
                // in this separate state which will be updated by other actions like UPDATE_NOTIFICATION_ITEM and others.
                clientNotificationItems: clientNotificationItems
            } as IAppReducerState;
        }
        case actionTypes.API_DISMISS_NOTIFICATION: {
            const payload: IApiDismissNotification = action.payload as IApiDismissNotification;

            // Update the clientNotificationItems state.
            const clientNotificationItems: ClientNotificationItem[] = [...state.clientNotificationItems];
            if (payload.callApiState === CallApiState.Completed) {
                // Find and remove the item from the array.
                const index: number = clientNotificationItems.findIndex(x => x.id === payload.notificationId);
                if (index > -1) {
                    clientNotificationItems.splice(index, 1);
                }
            }

            return {
                ...state,
                apiDismissNotification: { ...payload },
                clientNotificationItems: clientNotificationItems
            } as IAppReducerState;
        }
        case actionTypes.API_DISMISS_ALL_NOTIFICATIONS: {
            const payload: IApiDismissAllNotifications = action.payload as IApiDismissAllNotifications;

            // Update the clientNotificationItems state in memory locally if the API call succeeded.
            let clientNotificationItems: ClientNotificationItem[] = [...state.clientNotificationItems];
            if (payload.callApiState === CallApiState.Completed) {
                if (payload.clientNotificationItemState === undefined) {
                    // Remove all items from the array.
                    clientNotificationItems = [];
                } else {
                    // Filter out any items that have the specified state.
                    clientNotificationItems = clientNotificationItems.filter(x => x.notificationItemState !== payload.clientNotificationItemState);
                }
            }

            return {
                ...state,
                apiDismissAllNotifications: { ...payload },
                clientNotificationItems: clientNotificationItems
            } as IAppReducerState;
        }
        case actionTypes.UPSERT_NOTIFICATION_ITEM: {
            // Called by SignalR - when notification items come in, update existing ones if found, or add if not found.
            const clientNotificationItems: ClientNotificationItem[] = [...state.clientNotificationItems];

            const index: number = state.clientNotificationItems.findIndex(item => item.id === action.clientNotificationItem.id);
            if (index > -1) {
                // Replace the item at index with the one to update.
                clientNotificationItems[index] = action.clientNotificationItem;
            } else {
                clientNotificationItems.push(action.clientNotificationItem);
            }

            return {
                ...state,
                clientNotificationItems: clientNotificationItems
            } as IAppReducerState;
        }
        case actionTypes.SHOW_NOTIFICATION_PANEL: {
            return {
                ...state,
                isNotificationPanelOpen: action.isNotificationPanelOpen
            } as IAppReducerState;
        }
        case actionTypes.TEACHING_BUBBLE_SHOW: {
            const teachingBubbleItemArray: TeachingBubbleItem[] = [...state.teachingBubbleItemArray];
            // Check if the array already has the item. If it does then do nothing. If not yet added, then push it onto the array.
            // This can happen in some cases where a component code may run or rerender multiple times, causing a dispatch to
            // this action multiple times.
            if (state.teachingBubbleItemArray.indexOf(action.teachingBubbleItem) === -1) {
                teachingBubbleItemArray.push(action.teachingBubbleItem);
            }
            return {
                ...state,
                teachingBubbleItemShown: teachingBubbleItemArray[0],
                teachingBubbleItemArray: teachingBubbleItemArray
            } as IAppReducerState;
        }
        case actionTypes.TEACHING_BUBBLE_GOTIT: {
            const teachingBubbleItemArray: TeachingBubbleItem[] = [...state.teachingBubbleItemArray];
            const index: number = teachingBubbleItemArray.findIndex(item => item === action.teachingBubbleItem);
            if (index > -1) {
                teachingBubbleItemArray.splice(index, 1);
            }
            return {
                ...state,
                // Show the next item in the array if there were multiple to be shown.
                teachingBubbleItemShown: teachingBubbleItemArray.length > 0 ? teachingBubbleItemArray[0] : undefined,
                teachingBubbleItemArray: teachingBubbleItemArray
            } as IAppReducerState;
        }
        case actionTypes.TEACHING_BUBBLE_CLEAR_ARRAY: {
            // Hides any shown teaching bubble and clears the array of items queued to be shown. This is good to call
            // when transitioning away from a page and onto a new page. Any shown teaching bubbles that might have been
            // shown but not dismissed on the prior page would be cleared by this. The new page then has a fresh slate.
            return {
                ...state,
                teachingBubbleItemShown: undefined,
                teachingBubbleItemArray: []
            } as IAppReducerState;
        }
        case actionTypes.API_LOAD_HIERARCHY: {
            const payload: IApiLoadHierarchy = action.payload as IApiLoadHierarchy;
            return {
                ...state,
                apiLoadHierarchy: { ...payload }
            } as IAppReducerState;
        }
        case actionTypes.STORE_FINANCE_GEOGRAPHY_HIERARCHY_TREE_ITEM_STATES: {
            return {
                ...state,
                hierarchyStateInstances: {
                    ...state.hierarchyStateInstances,
                    [action.instanceId]: {
                        financeGeographyHierarchyTreeItemStates: { ...action.financeGeographyHierarchyTreeItemStates },
                        channelFunctionHierarchyTreeItemStates: { ...state.hierarchyStateInstances[action.instanceId]?.channelFunctionHierarchyTreeItemStates },
                        executiveFunctionHierarchyTreeItemStates: { ...state.hierarchyStateInstances[action.instanceId]?.executiveFunctionHierarchyTreeItemStates }
                    }
                }
            } as IAppReducerState;
        }
        case actionTypes.STORE_CHANNEL_FUNCTION_HIERARCHY_TREE_ITEM_STATES: {
            return {
                ...state,
                hierarchyStateInstances: {
                    ...state.hierarchyStateInstances,
                    [action.instanceId]: {
                        financeGeographyHierarchyTreeItemStates: { ...state.hierarchyStateInstances[action.instanceId]?.financeGeographyHierarchyTreeItemStates },
                        channelFunctionHierarchyTreeItemStates: { ...action.channelFunctionHierarchyTreeItemStates },
                        executiveFunctionHierarchyTreeItemStates: { ...state.hierarchyStateInstances[action.instanceId]?.executiveFunctionHierarchyTreeItemStates }
                    }
                }
            } as IAppReducerState;
        }
        case actionTypes.STORE_EXECUTIVE_FUNCTION_HIERARCHY_TREE_ITEM_STATES: {
            return {
                ...state,
                hierarchyStateInstances: {
                    ...state.hierarchyStateInstances,
                    [action.instanceId]: {
                        financeGeographyHierarchyTreeItemStates: { ...state.hierarchyStateInstances[action.instanceId]?.financeGeographyHierarchyTreeItemStates },
                        channelFunctionHierarchyTreeItemStates: { ...state.hierarchyStateInstances[action.instanceId]?.channelFunctionHierarchyTreeItemStates },
                        executiveFunctionHierarchyTreeItemStates: { ...action.executiveFunctionHierarchyTreeItemStates }
                    }
                }
            } as IAppReducerState;
        }
        case actionTypes.CLEAR_HIERARCHY_STATE_INSTANCE: {
            const hierarchyStateInstances: IKeyValuePair<IHierarchyState> = deepCopyObject(state.hierarchyStateInstances);
            delete hierarchyStateInstances[action.instanceId];
            return {
                ...state,
                hierarchyStateInstances: hierarchyStateInstances
            } as IAppReducerState;
        }
        case actionTypes.COPILOT_PANEL_IS_OPEN: {
            const copilotPanelIsOpen: boolean = action.copilotPanelIsOpen as boolean;
            return {
                ...state,
                copilotPanelIsOpen: copilotPanelIsOpen
            }
        }
        case actionTypes.NAV_IS_OPEN: {
            const navIsOpen: boolean = action.navIsOpen as boolean;
            return {
                ...state,
                navIsOpen: navIsOpen
            }
        }
        case actionTypes.SHOW_VIDEO_GUIDE: {
            return {
                ...state,
                showVideoGuideDialog: true,
                initialVideo: action.initialVideo
            }
        }
        case actionTypes.DISMISS_VIDEO_GUIDE: {
            return {
                ...state,
                showVideoGuideDialog: false,
                initialVideo: undefined
            }
        }
        default:
    }

    return state;
};
