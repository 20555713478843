import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import {
    Stack,
    Text,
    DetailsList,
    IDetailsListProps,
    FontIcon,
    DefaultButton,
    IColumn,
    Spinner,
    SpinnerSize,
    ScrollablePane,
    mergeStyles,
    IDetailsList,
    ColumnActionsMode,
    IContextualMenuProps,
    ContextualMenu,
    ITextField,
    TextField,
    Label,
    DirectionalHint,
    IContextualMenuItem
} from '@fluentui/react';
import {
    CoherencePagination,
    CoherencePageSize,
    ICoherencePaginationProps,
    ICoherencePageSizeProps
} from '@coherence-design-system/controls';
import { coherencePageSizeStyles, componentStyles, getCoherencePaginationStyles } from './CustomDetailsList.styles';
import './detailsListStyleOverrides.css';
import { commonStyles } from '../../common/common.styles';
import { GenericDialog, GenericDialogMode } from '../GenericDialog/GenericDialog';
import { ICustomColumn } from './ICustomColumn';
import { adjustScrollableDetailsContainerHeight } from './CustomDetailsList.util';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/**
 * Excel column. Used with Excel export feature.
 */
export interface IExcelCol {
    colName: string;
    dataField: string;
}

/**
 * Excel data. Used with Excel export feature.
 */
export interface IExcelData {
    items: any[];
    cols: IExcelCol[];
}

/**
 * Excel export mode.
 */
export enum ExcelExportMode {
    /**
     * Use built in Excel export. This will export data currently being shown in the grid.
     * When server side pagination is used, it would be only the data on the current page.
     * If client side pagination is used, then it would include all the data across all pages.
     */
    Internal,

    /**
     * Use external Excel export implemented by the caller.
     */
    External
}

/**
 * Custom details list props.
 */
export interface ICustomDetailsListProps extends IDetailsListProps {
    /**
     * Show paginator.
     */
    showPaginator?: boolean;

    /**
     * Enable ability to jump to a page.
     */
    canJumpToPage?: boolean;

    /**
     * Initial paging label text. This is the word "Page" in (ex: Page 1 / 2). Leave undefined to use the default.
     */
    initialPagingLabelText?: string;

    /**
     * Previous page label text. Leave undefined to use the default.
     */
    previousPageLabelText?: string;

    /**
     * Next page label text. Leave undefined to use the default.
     */
    nextPageLabelText?: string;

    /**
     * Optionally hide the page indicator (ex: 1 / 2).
     */
    hidePageIndicator?: boolean;

    /**
     * Show page size next to paginator.
     */
    showPageSize?: boolean;

    /**
     * Page sizes.
     */
    pageSizes?: number[];

    /**
     * On page size changed callback.
     */
    onPageSizeChange?: (pageSize: number) => void;
    
    /**
     * Selected page. Not 0 based, starts at page 1.
     */
    selectedPage?: number;

    /**
     * Selected page changed callback.
     * @param page Page number. Not 0 based, starts at page 1.
     */
    onSelectedPageChange?: (page: number) => void;
    
    /**
     * Show no data found message.
     */
    showNoDataFoundMsg?: boolean;

    /**
     * Display total items on bottom of grid.
     */
    displayTotalItems?: boolean;

    /**
     * Alternate text to use for total items.
     */
    displayTotalItemsText?: string;
    
    /**
     * Aria label for grid.
     */
    ariaLabelForGrid?: string;

    /**
     * Show Excel export button. Exports data currently shown in grid. When using local paging this includes
     * all data. When using server side paging this is only data currently loaded in the grid.
     */
    showExcelExport?: boolean;

    /**
     * Show Excel import button.
     */
    showExcelImport?: boolean;

    /**
     * Excel export mode to use.
     */
    excelExportMode?: ExcelExportMode;

    /**
     * Indicates if export is running.
     */
    isExporting?: boolean;

    /**
     * Indicator if import is running.
     */
    isImporting?: boolean;

    /**
     * Text to use on the export button. Defaults to "Export".
     */
    excelExportButtonText?: string;

    /**
     * Text to use on the import button. Defaults to "Import".
     */
    excelImportButtonText?: string;

    /**
     * Callback called when export button is clicked.
     */
    excelExportButtonClicked?: () => void;

    /**
     * Callback called when import button is clicked.
     */
    excelImportButtonClicked?: () => void;

    /**
     * Export to Excel worksheet name.
     */
    exportExcelSheetName?: string;

    /**
     * Alternate data to export to Excel. Normally the props.items array will be exported.
     */
    exportAltExcelData?: IExcelData;

    /**
     * Used to implement server side paging. Normally the item count is based on items.length
     * which is good for client side paging. But when using srever side paging we know the amount
     * of total items from search results metadata. We can then handle retriving the next page of
     * data using the onSelectedPageChange and onPageSizeChange callbacks.
     */
    totalItemsAtServer?: number;

    /**
     * Indicates if data is loading. Will display a busy spinner if true.
     * The grid and paginator will still show, but with no data in it. This can help create a better
     * looking UI rather than replacing the entire grid with a spinner from outside this component.
     */
    isLoading?: boolean;

    /**
     * Optional id to use on root element of the component.
     */
    id?: string;

    /**
     * Optional text to display in the footer on the bottom right.
     */
    footerText?: string;

    /**
     * Puts the DetailsList in a ScrollablePane.
     */
    useScrollablePane?: boolean;

    /**
     * Loading text to use. If left undefined it will be "Loading...".
     */
    loadingText?: string;
}

const defaultPageSizes: number[] = [10, 25, 100];

/**
 * Custom details list.
 * Wraps usage of Fluent DetailsList with Coherence CoherencePagination and CoherencePageSize, among other features.
 * 
 * Note there is a data grid control in Coherence that also wraps the Fluent DetailsList,
 * see: https://coherence-portal.azurewebsites.net/Controls/DataGrid
 * It does not wrap CoherencePagination and CoherencePageSize, and has some behaviors that are not desirable for this
 * app (as of version @coherence-design-system/controls 4.3.0). So opting to not use that control and to make our own
 * control here for maximum flexibility.
 */
export const CustomDetailsList: React.FunctionComponent<ICustomDetailsListProps> = (props: ICustomDetailsListProps): JSX.Element => {
    const [selectedPage, setSelectedPage] = useState<number | undefined>(props.selectedPage);
    const [pageCount, setPageCount] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(props.pageSizes ? props.pageSizes[0] : defaultPageSizes[0]);
    const [pageData, setPageData] = useState<any[]>([]);
    const [showResizeColumnDialog, { toggle: toggleShowResizeColumnDialog }] = useBoolean(false);
    const detailsListRef = useRef<IDetailsList>(null);
    const columnToEdit = useRef<IColumn | null>(null);
    const resizeTextFieldRef = useRef<ITextField>(null);
    const [contextualMenuProps, setContextualMenuProps] = useState<IContextualMenuProps | undefined>(undefined);
    const [alteredColumns, setAlteredColumns] = useState<IColumn[]>();
    const resizeColumnInputId: string = useId();
    
    /**
     * Memoized field for excel data to export.
     */
    const excelData: IExcelData = useMemo<IExcelData>(() => {
        // If props.exportAltExcelData present then use that. Otherwise use props.items.
        if (props.exportAltExcelData) {
            return props.exportAltExcelData;
        } else {
            return {
                items: props.items,
                // If fieldName is not set, then ignore that column.
                cols: props.columns?.filter(x => x.fieldName !== undefined && x.fieldName !== null && x.fieldName !== '').map((col: IColumn) => {
                    return {
                        colName: col.name,
                        dataField: col.fieldName
                    } as IExcelCol
                })
            } as IExcelData;
        }
    }, [props.columns, props.exportAltExcelData, props.items]);

    /**
     * Resize column handler.
     */
    const resizeColumn = useCallback((column: IColumn) => {
        columnToEdit.current = column;
        toggleShowResizeColumnDialog();
    }, [toggleShowResizeColumnDialog]);

    /**
     * Get context menu props.
     */
    const getContextualMenuProps = useCallback((ev: React.MouseEvent<HTMLElement>, column: ICustomColumn): IContextualMenuProps => {
        const items: IContextualMenuItem[] = [];

        // If the column is resizable, add the resize option.
        if (column.isResizable) {
            items.push(
                { key: 'resize', text: 'Resize', onClick: () => resizeColumn(column) }
            );
        }

        // If the ICustomColumn has implemented a sortCallback, then add the sort context menu item.
        if (column.sortCallback) {
            items.push(
                { key: 'sort', text: 'Sort', onClick: () => column.sortCallback!(column) }
            );
        }

        return {
            items: items,
            target: ev.currentTarget as HTMLElement,
            gapSpace: -6, // This positions the context menu right under the column header with no gap. Works best when using the CustomColumnHeader with two line header. If using single line header anywhere this might need adjusting via props.
            isBeakVisible: false,
            useTargetWidth: false,
            directionalHint: DirectionalHint.bottomLeftEdge,
            onDismiss: () => setContextualMenuProps(undefined)
        };
    }, [resizeColumn]);

    /**
     * On column click handler.
     * Sets up a context menu.
     */
    const onColumnClick = useCallback((ev: React.MouseEvent<HTMLElement>, column: ICustomColumn): void => {
        if (column.columnActionsMode !== ColumnActionsMode.disabled) {
            setContextualMenuProps(getContextualMenuProps(ev, column));
        }
    }, [getContextualMenuProps]);

    /**
     * Effect that creates an altered column array from the original columns.
     * This is done to attach a column click handler used to attach a context menu with possible options for
     * sorting and resizing. This is done for accessibility reasons.
     */
    useEffect(() => {
        if (props.columns) {
            const alteredColumns: ICustomColumn[] = [...props.columns];
            alteredColumns.forEach(c => {
                c.columnActionsMode = c.sortCallback || c.isResizable ? ColumnActionsMode.hasDropdown : ColumnActionsMode.disabled;
                // Change the onColumnClick so that it calls our own internal onColumnClick.
                c.onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
                    // Note that the column may have its own onColumnClick defined. If that is the case, then assume
                    // that that onColumnClick handler was for sorting. But we need to call our own onColumnClick
                    // here in this component, which will show a context menu. The user can then choose to sort from that
                    // context menu in which case the columns onColumnClick would be called.
                    onColumnClick(ev, column as ICustomColumn);
                };
            })
            setAlteredColumns(alteredColumns);
        }
    }, [onColumnClick, props.columns]);

    /**
     * Effect for when the selected page changes.
     */
    useEffect(() => {
        // If the page changes from props, then update our local state for the selected page.
        setSelectedPage(props.selectedPage)
    }, [props.selectedPage])

    /**
     * Effect for when the page size or selected page changes, or items change.
     */
    useEffect(() => {
        const data: any[] = [];

        if (props.showPaginator) {
            if (typeof props.totalItemsAtServer === 'number') {
                setPageCount(Math.ceil(props.totalItemsAtServer / pageSize));
                data.push(...props.items);
            } else {
                if (selectedPage) {
                    for (let i: number = (selectedPage - 1) * pageSize;
                        (i < selectedPage * pageSize) && i < props.items.length;
                        i++) {
                        data.push(props.items[i]);
                    }
                }

                setPageCount(Math.ceil(props.items.length / pageSize));
            }
        } else {
            data.push(...props.items);
        }

        setPageData(data);
    }, [pageSize, props.items, props.showPaginator, props.totalItemsAtServer, selectedPage]);

    /**
     * Helper method to update the selected page.
     * @param page Page number.
     */
    const updateSelectedPage = useCallback((page: number) => {
        setSelectedPage(page);

        if (props.onSelectedPageChange && !Number.isNaN(page) && page > 0) {
            props.onSelectedPageChange(page);
        }
    }, [props]);

    /**
     * On page change event handler.
     * @param startItemIndex Start item index.
     * @param endItemIndex End item index.
     * @param newSelectedPage New selected page.
     */
    const onPageChange = useCallback((startItemIndex: number, endItemIndex: number, newSelectedPage: number): void => {
        // Set the state for the selected page here regardless of it was typed in (as users type in the box) or if
        // the page next or previous buttons where clicked. If typed, even if the number is blank (NaN) or > pageCount
        // it should be set in state here.
        setSelectedPage(newSelectedPage);

        // Use a setTimeout for the next code, because further updates to the selectedPage state might occur.
        setTimeout(() => {
            // This onPageChange event gets called when users type in the input box (as they type) and also
            // by clicking the page next and previous buttons. When typing in the input box, we don't want to
            // update the selected page until the user tabs out (or focus lost) of the input box. This code
            // will handle that situation by hooking up a blur event to the active element (which is the input box).
            const elem: Element | null = document.activeElement;
            if (elem && elem instanceof HTMLElement && elem.tagName === 'INPUT') {
                // User had typed in the input box.
                elem.onblur = () => {
                    updateSelectedPage(newSelectedPage);
                }
            } else {
                // User had clicked the page next or previous button.
                updateSelectedPage(newSelectedPage);
            }
        });
    }, [updateSelectedPage]);

    /**
     * On page size change event handler.
     * @param newPageSize New page size.
     */
    const onPageSizeChange = useCallback((newPageSize: string | number): void => {
        setPageSize(newPageSize as number);
        setSelectedPage(1);

        if (props.onPageSizeChange) {
            props.onPageSizeChange(Number(newPageSize));
        }
    }, [props]);

    /**
     * Memoized props passed to pagination control.
     */
    const paginationProps: ICoherencePaginationProps = useMemo<ICoherencePaginationProps>(() => {
        return {
            initialPagingLabelText: props.initialPagingLabelText,
            previousPageLabelText: props.previousPageLabelText,
            nextPageLabelText: props.nextPageLabelText,
            pageCount: pageCount,
            selectedPage: selectedPage,
            previousPageAriaLabel: 'previous page',
            nextPageAriaLabel: 'next page',
            inputFieldAriaLabel: `page number ${selectedPage} of ${pageCount}`,
            onPageChange: onPageChange,
            canJumpToPage: props.canJumpToPage !== undefined ? props.canJumpToPage : true,
            styles: getCoherencePaginationStyles(props.hidePageIndicator !== undefined ? props.hidePageIndicator : false)
        } as ICoherencePaginationProps;
    }, [onPageChange, pageCount, props.canJumpToPage, props.hidePageIndicator, props.initialPagingLabelText, props.nextPageLabelText, props.previousPageLabelText, selectedPage]);

    /**
     * Memoized props passed to pagination page size control.
     */
    const paginationPageSizeProps: ICoherencePageSizeProps = useMemo<ICoherencePageSizeProps>(() => {
        return {
            pageSize: pageSize,
            pageSizeList: (props.pageSizes || defaultPageSizes).map((x) => {
                return { key: x, text: x.toString() }
            }),
            comboBoxAriaLabel: 'page size',
            onPageSizeChange: onPageSizeChange,
            styles: coherencePageSizeStyles
        } as ICoherencePageSizeProps;
    }, [onPageSizeChange, pageSize, props.pageSizes]);

    /**
     * Renders the Excel file export element.
     * @returns JSX for the excel file export.
     */
    const renderExcelFileExport = (): JSX.Element => {
        const exportButtonText: string = props.excelExportButtonText || 'Export';
        if (props.excelExportMode === ExcelExportMode.External) {
            return (
                <DefaultButton
                    ariaLabel={exportButtonText}
                    className={componentStyles.excelExportImportButton}
                    disabled={excelData.items === undefined || excelData.items.length === 0 || props.isExporting}
                    onClick={() => {
                        telemetryService.trackEvent({ name: trackedEvent.customDetailsListExportButtonClicked });
                        if (props.excelExportButtonClicked) {
                            props.excelExportButtonClicked();
                        }
                    }}>
                    <FontIcon iconName="ExcelDocument" className={componentStyles.excelIcon} />
                    <Text className={componentStyles.exportButtonText}>
                        {!props.isExporting && (
                            <span>{exportButtonText}</span>
                        )}
                        {props.isExporting && (
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                        )}
                    </Text>
                </DefaultButton>
            );
        } else if (props.excelExportMode === ExcelExportMode.Internal) {
            return (
                // See: https://www.npmjs.com/package/react-data-export
                <ExcelFile filename="export" element={
                    <DefaultButton
                        ariaLabel={exportButtonText}
                        className={componentStyles.excelExportImportButton}
                        disabled={excelData.items === undefined || excelData.items.length === 0}>
                        <FontIcon iconName="ExcelDocument" className={componentStyles.excelIcon} />
                        <Text className={componentStyles.exportButtonText}>{exportButtonText}</Text>
                    </DefaultButton>
                }>
                    <ExcelSheet data={excelData.items} name={props.exportExcelSheetName}>
                        {
                            excelData.cols.map((col: IExcelCol, index: number) => {
                                return <ExcelColumn key={index} label={col.colName} value={col.dataField} />
                            })
                        }
                    </ExcelSheet>
                </ExcelFile>
            );
        }

        return <></>;
    };

    /**
     * Renders the Excel file import element.
     * @returns JSX for the excel file import.
     */
    const renderExcelFileImport = (): JSX.Element => {
        const importButtonText: string = props.excelImportButtonText || 'Import';
        return (
            <DefaultButton
                ariaLabel={importButtonText}
                className={componentStyles.excelExportImportButton}
                disabled={props.isImporting}
                onClick={() => {
                    telemetryService.trackEvent({ name: trackedEvent.customDetailsListImportButtonClicked });
                    if (props.excelImportButtonClicked) {
                        props.excelImportButtonClicked();
                    }
                }}>
                <FontIcon iconName="ExcelDocument" className={componentStyles.excelIcon} />
                <Text className={componentStyles.exportButtonText}>
                    {!props.isImporting && (
                        <span>{importButtonText}</span>
                    )}
                    {props.isImporting && (
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    )}
                </Text>
            </DefaultButton>
        );
    };

    /**
     * Renders the footer.
     * @returns JSX for the footer.
     */
    const renderFooter = (): JSX.Element => {
        return (
            <>
                {!props.isLoading && !props.showNoDataFoundMsg &&
                    <>
                        {(props.showPaginator || props.showPageSize || props.displayTotalItems || props.showExcelExport || props.showExcelImport) && (
                            <Stack horizontal horizontalAlign="start" wrap className={componentStyles.footerContainer}>
                                {props.showPaginator && pageData && pageData.length > 0 && (
                                    <Stack.Item>
                                        <CoherencePagination {...paginationProps} />
                                    </Stack.Item>
                                )}
                                {props.showPageSize && (
                                    <Stack.Item>
                                        <div className={componentStyles.pageSizeContainer}>
                                            <CoherencePageSize {...paginationPageSizeProps} />
                                        </div>
                                    </Stack.Item>
                                )}
                                {props.displayTotalItems && (
                                    <Stack.Item>
                                        <div className={componentStyles.footerTextContainer}>
                                            <Text variant="medium" className={componentStyles.footerText}>
                                                {props.displayTotalItemsText ? props.displayTotalItemsText : 'Total items'}:&nbsp;
                                                {props.totalItemsAtServer !== undefined ? props.totalItemsAtServer : props.items.length}
                                            </Text>
                                        </div>
                                    </Stack.Item>
                                )}
                                {props.showExcelExport && (
                                    <Stack.Item>
                                        <div className={componentStyles.excelExportImportContainer}>
                                            {renderExcelFileExport()}
                                        </div>
                                    </Stack.Item>
                                )}
                                {props.showExcelImport && (
                                    <Stack.Item>
                                        <div className={componentStyles.excelExportImportContainer}>
                                            {renderExcelFileImport()}
                                        </div>
                                    </Stack.Item>
                                )}
                                {props.footerText && (
                                    <Stack.Item>
                                        <div className={componentStyles.footerTextContainer}>
                                            <Text variant="medium" className={componentStyles.footerText}>{props.footerText}</Text>
                                        </div>
                                    </Stack.Item>
                                )}
                            </Stack>
                        )}
                    </>
                }
            </>
        );
    };

    /**
     * Renders the details list.
     * @returns JSX for details list.
     */
    const renderDetailsList = (): JSX.Element => {
        return (
            <>
                <DetailsList
                    { ...props }
                    columns={alteredColumns}
                    componentRef={detailsListRef}
                    className={mergeStyles(componentStyles.detailsList, props.className)}
                    items={props.isLoading ? [] : pageData}
                    onDidUpdate={() => {
                        if (props.useScrollablePane) {
                            adjustScrollableDetailsContainerHeight(props.id)
                        }
                    }}
                />
                {contextualMenuProps && contextualMenuProps.items.length > 0 && <ContextualMenu {...contextualMenuProps} />}
            </>
        );
    };

    /**
     * Render loading element.
     * @returns JSX for the loading element.
     */
    const renderLoading = (): JSX.Element => {
        return (
            <>
                {props.isLoading && (
                    <div>
                        <Text variant='mediumPlus'>{props.loadingText !== undefined ? props.loadingText : 'Loading...'}</Text>
                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinnerInline} />
                    </div>
                )}
            </>
        );
    };

    /**
     * Render no data found element.
     * @returns JSX for no data found element.
     */
    const renderNoDataFound = (): JSX.Element => {
        return (
            <>
                {!props.isLoading && props.showNoDataFoundMsg && <Text variant="medium">No data found</Text>}
            </>
        );
    };

    /**
     * Render resize dialog. This is shown when user chooses to resize a column.
     * @returns JSX for the resize dialog.
     */
    const renderResizeDialog = (): JSX.Element => {
        const resizeText: string = 'Enter desired column width pixels';
        const onOkClicked = () => {
            if (resizeTextFieldRef.current && columnToEdit.current && resizeTextFieldRef.current.value) {
                const width: number = Number(resizeTextFieldRef.current.value);
                detailsListRef.current?.updateColumn(columnToEdit.current, { width: width });
            }
            toggleShowResizeColumnDialog();
        };
        return (
            <GenericDialog
                displayDialog={showResizeColumnDialog}
                title={'Resize column'}
                mode={GenericDialogMode.OkCancel}
                width={280}
                content={
                    <>
                        <Label htmlFor={resizeColumnInputId}>{resizeText}</Label>
                        <TextField
                            id={resizeColumnInputId}
                            autoComplete='off'
                            componentRef={resizeTextFieldRef}
                            ariaLabel={resizeText}
                            onKeyDown={(key: React.KeyboardEvent<HTMLElement>) => {
                                if (key.code === 'Enter') {
                                    onOkClicked();
                                }
                            }}
                        />
                    </>
                }
                onOkClicked={() => {
                    onOkClicked();
                }}
                onCancelClicked={() => {
                    toggleShowResizeColumnDialog();
                }}
            />
        );
    };

    /**
     * Render the main element.
     * @returns JSX for main element.
     */
    const renderMain = (): JSX.Element => {
        return (
            <>
                {renderDetailsList()}
                {renderLoading()}
                {renderNoDataFound()}
                {renderResizeDialog()}
            </>
        );
    };

    return (
        <div id={props.id}>
            {props.useScrollablePane && (
                <>
                    {/* Also using the props.id if supplied to give a unique id to the scrollable container id. */}
                    {/* If multiple of these custom details lists are used on one page, then supply a different id for each. */}
                    {/* See adjustScrollableDetailsContainerHeight function. */}
                    <div className={componentStyles.detailsListScrollableContainer} id={`${props.id ? props.id : ''}_detailsListScrollableContainer`}>
                        <ScrollablePane>
                            {renderMain()}
                        </ScrollablePane>
                    </div>
                    {/* Render the footer below the scrollable pane. */}
                    {renderFooter()}
                </>
            )}
            {!props.useScrollablePane && (
                <>
                    {renderMain()}
                    {renderFooter()}
                </>
            )}
        </div>
    );
};
