import { SupplierShipmentDetail } from '../../models/shipment/supplierShipmentDetail';
import { appConfig } from '../../shell/appConfig';
import { ApiClientBase, ApiService } from './apiClientBase';

/**
 * Receipting shipment api client.
 */
class ReceiptingShipmentApiClient extends ApiClientBase {
    /**
     * Constructor for the receipting service api client.
     */
    constructor() {
        super(ApiService.Shipment)
    }
    
    /**
     * Gets shipment status trail
     * @param poNumber Purchase order number.
     * @param lineNumber Line number.
     * @returns Shipments status trail.
     */
    public async shipmentStatusTrail(poNumber: number, lineNumber: number): Promise<SupplierShipmentDetail[] | null> {
        const mockDataFile: string = 'shipmentStatusTrailLenovo.json';
        // const mockDataFile: string = 'shipmentStatusTrailZones.json';
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/${mockDataFile}` : `${appConfig.current.service.receiptingShipmentApiBaseUrl}shipments/shipmentstatustrail`;
        return await this.getObjectArray<SupplierShipmentDetail>(SupplierShipmentDetail, apiUrl, {
            purchaseOrder: poNumber,
            lineNumber: lineNumber
        });
    }
}

export const receiptingShipmentApiClient = new ReceiptingShipmentApiClient();
