import React, { useEffect, useState } from 'react';
import { ScrollablePane, Separator, Link, Image, Stack, FontIcon } from '@fluentui/react';
import { ICommonPageProps } from '../../common/common.types';
import { componentStyles } from './PageWrapper.styles';
import { appConstants } from '../../common/appConstants';
import { stackTokensNormalGap } from '../../common/common.styles';
import { ServiceAlert } from '../../models/utility/serviceAlert';
import { webServerApiClient } from '../../services/api/webServerApiClient';
import { useAppSelector } from '../../store/hooks';

interface IPageWrapperProps extends ICommonPageProps {
    onScroll?: () => void;
}

export const PageWrapper: React.FunctionComponent<IPageWrapperProps> = (props: IPageWrapperProps): JSX.Element => {
    const isNavInMobileMode: boolean = window.innerWidth <= 1024;

    const [serviceAlert, setServiceAlert] = useState<ServiceAlert>();

    // Redux store selectors to get state from the store when it changes.
    const copilotPanelIsOpen: boolean =
        useAppSelector<boolean>((state) => state.appReducer.copilotPanelIsOpen);
    const navIsOpen: boolean =
        useAppSelector<boolean>((state) => state.appReducer.navIsOpen);

    /**
     * Load service alert.
     */
    const loadServiceAlert = async () => {
        const data: ServiceAlert | null = await webServerApiClient.getServiceAlert();
        if (data) {
            setServiceAlert(data);
        }
    };

    /**
     * Effect for when the component loads.
     */
    useEffect(() => {
        // This load service alert call is async, but not awaiting here for it as we don't need to.
        loadServiceAlert();
    }, []);

    const scrollablePaneClass = () => {
        if (!navIsOpen || isNavInMobileMode) {
            if (!copilotPanelIsOpen) {
                return componentStyles.scrollablePaneNavCollapsedCopilotCollapsed;
            } else {
                return componentStyles.scrollablePaneNavCollapsedCopilotExpanded;
            }
        } else {
            if (!copilotPanelIsOpen) {
                return componentStyles.scrollablePaneNavExpandedCopilotCollapsed;
            } else {
                return componentStyles.scrollablePaneNavExpandedCopilotExpanded;
            }
        }
    };

    return (
        <ScrollablePane
            className={scrollablePaneClass()}
            onScroll={()=>{
                if (props.onScroll) {
                    props.onScroll();
                }
            }}>

            {serviceAlert && serviceAlert.display && (
                <div className={componentStyles.serviceAlertContainer}>
                    <div className={componentStyles.serviceAlertHeading}><FontIcon iconName='Warning' className={componentStyles.serviceAlertIcon} />Service Alert</div>
                    <div className={componentStyles.serviceAlertText}>{serviceAlert.message}</div>
                </div>
            )}

            {props.children}

            <footer className={componentStyles.footer}>
                <Separator/>

                <Stack horizontal tokens={stackTokensNormalGap}>
                    <Image src={`${appConstants.publicUrl}/images/logo_microsoft.svg`} alt="Microsoft logo" height="20px" className={componentStyles.msLogo} />
                    <Link href="http://go.microsoft.com/fwlink/?LinkId=521839" target="_blank">Privacy & Cookies</Link>
                </Stack>
            </footer>
        </ScrollablePane>
    )
};
