import React, { useState, useEffect, useReducer, useRef, useMemo } from 'react';
import { Header, HeaderButton, IHeaderButtonProps, usePanels, useResizeGroup } from '@coherence-design-system/header';
import { FeedbackCenter } from '@coherence-design-system/feedback-center';
import { appConfig } from '../appConfig';
import { graphApiClient } from '../../services/api/graphApiClient';
import { getUserFullName } from '../../services/auth/msalHelper';
import { appConstants } from '../../common/appConstants';
import { componentStyles, headerStyles } from './AppHeader.styles';
import { ProfilePanel } from '../ProfilePanel/ProfilePanel';
import {
    FontIcon,
    IPanel,
    Image,
    ResizeGroup,
    Text,
    TooltipHost
} from '@fluentui/react';
import { NotificationPanel } from '../NotificationPanel/NotificationPanel';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ClientNotificationItem } from '../../models/clientNotification/clientNotificationItem';
import { commonString } from '../../common/commonString';
import { InitialVideo, VideoGuide } from '../VideoGuide/VideoGuide';
import { useBoolean } from '@fluentui/react-hooks';
import { CustomTeachingBubble, TeachingBubbleItem, teachingBubbleCheckShow } from '../../components/CustomTeachingBubble/CustomTeachingBubble';
import { telemetryService } from '../../services/TelemetryService/TelemetryService';
import { trackedEvent } from '../../services/TelemetryService/trackedEvents';
import { NavigateFunction, useNavigate } from 'react-router';
import { padNumberWithLeadingChar } from '../../common/common.func.transform';
import { validationConstants } from '../../common/validationConstants';
import { GenericDialog, GenericDialogMode } from '../../components/GenericDialog/GenericDialog';
import { AppDispatch } from '../../store/reduxStore';
import { clearSearchResults, resetApiCallStateForSearch, resetApiCallStateForSearchForPo } from '../../store/actions/search.action';
import { dismissVideoGuide, setCopilotPanelIsOpen, setNavIsOpen, showNotificationPanel, showVideoGuide } from '../../store/actions/app.action';
import { getOcvOptions } from './ocvOptions';
import { Template } from '@coherence-design-system/controls';
import { EnhancedSearch } from '@coherence-design-system/enhanced-search';
import _ from 'lodash';
import { UserProfile } from '../../models/user/userProfile';

export interface IAppHeaderProps {
}

export const AppHeader: React.FunctionComponent<IAppHeaderProps> = (props: IAppHeaderProps): JSX.Element => {
    const [userImage, setUserImage] = useState<string | undefined>(undefined);
    const [displayInvalidPoDialog, { toggle: toggleDisplayInvalidPoDialog }] = useBoolean(false);
    const { currentPanel, openPanel, closePanel } = usePanels();
    const { onReduceData, onGrowData, onRenderData } = useResizeGroup({ closePanel, currentPanel });
    const ocvFeedbackPanelRef = useRef<IPanel>(null);
    const notificationPanelRef = useRef<IPanel>(null);
    const profilePanelRef = useRef<IPanel>(null);
    const [, forceRender] = useReducer((s) => s + 1, 0);

    // Redux store selectors to get state from the store when it changes.
    const navIsOpen: boolean =
        useAppSelector<boolean>((state) => state.appReducer.navIsOpen);
    const copilotPanelIsOpen: boolean =
        useAppSelector<boolean>((state) => state.appReducer.copilotPanelIsOpen);
    const clientNotificationItems: ClientNotificationItem[] =
        useAppSelector<ClientNotificationItem[]>((state) => state.appReducer.clientNotificationItems);
    const showVideoGuideDialog: boolean =
        useAppSelector<boolean>((state) => state.appReducer.showVideoGuideDialog);
    const initialVideo: InitialVideo | undefined =
        useAppSelector<InitialVideo | undefined>((state) => state.appReducer.initialVideo);
    const userProfile: UserProfile | undefined =
        useAppSelector<UserProfile | undefined>(state => state.appReducer.apiLoadUserProfile.userProfile);

    // Redux store dispatch to send actions to the store.
    const dispatch: AppDispatch = useAppDispatch();

    const navigate: NavigateFunction = useNavigate();

    const ocvOptions = getOcvOptions();
    ocvOptions.callbackFunctions!.onDismiss = () => {
        closePanel();
    };

    /**
     * Effect for when the component loads.
     */
    useEffect(() => {
        // Only make the call to get the user image if it has not been set yet.
        if (userImage === undefined) {
            (async () => {
                const img: string = await graphApiClient.getUserPhoto();
                setUserImage(img || ''); // If no image is returned, set it to an empty string.
            })();
        }
    }, [userImage]);

    /**
     * Renders site label.
     * @returns JSX element.
     */
    const renderSiteLabel = (): JSX.Element => {
        return (
            <>
                <span className={componentStyles.siteLabel}>{commonString.siteName}</span>
                {appConfig.current.settings.displayEnvNameInHeader && (
                    <span className={componentStyles.envName}>[{appConfig.current.settings.environmentName}]</span>
                )}
            </>
        );
    };

    /**
     * Header buttons to display in the resize group. Not including the profile button, as it is a static trigger.
     */
    const resizeGroupHeaderButtons = useMemo<IHeaderButtonProps[]>(() => {
        const buttons: IHeaderButtonProps[] = [];

        if (appConfig.current.featureFlighting.copilot || userProfile?.isDri) {
            buttons.push(
                {
                    key: 'copilot',
                    onRenderIcon: () => {
                        return (
                            <div>
                                <Image src="/images/copilotWhite.svg" width="20px" alt="Copilot" />
                            </div>
                        );
                    },
                    ariaLabel: commonString.copilot,
                    text: commonString.copilot,
                    checked: copilotPanelIsOpen,
                    onClick: () => {
                        telemetryService.trackEvent({ name: trackedEvent.copilotButtonClicked });
                        dispatch(setCopilotPanelIsOpen(!copilotPanelIsOpen));
                    }
                } as IHeaderButtonProps
            );
        }

        buttons.push(
            {
                key: 'videoGuide',
                onRenderIcon: () => {
                    return (
                        <>
                            <div className={componentStyles.headerIconContainer} id='videoGuide'>
                                <FontIcon iconName='Video' />{teachingBubbleCheckShow(TeachingBubbleItem.VideoGuide)}
                            </div>
                            { teachingBubbleCheckShow(TeachingBubbleItem.VideoGuide) && (
                                <CustomTeachingBubble
                                    targetElemId='videoGuide'
                                    teachingBubbleItem={TeachingBubbleItem.VideoGuide}
                                    teachingBubbleHeadline={commonString.videoTutorialGuides}
                                    teachingBubbleContent='Click here to view video tutorial guides about Goods and Services Receipting (GSR) and how to use each page.'
                                    disableIsOnScreenCheck={true}
                                    gapSpace={10}
                                    onGotItClicked={() => forceRender()}
                                    onDisableClicked={() => forceRender()}
                                />
                            )}
                        </>
                    );
                },
                ariaLabel: commonString.videoTutorialGuides,
                text: commonString.videoTutorialGuides,
                checked: showVideoGuideDialog,
                onClick: () => {
                    telemetryService.trackEvent({ name: trackedEvent.openVideoGuidesButtonClicked });
                    dispatch(showVideoGuide());
                }
            } as IHeaderButtonProps
        );

        buttons.push(
            {
                // Using my own notification panel, not the one included in Coherence header.
                // It does not work or look the way I want for this app. */}
                key: 'customNotifications',
                onRenderIcon: () => {
                    return (
                        <div className={componentStyles.headerIconContainer}>
                            <FontIcon iconName='Ringer' />
                            <div className={componentStyles.notificationCountBadge}>
                                {clientNotificationItems.length > 99 ? '99+' : clientNotificationItems.length.toString()}
                            </div>
                        </div>
                    );
                },
                ariaLabel: commonString.notifications,
                text: commonString.notifications,
                checked: _.isEqual(currentPanel, notificationPanelRef),
                onClick: () => {
                    telemetryService.trackEvent({ name: trackedEvent.openNotificationPanelButtonClicked });
                    dispatch(showNotificationPanel(!_.isEqual(currentPanel, notificationPanelRef)));
                    openPanel(notificationPanelRef);
                }
            } as IHeaderButtonProps
        );

        buttons.push(
            {
                key: 'ocvFeedback',
                onRenderIcon: () => {
                    return (
                        <div className={componentStyles.headerIconContainer}>
                            <FontIcon iconName='Feedback' />
                        </div>
                    );
                },
                ariaLabel: commonString.feedback,
                text: commonString.feedback,
                checked: _.isEqual(currentPanel, ocvFeedbackPanelRef),
                onClick: () => {
                    telemetryService.trackEvent({ name: trackedEvent.openOcvPanelButtonClicked });
                    openPanel(ocvFeedbackPanelRef);
                }
            } as IHeaderButtonProps
        );

        return buttons;
    }, [clientNotificationItems.length, copilotPanelIsOpen, currentPanel, dispatch, openPanel, showVideoGuideDialog, userProfile?.isDri]);

    return (
        <div>
            <Header
                title={{ href: '/', children: renderSiteLabel() }}
                showHamburger={true}
                styles={headerStyles}
                toggleNavCollapsed={() => {
                    dispatch(setNavIsOpen(!navIsOpen));
                }}
                isNavCollapsed={!navIsOpen}
            >
                <Template slot="search">
                    <EnhancedSearch
                        placeholder='Search for PO'
                        enableAutoSuggestions={false}
                        onSearch={(newValue: any) => {
                            newValue = newValue.trim();
                            if (!RegExp(validationConstants.poNumber.pattern!).test(newValue)) {
                                toggleDisplayInvalidPoDialog();
                            } else {
                                dispatch(resetApiCallStateForSearch());
                                dispatch(resetApiCallStateForSearchForPo());
                                dispatch(clearSearchResults());
    
                                const po: string = padNumberWithLeadingChar(newValue, 10, '0');
                                navigate(`${appConstants.publicUrl}/Edit/${po}`);
                            }
                        }}
                    / >
                </Template>

                <Template slot="collapsible-triggers">
                    <ResizeGroup
                        className={componentStyles.resizeGroup}
                        data={{
                            primary: resizeGroupHeaderButtons,
                            overflow: []
                        }}
                        onReduceData={onReduceData}
                        onGrowData={onGrowData}
                        onRenderData={onRenderData}
                    />
                </Template>

                <Template slot="static-triggers">
                    {/* Using my own profile panel, not using the one in Coherence, as I want more customizability. */}
                    <TooltipHost content={`Profile for ${getUserFullName()}`} id={'profile'}>
                        <HeaderButton
                            onRenderIcon={() => {
                                return (
                                    <div className={componentStyles.headerIconContainer}>
                                        {userImage && (
                                            <Image src={userImage} height={32} width={32} className={componentStyles.userImage} alt="User photo" />
                                        )}
                                        {!userImage && (
                                            <FontIcon iconName='Contact' />
                                        )}
                                    </div>
                                );
                            }}
                            onClick={() => {
                                openPanel(profilePanelRef);
                            }}
                            checked={_.isEqual(currentPanel, profilePanelRef)}
                            aria-describedby={'profile'}
                        />
                    </TooltipHost>
                </Template>
            </Header>

            <VideoGuide
                isOpen={showVideoGuideDialog}
                showInitialVideo={initialVideo}
                onDismissVideoGuide={() => {
                    dispatch(dismissVideoGuide());
                }}
            />

            <NotificationPanel
                componentRef={notificationPanelRef}
                isOpen={Boolean(currentPanel === notificationPanelRef)}
                onDismiss={() => closePanel()}
            />

            <FeedbackCenter
                componentRef={ocvFeedbackPanelRef}
                isOpen={Boolean(currentPanel === ocvFeedbackPanelRef)}
                onDismiss={() => closePanel()}
                ocvOptions={ocvOptions}
            />

            <ProfilePanel
                componentRef={profilePanelRef}
                isOpen={Boolean(currentPanel === profilePanelRef)}
                onDismiss={() => closePanel()}
                userImage={userImage}
            />

            <GenericDialog
                displayDialog={displayInvalidPoDialog}
                title={'Invalid PO'}
                content={
                    <Text variant="medium">The PO number entered is invalid.</Text>
                }
                mode={GenericDialogMode.Ok}
                onOkClicked={() => {
                    toggleDisplayInvalidPoDialog();
                }}
            />

        </div>
    );
};
