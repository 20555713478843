import React from 'react';
import { useNavigate } from 'react-router-dom';
import { INavLink, INavLinkGroup, Navigation } from '@coherence-design-system/navigation';
import { appConfig } from '../appConfig';
import { appConstants } from '../../common/appConstants';
import { UserProfile } from '../../models/user/userProfile';
import { useAppSelector } from '../../store/hooks';
import { componentStyles } from './AppNav.styles';

export interface IAppNavProps {
}

export const AppNav: React.FunctionComponent<IAppNavProps> = (props: IAppNavProps): JSX.Element => {
    // See: https://stackoverflow.com/questions/63471931/using-history-with-react-router-dom-v6
    // https://devtop.org/react-router-6-what-changed-upgrading-guide/
    // https://javascript.plainenglish.io/4-easy-steps-to-upgrade-react-router-v5-to-v6-d3016b3a8d2a
    // https://medium.com/@manishsundriyal/whats-new-in-react-router-v6-20eefe665be9
    // https://reactrouterdotcom.fly.dev/docs/en/v6/upgrading/v5
    const navigate = useNavigate();

    // Redux store selectors to get state from the store when it changes.
    const navIsOpen: boolean =
        useAppSelector<boolean>((state) => state.appReducer.navIsOpen);
    const userProfile: UserProfile | undefined =
        useAppSelector<UserProfile | undefined>(state => state.appReducer.apiLoadUserProfile.userProfile);

    const pageIsSelected = (pagePath: string, altPagePaths: string | undefined): boolean => {
        let isMatch: boolean = false;
        const pathMatch = (checkPath: string): boolean => {
            const path: string = `${window.location.origin}${window.location.pathname}`.toLowerCase();
            const test: string = `${window.location.protocol}//${window.location.host}${appConstants.publicUrl}/${checkPath}`.toLowerCase();
            // Check to see if the current path starts with the test path. Using startsWith rather than direct equality
            // because some paths, like Edit, also have a PO number afterwards (/Edit/1234567).
            return path.startsWith(test);
        }
        if (pathMatch(pagePath)) {
            isMatch = true;
        }
        // If alternate page paths to determine if selected, check those.
        // altPagePaths can contain multiple paths delimited by |.
        if (altPagePaths) {
            const arr: string[] = altPagePaths.split('|');
            for (let i: number = 0; i < arr.length; i++) {
                if (pathMatch(arr[i])) {
                    isMatch = true;
                    break;
                }
            }
        }

        return isMatch;
    };

    const createNavLink = (pageName: string, ariaLabel: string, pagePath: string, icon: string, altPagePaths?: string): INavLink => {
        return {
            name: pageName,
            url: `${appConstants.publicUrl}/${pagePath}`,
            onClick: () => {
                navigate(`${appConstants.publicUrl}/${pagePath}`);
            },
            ariaLabel: ariaLabel,
            key: `${pageName}Key`,
            icon: icon,
            isSelected: pageIsSelected(pagePath, altPagePaths)
        } as INavLink;
    };

    const navLinks: INavLink[] = [];

    navLinks.push(...[
        createNavLink('Home', 'Home', 'Home', 'Home', 'Searchresults|Edit|CloseLine'),
        createNavLink('User Profile', 'User Profile', 'UserProfile', 'PlayerSettings'),
        createNavLink('Access Request', 'Access Request', 'AccessRequest/Requests', 'Permissions', 'AccessRequest/Requests|AccessRequest/Approvals'),
        createNavLink('Reports', 'Reports', 'Reports', 'ReportDocument'),
        createNavLink('Prior Year Accrual', 'Prior Year Accrual', 'PriorYearAccrual/ForYou', 'IssueTracking', 'PriorYearAccrual/ForYou|PriorYearAccrual/Elevated|PriorYearAccrual/Administration')
    ]);

    if (userProfile?.isAccrualsAdmin || userProfile?.isDri) {
        navLinks.push(
            createNavLink('Accrual Dashboard', 'Accrual Dashboard', 'AccrualDashboard', 'Money')
        );
    }

    navLinks.push(
        createNavLink('FAQ', 'Frequently Asked Questions', 'FAQ', 'Help')
    );

    if (appConfig.current.settings.displayDriToolsPage && userProfile?.isDri) {
        navLinks.push(
            createNavLink('DRI Tools', 'DRI Tools', 'DRITools', 'Toolbox')
        );
    }

    if (appConfig.current.settings.displayDiagnosticsPage && userProfile?.isDri) {
        navLinks.push(
            createNavLink('Diagnostics', 'Diagnostics', 'Diagnostics', 'Settings')
        );
    }

    const navLinkGroups: INavLinkGroup[] = [
        {
            key: 'Left Nav Menu',
            links: navLinks
        } as INavLinkGroup
    ];

    return (
        <div className={componentStyles.navContainer}>
            <Navigation
                groups={navLinkGroups}
                isNavCollapsed={!navIsOpen}
            />
        </div>
    );
};
