import { AnyAction } from 'redux';
import { PriorYearAccrualLineItem } from '../../../models/priorYearAccrual/priorYearAccrualLineItem';
import { PriorYearAccrualSearch, PriorYearAccrualSearchRole } from '../../../models/priorYearAccrual/priorYearAccrualSearch';
import { receiptingApiClient } from '../../../services/api/receiptingApiClient';
import { receiptingAccrualsApiClient } from '../../../services/api/receiptingAccrualsApiClient';
import { AppDispatch } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';
import { createShortGuid } from '../../../common/common.func.guid';
import { PurchaseOrderLineItemPair } from '../../../models/purchaseOrder/purchaseOrderLineItemPair';
import { PurchaseOrderLineClose } from '../../../models/purchaseOrder/purchaseOrderLineClose';
import { PriorYearAccrualSearchResults } from '../../../models/priorYearAccrual/priorYearAccrualSearchResults';
import { PriorYearAccrualEmailSentDetail } from '../../../models/priorYearAccrual/priorYearAccrualEmailSentDetail';
import { PriorYearAccrualHierarchyScope } from '../../../models/priorYearAccrual/priorYearAccrualHierarchyScope';
import { ProfitCenterCodeMapping } from '../../../models/priorYearAccrual/profitCenterCodeMapping';
import { CorpDetails } from '../../../models/priorYearAccrual/corpDetails';
import { PriorYearAccrualUserSummary } from '../../../models/priorYearAccrual/priorYearAccrualUserSummary';
import { PriorYearAccrualElevatedSummary } from '../../../models/priorYearAccrual/priorYearAccrualElevatedSummary';
import { CostCategoryConfiguration } from '../../../models/priorYearAccrual/costCategoryConfiguration';
import { CodeValue } from '../../../models/priorYearAccrual/codeValue';

/**
 * Payload used with callApiPriorYearAccrualUserSummary action.
 */
export interface IApiPriorYearAccrualUserSummary extends ICallApiBase {
    priorYearAccrualUserSummary?: PriorYearAccrualUserSummary | null;
}

/**
 * Call API to get prior year accruals user summary.
 * @param priorYearAccrualSearch Search params.
 * @returns Redux dispatch function.
 */
export const callApiPriorYearAccrualUserSummary = (priorYearAccrualSearch: PriorYearAccrualSearch): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<PriorYearAccrualUserSummary | null>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_USER_SUMMARY,
        async () => {
            return await receiptingApiClient.loadPriorYearAccrualUserSummary(priorYearAccrualSearch);
        },
        (payload: IApiPriorYearAccrualUserSummary, data: PriorYearAccrualUserSummary | null) => {
            payload.priorYearAccrualUserSummary = data;
        }
    );
};

/**
 * Payload used with callApiPriorYearAccrualElevatedSummary action.
 */
export interface IApiPriorYearAccrualElevatedSummary extends ICallApiBase {
    priorYearAccrualElevatedSummary?: PriorYearAccrualElevatedSummary | null;
}

/**
 * Call API to get prior year accruals elevated (agent, accruals admin, finance controller) summary.
 * @param priorYearAccrualSearchRole Prior year accrual search role. When used with this elevated summary it will be Agent, AccrualAdmin, or FinanceController. Not User role.
 * @param PriorYearAccrualSearch Search params.
 * @returns Redux dispatch function.
 */
export const callApiPriorYearAccrualElevatedSummary = (priorYearAccrualSearchRole: PriorYearAccrualSearchRole, priorYearAccrualSearch: PriorYearAccrualSearch): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<PriorYearAccrualElevatedSummary | null>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_ELEVATED_SUMMARY,
        async () => {
            return await receiptingApiClient.loadPriorYearAccrualElevatedSummary(priorYearAccrualSearchRole, priorYearAccrualSearch);
        },
        (payload: IApiPriorYearAccrualElevatedSummary, data: PriorYearAccrualElevatedSummary | null) => {
            payload.priorYearAccrualElevatedSummary = data;
        }
    );
};

/**
 * Payload used with callApiPriorYearAccrualRegions action.
 */
export interface IApiPriorYearAccrualRegions extends ICallApiBase {
    regions?: string[] | null;
}

/**
 * Call API to get prior year accrual regions to use on search filter dropdown.
 * @returns Redux dispatch function.
 */
export const callApiPriorYearAccrualRegions = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<string[] | null>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_REGIONS,
        async () => {
            return await receiptingApiClient.priorYearAccrualRegions();
        },
        (payload: IApiPriorYearAccrualRegions, data: string[] | null) => {
            payload.regions = data;
        }
    );
};

/**
 * Payload used with callApiPriorYearAccrualSearch action.
 */
export interface IApiPriorYearAccrualSearch extends ICallApiBase {
    priorYearAccrualSearchResults?: PriorYearAccrualSearchResults | null;
}

/**
 * Call API to search prior year accruals.
 * @param priorYearAccrualSearch Search params.
 * @returns Redux dispatch function.
 */
export const callApiPriorYearAccrualSearch = (priorYearAccrualSearch: PriorYearAccrualSearch): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<PriorYearAccrualSearchResults | null>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_SEARCH,
        async () => {
            return await receiptingApiClient.priorYearAccrualSearch(priorYearAccrualSearch);
        },
        (payload: IApiPriorYearAccrualSearch, data: PriorYearAccrualSearchResults | null) => {
            if (data && data.items) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.items.forEach((item: PriorYearAccrualLineItem) => {
                    item.clientRowKey = createShortGuid();

                    // Mark duplicate PO / line items with the duplicate flag.
                    item.isDuplicate = false;
                    const matchingRecs: PriorYearAccrualLineItem[] = data.items.filter(x => x.purchaseOrder === item.purchaseOrder && x.lineItem === item.lineItem);
                    for (let i: number = 1; i < matchingRecs.length; i++) {
                        matchingRecs[i].isDuplicate = true;
                    }
                });
            }

            payload.priorYearAccrualSearchResults = data;
        }
    );
};

/**
 * Action creator: Reset the API to search prior year accruals.
 * @returns Redux action.
 */
export const resetApiPriorYearAccrualSearch = (): AnyAction => {
    const apiData: IApiPriorYearAccrualSearch = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        priorYearAccrualSearchResults: undefined
    };
    return {
        type: actionTypes.API_PRIOR_YEAR_ACCRUAL_SEARCH,
        payload: apiData
    } as AnyAction;
};

/**
 * Payload used with callApiPriorYearAccrualExport action.
 */
export interface IApiPriorYearAccrualExport extends ICallApiBase {
    responseStatusCode?: number | null;
}

/**
 * Call API to export prior year accruals.
 * @param priorYearAccrualSearch Search params.
 * @returns Redux dispatch function.
 */
export const callApiPriorYearAccrualExport = (priorYearAccrualSearch: PriorYearAccrualSearch): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<number>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_EXPORT,
        async () => {
            return await receiptingApiClient.priorYearAccrualExport(priorYearAccrualSearch);
        },
        (payload: IApiPriorYearAccrualExport, data: number | null) => {
            payload.responseStatusCode = data;
        }
    );
};

/**
 * Payload used with callApiPriorYearAccrualExportCorpDetails action.
 */
export interface IApiPriorYearAccrualExportCorpDetails extends ICallApiBase {
    responseStatusCode?: number | null;
}

/**
 * Call API to export prior year accruals corp details.
 * @param priorYearAccrualSearch Search params.
 * @returns Redux dispatch function.
 */
export const callApiPriorYearAccrualExportCorpDetails = (priorYearAccrualSearch: PriorYearAccrualSearch): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<number>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_EXPORT_CORP_DETAILS,
        async () => {
            return await receiptingApiClient.priorYearAccrualExportCorpDetails(priorYearAccrualSearch);
        },
        (payload: IApiPriorYearAccrualExportCorpDetails, data: number | null) => {
            payload.responseStatusCode = data;
        }
    );
};

/**
 * Payload used with callApiPriorYearAccrualImportCorpDetails action.
 */
export interface IApiPriorYearAccrualImportCorpDetails extends ICallApiBase {
}

/**
 * Call API to import prior year accruals corp details.
 * @param file File to import.
 * @returns Redux dispatch function.
 */
export const callApiPriorYearAccrualImportCorpDetails = (file: File): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_IMPORT_CORP_DETAILS,
        async () => {
            return await receiptingApiClient.priorYearAccrualImportCorpDetails(file);
        }
    );
};

/**
 * Payload used with callApiPriorYearAccrualRetain action.
 */
export interface IApiPriorYearAccrualRetain extends ICallApiBase {
    // No data returned.
}

/**
 * Call API to retain prior year accrual lines.
 * @param purchaseOrderLineItemPairs Purchase order lines to retain.
 * @returns Redux dispatch function.
 */
export const callApiPriorYearAccrualRetain = (purchaseOrderLineItemPairs: PurchaseOrderLineItemPair[]): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_RETAIN_LINE,
        async () => {
            return await receiptingApiClient.priorYearAccrualRetain(purchaseOrderLineItemPairs);
        }
    );
};

/**
 * Payload used with callApiPriorYearAccrualRefresh action.
 */
export interface IApiPriorYearAccrualRefresh extends ICallApiBase {
    // No data returned.
}

/**
 * Call API to refresh prior year accrual lines.
 * @param purchaseOrderLineItemPairs Purchase order lines to refresh.
 * @returns Redux dispatch function.
 */
export const callApiPriorYearAccrualRefresh = (purchaseOrderLineItemPairs: PurchaseOrderLineItemPair[]): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_REFRESH_LINE,
        async () => {
            return await receiptingApiClient.priorYearAccrualRefresh(purchaseOrderLineItemPairs);
        }
    );
};

/**
 * Payload used with callApiClosePoLines action.
 */
export interface IApiClosePoLines extends ICallApiBase {
    // No data returned.
}

/**
 * Call API to close PO lines.
 * @param purchaseOrderLineCloseArray Purchase order lines to close.
 * @returns Redux dispatch function.
 */
export const callApiClosePoLines = (purchaseOrderLineCloseArray: PurchaseOrderLineClose[]): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_CLOSE_LINE,
        async () => {
            return await receiptingApiClient.closePoLines(purchaseOrderLineCloseArray);
        }
    );
};

/**
 * Payload used with callApiGetPriorYearAccrualEmailDetails action.
 */
export interface IApiGetPriorYearAccrualEmailDetails extends ICallApiBase {
    priorYearAccrualEmailSentDetails?: PriorYearAccrualEmailSentDetail[] | null;
}

/**
 * Call API to get prior year accrual email details.
 * @param purchaseOrderNumbers Purchase order numbers.
 * @returns Redux dispatch function.
 */
export const callApiGetPriorYearAccrualEmailDetails = (purchaseOrderNumbers: number[]): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<PriorYearAccrualEmailSentDetail[] | null>(
        actionTypes.API_GET_PRIOR_YEAR_ACCRUAL_EMAIL_DETAILS,
        async () => {
            return await receiptingApiClient.getPriorYearAccrualEmailDetails(purchaseOrderNumbers);
        },
        (payload: IApiGetPriorYearAccrualEmailDetails, data: PriorYearAccrualEmailSentDetail[] | null) => {
            payload.priorYearAccrualEmailSentDetails = data;
        }
    );
};

/**
 * Payload used with callApiTriggerPriorYearAccrualManualNotification action.
 */
export interface IApiTriggerPriorYearAccrualManualNotification extends ICallApiBase {
}

/**
 * Call API trigger prior year accrual manual notifications.
 * @param purchaseOrderOwners Purchase order owners.
 * @returns Redux dispatch function.
 */
export const callApiTriggerPriorYearAccrualManualNotification = (purchaseOrderOwners: string[]): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_TRIGGER_PRIOR_YEAR_ACCRUAL_MANUAL_NOTIFICATION,
        async () => {
            return await receiptingApiClient.triggerPriorYearAccrualManualNotification(purchaseOrderOwners);
        }
    );
};

/**
 * Payload used with callApiTriggerPriorYearAccrualManualNotification action.
 */
export interface IApiUpdateAccountingDetails extends ICallApiBase {
}

/**
 * Call API to update accounting details
 * @param poLines Purchase order line item pairs.
 * @param corpDetails Corp details object.
 * @param updateMode Corp details update mode.
 * @returns Redux dispatch function.
 */
export const callApiUpdateAccountingDetails = (
        poLines: PurchaseOrderLineItemPair[],
        corpDetails: CorpDetails
    ) : (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_UPDATE_ACCOUNTING_DETAILS,
        async () => {
            return await receiptingApiClient.updateAccountingDetails(poLines, corpDetails);
        }
    );
};

/**
 * Action creator: Show details for prior year accrual line item.
 * @param priorYearAccrualLineItem Prior year accrual line item.
 * @returns Redux action.
 */
export const showDetailsForPriorYearAccrualLineItem = (priorYearAccrualLineItem: PriorYearAccrualLineItem): AnyAction => {
    return {
        type: actionTypes.SHOW_DETAILS_FOR_PRIOR_YEAR_ACCRUAL_LINE_ITEM,
        showDetailsForPriorYearAccrualLineItem: priorYearAccrualLineItem
    } as AnyAction;
};

/**
 * Action creator: Hide details for prior year accrual.
 * @returns Redux action.
 */
export const hideDetailsForPriorYearAccrual = (): AnyAction => {
    return {
        type: actionTypes.SHOW_DETAILS_FOR_PRIOR_YEAR_ACCRUAL_LINE_ITEM,
        showDetailsForPriorYearAccrualLineItem: undefined
    } as AnyAction;
};

/**
 * Action creator: Show audit history for line item.
 * @param priorYearAccrualLineItem Prior year accrual line item.
 * @returns Redux action.
 */
export const showAuditForPriorYearAccrualLineItem = (priorYearAccrualLineItem: PriorYearAccrualLineItem): AnyAction => {
    return {
        type: actionTypes.SHOW_AUDIT_FOR_PRIOR_YEAR_ACCRUAL_LINE_ITEM,
        showAuditForPriorYearAccrualLineItem: priorYearAccrualLineItem
    } as AnyAction;
};

/**
 * Action creator: Hide audit history for line item.
 * @returns Redux action.
 */
export const hideAuditForPriorYearAccrualLineItem = (): AnyAction => {
    return {
        type: actionTypes.SHOW_AUDIT_FOR_PRIOR_YEAR_ACCRUAL_LINE_ITEM,
        showAuditForLineItem: undefined
    } as AnyAction;
};

/**
 * Action creator: Line item selection.
 * @param priorYearAccrualLineItem Prior year accrual line item.
 * @param checked Is checked.
 * @returns Redux action.
 */
export const priorYearAccrualLineItemSelection = (priorYearAccrualLineItem: PriorYearAccrualLineItem, checked: boolean): AnyAction => {
    return {
        type: actionTypes.PRIOR_YEAR_ACCRUAL_LINE_ITEM_SELECTION,
        lineItemSelection: priorYearAccrualLineItem,
        checked: checked
    } as AnyAction;
};

/**
 * Action creator: Toggle all selected.
 * @param checked Is checked.
 * @returns Redux action.
 */
export const toggleAllSelected = (checked: boolean): AnyAction => {
    return {
        type: actionTypes.PRIOR_YEAR_ACCRUAL_TOGGLE_ALL_SELECTION,
        checked: checked
    } as AnyAction;
};

/**
 * Payload used with callApiPriorYearAccrualsHierarchyScope action.
 */
export interface IApiPriorYearAccrualHierarchyScope extends ICallApiBase {
    priorYearAccrualHierarchyScope?: PriorYearAccrualHierarchyScope | null;
}

/**
 * Call API to get prior year accrual hierarchy scope.
 * @returns Redux dispatch function.
 */
export const callApiPriorYearAccrualHierarchyScope = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<PriorYearAccrualHierarchyScope | null>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_HIERARCHY_SCOPE,
        async () => {
            return await receiptingAccrualsApiClient.priorYearAccrualHierarchyScope();
        },
        (payload: IApiPriorYearAccrualHierarchyScope, data: PriorYearAccrualHierarchyScope | null) => {
            payload.priorYearAccrualHierarchyScope = data;
        }
    );
};

/**
 * Payload used with callApiSavePriorYearAccrualHierarchyScope action.
 */
export interface IApiSavePriorYearAccrualHierarchyScope extends ICallApiBase {
}

/**
 * Call API to save prior year accrual hierarchy scope.
 * @param priorYearAccrualHierarchyScope Prior year accrual hierarchy scope.
 * @returns Redux dispatch function.
 */
export const callApiSavePriorYearAccrualHierarchyScope = (priorYearAccrualHierarchyScope: PriorYearAccrualHierarchyScope): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<PriorYearAccrualHierarchyScope | null>(
        actionTypes.API_SAVE_PRIOR_YEAR_ACCRUAL_HIERARCHY_SCOPE,
        async () => {
            return await receiptingAccrualsApiClient.savePriorYearAccrualHierarchyScope(priorYearAccrualHierarchyScope);
        }
    );
};

/**
 * Action creator: Reset the API to save cost category configuration.
 * @returns Redux action.
 */
export const resetApiSavePriorYearAccrualHierarchyScope = (): AnyAction => {
    const apiData: IApiSavePriorYearAccrualHierarchyScope = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    return {
        type: actionTypes.API_SAVE_PRIOR_YEAR_ACCRUAL_HIERARCHY_SCOPE,
        payload: apiData
    } as AnyAction;
};

/**
 * Payload used with callApiPriorYearAccrualProfitCenterCodeMapping action.
 */
export interface IApiPriorYearAccrualProfitCenterCodeMapping extends ICallApiBase {
    profitCenterCodeMapping?: ProfitCenterCodeMapping[] | null;
}

/**
 * Call API to get prior year accrual profit center code mapping.
 * @returns Redux dispatch function.
 */
export const callApiPriorYearAccrualProfitCenterCodeMapping = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<ProfitCenterCodeMapping[] | null>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_PROFIT_CENTER_CODE_MAPPING,
        async () => {
            return await receiptingAccrualsApiClient.priorYearAccrualProfitCenterCodeMapping();
        },
        (payload: IApiPriorYearAccrualProfitCenterCodeMapping, data: ProfitCenterCodeMapping[] | null) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((item: ProfitCenterCodeMapping) => {
                    item.clientRowKey = createShortGuid();
                });
            }

            payload.profitCenterCodeMapping = data;
        }
    );
};

/**
 * Action creator: Profit center code mapping sort on column.
 * @param profitCenterCodeMappingSortUsingColumnKey Column key to sort on.
 * @returns Redux action.
 */
export const profitCenterCodeMappingSortOnColumn = (profitCenterCodeMappingSortUsingColumnKey: string | undefined): AnyAction => {
    return {
        type: actionTypes.PROFIT_CENTER_CODE_MAPPING_SORT_ON_COLUMN,
        profitCenterCodeMappingSortUsingColumnKey: profitCenterCodeMappingSortUsingColumnKey
    } as AnyAction;
};

/**
 * Action creator: Update sorted profit center code mapping.
 * @param profitCenterCodeMappingItems Profit center code mapping items data.
 * @returns Redux action.
 */
export const updateSortedProfitCenterCodeMapping = (profitCenterCodeMappingItems: ProfitCenterCodeMapping[]): AnyAction => {
    return {
        type: actionTypes.PROFIT_CENTER_CODE_MAPPING_UPDATE_SORTED,
        profitCenterCodeMappingItems: profitCenterCodeMappingItems
    } as AnyAction;
};

/**
 * Payload used with callApiCostCategoryConfiguration action.
 */
export interface IApiCostCategoryConfiguration extends ICallApiBase {
    costCategoryConfig?: CostCategoryConfiguration[] | null;
}

/**
 * Call API to get cost category configuration.
 * @returns Redux dispatch function.
 */
export const callApiCostCategoryConfiguration = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<CostCategoryConfiguration[] | null>(
        actionTypes.API_COST_CATEGORY_CONFIG,
        async () => {
            return await receiptingAccrualsApiClient.costCategoryConfiguration();
        },
        (payload: IApiCostCategoryConfiguration, data: CostCategoryConfiguration[] | null) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((item: CostCategoryConfiguration) => {
                    item.clientRowKey = createShortGuid();
                });
            }

            payload.costCategoryConfig = data;
        }
    );
};

/**
 * Action creator: Cost category configuration sort on column.
 * @param costCategoryConfigurationSortUsingColumnKey Column key to sort on.
 * @returns Redux action.
 */
export const costCategoryConfigurationSortOnColumn = (costCategoryConfigurationSortUsingColumnKey: string | undefined): AnyAction => {
    return {
        type: actionTypes.COST_CATEGORY_CONFIGURATION_SORT_ON_COLUMN,
        costCategoryConfigurationSortUsingColumnKey: costCategoryConfigurationSortUsingColumnKey
    } as AnyAction;
};

/**
 * Action creator: Update sorted cost category configuration.
 * @param costCategoryConfigurationItems Cost category configuration items data.
 * @returns Redux action.
 */
export const updateSortedCostCategoryConfiguration = (costCategoryConfigurationItems: CostCategoryConfiguration[]): AnyAction => {
    return {
        type: actionTypes.COST_CATEGORY_CONFIGURATION_UPDATE_SORTED,
        costCategoryConfigurationItems: costCategoryConfigurationItems
    } as AnyAction;
};

/**
 * Payload used with callApiCostCategorySubclasses action.
 */
export interface IApiCostCategorySubclasses extends ICallApiBase {
    costCategorySubclasses?: CodeValue[] | null;
}

/**
 * Call API to get cost category subclasses.
 * @returns Redux dispatch function.
 */
export const callApiCostCategorySubclasses = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<CodeValue[] | null>(
        actionTypes.API_COST_CATEGORY_SUBCLASSES,
        async () => {
            return await receiptingAccrualsApiClient.costCategorySubclasses();
        },
        (payload: IApiCostCategorySubclasses, data: CodeValue[] | null) => {
            payload.costCategorySubclasses = data;
        }
    );
};

/**
 * Payload used with callApiCostCategoryGroupNames action.
 */
export interface IApiCostCategoryGroupNames extends ICallApiBase {
    groupNames?: CodeValue[] | null;
}

/**
 * Call API to get cost category group names based on subclass code.
 * @param subclassCode Subclass code.
 * @returns Redux dispatch function.
 */
export const callApiCostCategoryGroupNames = (subclassCode: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<CodeValue[] | null>(
        actionTypes.API_COST_CATEGORY_GROUP_NAMES,
        async () => {
            return await receiptingAccrualsApiClient.costCategoryGroupNames(subclassCode);
        },
        (payload: IApiCostCategoryGroupNames, data: CodeValue[] | null) => {
            payload.groupNames = data;
        }
    );
};

/**
 * Action creator: Reset the API to get cost category group names based on subclass code.
 * @returns Redux action.
 */
export const resetApiCostCategoryGroupNames = (): AnyAction => {
    const apiData: IApiCostCategoryGroupNames = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        groupNames: undefined
    };
    return {
        type: actionTypes.API_COST_CATEGORY_GROUP_NAMES,
        payload: apiData
    } as AnyAction;
};

/**
 * Payload used with callApiCostCategoryLineItems action.
 */
export interface IApiCostCategoryLineItems extends ICallApiBase {
    lineItems?: CodeValue[] | null;
}

/**
 * Call API to get cost category line items based on groupNameCode.
 * @param groupNameCode Group name code.
 * @returns Redux dispatch function.
 */
export const callApiCostCategoryLineItems = (groupNameCode: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<CodeValue[] | null>(
        actionTypes.API_COST_CATEGORY_LINE_ITEMS,
        async () => {
            return await receiptingAccrualsApiClient.costCategoryLineItems(groupNameCode);
        },
        (payload: IApiCostCategoryLineItems, data: CodeValue[] | null) => {
            payload.lineItems = data;
        }
    );
};

/**
 * Action creator: Reset the API to get cost category line items based on groupNameCode.
 * @returns Redux action.
 */
export const resetApiCostCategoryLineItems = (): AnyAction => {
    const apiData: IApiCostCategoryLineItems = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        lineItems: undefined
    };
    return {
        type: actionTypes.API_COST_CATEGORY_LINE_ITEMS,
        payload: apiData
    } as AnyAction;
};

/**
 * Payload used with callApiCostCategoryLineItemDetails action.
 */
export interface IApiCostCategoryLineItemDetails extends ICallApiBase {
    lineItemDetails?: CodeValue[] | null;
}

/**
 * Call API to get cost category line items based on groupNameCode.
 * @param lineItemCode Line item code.
 * @returns Redux dispatch function.
 */
export const callApiCostCategoryLineItemDetails = (lineItemCode: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<CodeValue[] | null>(
        actionTypes.API_COST_CATEGORY_LINE_ITEM_DETAILS,
        async () => {
            return await receiptingAccrualsApiClient.costCategoryLineItemDetails(lineItemCode);
        },
        (payload: IApiCostCategoryLineItemDetails, data: CodeValue[] | null) => {
            payload.lineItemDetails = data;
        }
    );
};

/**
 * Action creator: Reset the API to get cost category line items based on groupNameCode.
 * @returns Redux action.
 */
export const resetApiCostCategoryLineItemDetails = (): AnyAction => {
    const apiData: IApiCostCategoryLineItemDetails = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        lineItemDetails: undefined
    };
    return {
        type: actionTypes.API_COST_CATEGORY_LINE_ITEM_DETAILS,
        payload: apiData
    } as AnyAction;
};

/**
 * Payload used with callApiSaveCostCategoryConfiguration action.
 */
export interface IApiSaveCostCategoryConfiguration extends ICallApiBase {
}

/**
 * Call API to save cost category configuration.
 * @param costCategoryConfiguration Cost category configuration to save.
 * @returns Redux dispatch function.
 */
export const callApiSaveCostCategoryConfiguration = (costCategoryConfiguration: CostCategoryConfiguration): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<string[] | null>(
        actionTypes.API_SAVE_COST_CATEGORY_CONFIG,
        async () => {
            return await receiptingAccrualsApiClient.saveCostCategoryConfiguration(costCategoryConfiguration);
        }
    );
};

/**
 * Action creator: Reset the API to save cost category configuration.
 * @returns Redux action.
 */
export const resetApiSaveCostCategoryConfiguration = (): AnyAction => {
    const apiData: IApiSaveCostCategoryConfiguration = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    return {
        type: actionTypes.API_SAVE_COST_CATEGORY_CONFIG,
        payload: apiData
    } as AnyAction;
};

/**
 * Payload used with callApiDeleteCostCategoryConfiguration action.
 */
export interface IApiDeleteCostCategoryConfiguration extends ICallApiBase {
}

/**
 * Call API to delete cost category configuration.
 * @param id Id of cost category configuration to delete.
 * @returns Redux dispatch function.
 */
export const callApiDeleteCostCategoryConfiguration = (id: number): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<string[] | null>(
        actionTypes.API_DELETE_COST_CATEGORY_CONFIG,
        async () => {
            return await receiptingAccrualsApiClient.deleteCostCategoryConfiguration(id);
        }
    );
};

/**
 * Action creator: Reset the API to delete cost category configuration.
 * @returns Redux action.
 */
export const resetApiDeleteCostCategoryConfiguration = (): AnyAction => {
    const apiData: IApiDeleteCostCategoryConfiguration = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    return {
        type: actionTypes.API_DELETE_COST_CATEGORY_CONFIG,
        payload: apiData
    } as AnyAction;
};

/**
 * Action creator: Edit cost category configuration.
 * @param editCostCategoryConfiguration Cost category configuration to edit.
 * @returns Redux action.
 */
export const editCostCategoryConfiguration = (editCostCategoryConfiguration: CostCategoryConfiguration | undefined): AnyAction => {
    return {
        type: actionTypes.EDIT_COST_CATEGORY_CONFIGURATION,
        costCategoryConfigurationToEdit: editCostCategoryConfiguration
    } as AnyAction;
};

/**
 * Action creator: Delete cost category configuration.
 * @param deleteCostCategoryConfiguration Cost category configuration to delete.
 * @returns Redux action.
 */
export const deleteCostCategoryConfiguration = (deleteCostCategoryConfiguration: CostCategoryConfiguration | undefined): AnyAction => {
    return {
        type: actionTypes.DELETE_COST_CATEGORY_CONFIGURATION,
        costCategoryConfigurationToDelete: deleteCostCategoryConfiguration
    } as AnyAction;
};

/**
 * Payload used with callApiCostCategoryConfigurationForGlAccount action.
 */
export interface IApiCostCategoryConfigurationForGlAccount extends ICallApiBase {
    costCategoryConfiguration?: CostCategoryConfiguration | null;
}

/**
 * Call API to get cost category configuration for GL account.
 * @param id Id of cost category configuration to delete.
 * @returns Redux dispatch function.
 */
export const callApiCostCategoryConfigurationForGlAccount = (glAccount: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<CostCategoryConfiguration | null>(
        actionTypes.API_COST_CATEGORY_CONFIGURATION_FOR_GL,
        async () => {
            return await receiptingAccrualsApiClient.costCategoryConfigurationForGlAccount(glAccount);
        },
        (payload: IApiCostCategoryConfigurationForGlAccount, data: CostCategoryConfiguration | null) => {
            payload.costCategoryConfiguration = data;
        }
    );
};

/**
 * Action creator: Reset the API to get cost category configuration for GL account.
 * @returns Redux action.
 */
export const resetApiCostCategoryConfigurationForGlAccount = (): AnyAction => {
    const apiData: IApiCostCategoryConfigurationForGlAccount = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        costCategoryConfiguration: undefined
    };
    return {
        type: actionTypes.API_COST_CATEGORY_CONFIGURATION_FOR_GL,
        payload: apiData
    } as AnyAction;
};

/**
 * Payload used with callApiPyaUploadManualPostings action.
 */
export interface IApiPyaUploadManualPostings extends ICallApiBase {
}

/**
 * Call API to upload manual postings Excel file for prior year accruals.
 * @param file Excel file to upload.
 * @returns Redux dispatch function.
 */
export const callApiPyaUploadManualPostings = (file: File): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<PriorYearAccrualUserSummary | null>(
        actionTypes.API_PRIOR_YEAR_ACCRUAL_UPLOAD_MANUAL_POSTINGS,
        async () => {
            return await receiptingApiClient.pyaUploadManualPostings(file);
        }
    );
};

/**
 * Action creator: Reset the API to upload manual postings Excel file for prior year accruals.
 * @returns Redux action.
 */
export const resetApiCallStateForPyaUploadManualPostings = (): AnyAction => {
    const apiData: IApiSaveCostCategoryConfiguration = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    return {
        type: actionTypes.API_PRIOR_YEAR_ACCRUAL_UPLOAD_MANUAL_POSTINGS,
        payload: apiData
    } as AnyAction;
};
